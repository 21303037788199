import React from 'react';
import * as Chars from './Chars';
import './App.css';

// If we have IN_A_ROW in a row correct on each of the last LAST_N
// characters then we introduce UNLOCK_AT_A_TIME new characters.
//const LAST_N = 12 * 3;
//const IN_A_ROW = 3;
const UNLOCK_AT_A_TIME = 12;

function getUnixTimestamp(): number {
  return Math.floor(Date.now() / 1000);
}

// From: https://stackoverflow.com/a/12646864
function shuffleArray<T>(array: T[]): void {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

const sessionToken: number = getUnixTimestamp();

interface Guess {
  session: number;
  timestamp: number;
  character: string;
  correct: boolean;
}

class GuessDB {
  guesses: Guess[];
  guessesByChar: Map<string, Guess[]>;
  lastShow: Map<string, number>;

  constructor() {
    this.guesses = null as unknown as Guess[];
    this.guessesByChar = null as unknown as Map<string, Guess[]>;
    this.lastShow = null as unknown as Map<string, number>;
    this.reloadDatabase();
  }

  reloadDatabase() {
    this.guesses = [];
    this.guessesByChar = new Map<string, Guess[]>();
    this.lastShow = new Map<string, number>();
    //const count = Number(localStorage.getItem('guessCount') || 0);
    for (let index = 0; true; index++) {
      const entry = localStorage.getItem('g' + index);
      if (entry === null)
        break;
      const [session, timestamp, character, correct] = entry.split('.');
      console.log('Got:', session, timestamp, character, correct);
      this._innerAddGuess({
        session: Number(session),
        timestamp: Number(timestamp),
        character,
        correct: correct === '1',
      });
    }
    console.log('Loaded guesses:', this.guesses.length);
  }

  addGuess(character: string, correct: boolean) {
    const entry = `${sessionToken}.${getUnixTimestamp()}.${character}.${+correct}`
    // First make sure we're not conflicting with progress in another tab.
    const guessCount = localStorage.getItem('guessCount');
    if (guessCount !== null && Number(guessCount) > this.guesses.length) {
      console.log('Uh oh! Progress occurred in another tab:', guessCount, 'compared to our:', this.guesses.length);
      this.reloadDatabase();
    }
    localStorage.setItem('g' + this.guesses.length, entry);
    localStorage.setItem('guessCount', `${this.guesses.length + 1}`);
    this._innerAddGuess({
      session: sessionToken,
      timestamp: getUnixTimestamp(),
      character,
      correct,
    });
  }

  _innerAddGuess(guess: Guess) {
    this.guesses.push(guess);
    this.lastShow.set(guess.character, this.guesses.length);
    if (!this.guessesByChar.has(guess.character))
      this.guessesByChar.set(guess.character, []);
    this.guessesByChar.get(guess.character)!.push(guess);
  }

  getHistory(character: string): Guess[] {
    return this.guessesByChar.get(character) || [];
  }

  getShowAge(character: string): number {
    return this.guesses.length - (this.lastShow.get(character) || 0);
  }
}

const buttonStyle: React.CSSProperties = {
  display: 'inline-block',
  border: '2px solid white',
  borderRadius: 10,
  width: 100,
  padding: 20,
  fontSize: '80%',
  color: 'white',
  cursor: 'pointer',
  userSelect: 'none',
};

let fetchedSource: string = '';
let handle: NodeJS.Timeout | undefined = undefined;
let globalIsUnsaved: boolean = false;

function getGlobalIsUnsaved(): boolean {
  return globalIsUnsaved;
}

function debounceCompile(f: () => void) {
  if (handle !== undefined)
    clearTimeout(handle);
  handle = setTimeout(f, 150);
}

interface FlashCard {
  front: string;
  def: string;
}

function TeacherView(props: {}) {
  let [source, setSource] = React.useState('');
  const [compiled, setCompiled] = React.useState({
    source: '',
    cards: [],
  } as {
    source: string;
    cards: FlashCard[];
  });
  const [summary, setSummary] = React.useState({
    info: {},
  } as {
    info: any;
  });
  const [forceUpdateCounter, setForceUpdateCounter] = React.useState(0);

  globalIsUnsaved = source !== fetchedSource;

  const refreshCards = async () => {
    console.log('Loading...');
    const request1 = window.fetch('/api/main', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ kind: 'get-flashcards' }),
    });
    const request2 = await window.fetch('/api/main', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ kind: 'get-summary' }),
    });
    const response1 = await request1;
    const response2 = await request2;
    const t1 = await response1.json();
    console.log('Got:', t1.data);
    fetchedSource = t1.data;
    setSource(t1.data);
    const t2 = await response2.json();
    console.log('Got:', t2.data);
    setSummary(t2.data);
  };

  React.useEffect(() => {
    refreshCards();
    function areYouSure(e: BeforeUnloadEvent) {
      if (!getGlobalIsUnsaved()) {
        console.log('asdf');
        return;
      }
      var confirmationMessage = 'You have unsaved changes.';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
    window.addEventListener("beforeunload", areYouSure);
    return () => {
      window.removeEventListener("beforeunload", areYouSure);
    };
  }, []);

  // We now debounce the compilation so that we don't compile on every keystroke.
  const compileCards = debounceCompile(() => {
    if (source === compiled.source)
      return;
    console.log('Compiling flash cards...');
    let cards = [];
    for (const line of source.replace('｜', '|').split('\n')) {
      const match = line.match(/^([^|]*)\|([^|]*)$/);
      if (match === null)
        continue;
      const [_, front, def] = match;
      cards.push({ front: front.trim(), def: def.trim() });
    }
    setCompiled({
      source,
      cards,
    });
  });

  return <div style={{
    width: '100vw',
    height: '100vh',
    textAlign: 'center',
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    Teacher view<br/><br/>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    }}>
      <textarea
        style={{
          width: 300,
          // Now we make the text area's height fully fill the surrounding div.
          height: '95%',
        }}
        value={source}
        onChange={(e) => setSource(e.target.value)}
      />
      <div style={{
        width: 500,
        marginLeft: 20,
      }}>
        {/* Save button */}
        <button
          disabled={!globalIsUnsaved}
          style={{

          }}
          onClick={async () => {
            console.log('Saving flash cards...');
            const response = await window.fetch('/api/main', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ kind: 'set-flashcards', data: source }),
            });
            const t = await response.json();
            console.log('Got:', t);
            if (t.kind == 'ok')
              fetchedSource = source;
            // Effectively forceUpdate.
            setForceUpdateCounter(forceUpdateCounter + 1);
          }}
        >
          Save
        </button>
        <div style={{ marginTop: 20 }}>
          Total flashcards: {compiled.cards.length}<br/>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            // Enable wrapping
            flexWrap: 'wrap',
          }}>
            {compiled.cards.map((card, i) => <div key={i} style={{
              border: '1px solid #aaa',
              backgroundColor: '#333',
              borderRadius: 2,
              paddingLeft: 1,
              paddingRight: 1,
              margin: 2,

            }}>
              {card.front} {/*summary.info[card.front]*/}
            </div>)}
          </div>
        </div>
      </div>
    </div>
  </div>;
}

class App extends React.PureComponent<{}, {
  showDef: boolean;
  character: string;
  showChars: boolean;

  testMode: boolean;
  testModeCharacters: string[];
  testModeMistakeList: string[];
  testModeCorrectSoFar: number;
  testModeTotal: number;
  wrongnessThreshold: number;
  showWrongness: boolean;
}> {
  guessDB = new GuessDB();
  charactersUnlocked = 10;
  wrongness = 0;
  totalWrongness = 0;
  ones = 0;
  twos = 0;
  threes = 0;

  constructor(props: {}) {
    super(props);
    for (const guess of this.guessDB.guesses) {
      this.charactersUnlocked = Math.max(
        this.charactersUnlocked, Chars.getCharacterIndex(guess.character) + 1
      );
    }
    const rawWrongnessThreshold = localStorage.getItem('wrongnessThreshold');
    const wrongnessThreshold = rawWrongnessThreshold === null ? 20.0 : Number(rawWrongnessThreshold);
    this.state = {
      showDef: false,
      character: this.pickCharacter(),
      showChars: false,
      testMode: false,
      testModeCharacters: [],
      testModeMistakeList: [],
      testModeCorrectSoFar: 0,
      testModeTotal: 0,
      wrongnessThreshold,
      showWrongness: localStorage.getItem('showWrongness') === '1',
    };
    this.processState();
    document.addEventListener('keydown', this.shortcutsHandler);
  }

  shortcutsHandler = (event: KeyboardEvent) => {
    if (this.state.showChars)
      return;
    if (event.key === ' ')
      this.setState({ showDef: true });
    if (event.key === '1')
      this.onAnswer(true, null);
    if (event.key === '2')
      this.onAnswer(false, null);
  }

  processState(): void {
    // Test if we're doing well enough to advance to the next character.
    this.wrongness = 0;
    this.totalWrongness = 0;
    this.ones = 0;
    this.twos = 0;
    this.threes = 0;
    // We add a bonus to keep things slower at first.
    const lerp = Math.max(0, Math.min(1, this.charactersUnlocked / 50));
    this.wrongness += Math.max(0, 5 * (1 - lerp));
    // Sum up contributions from our history.
    for (let back = 0; back < this.charactersUnlocked; back++) {
      const scale = Math.max(0.02, Math.pow(0.99, Math.max(0, back - 11)));
      const character = Chars.data[this.charactersUnlocked - 1 - back][0];
      const history = this.guessDB.getHistory(character);
      for (let i = 0; i < history.length; i++) {
        const entry = history[history.length - 1 - i];
        this.wrongness += scale * Math.pow(0.6, i) * (1 - +entry.correct);
        this.totalWrongness += Math.pow(0.6, i) * (1 - +entry.correct);
      }
      this.wrongness += 2.5 * scale * Math.pow(0.6, history.length);
      this.totalWrongness += 2.5 * scale * Math.pow(0.6, history.length);
      switch (history.length) {
        case 1: this.ones++; break;
        case 2: this.twos++; break;
        case 3: this.threes++; break;
      }
    }
    //let failures = 0;
    //for (
    //  let charIndex = Math.max(0, this.charactersUnlocked - LAST_N);
    //  charIndex < this.charactersUnlocked; charIndex++
    //) {
    //  const character = Chars.data[charIndex][0];
    //  const history = this.guessDB.getHistory(character);
    //  if (history.length < IN_A_ROW || history.slice(-IN_A_ROW).some((guess) => !guess.correct))
    //    failures++;
    //}
    //if (failures < 4)
    //
    if (this.wrongness < this.state.wrongnessThreshold) {
      this.charactersUnlocked += this.charactersUnlocked < 100 ? 8 : UNLOCK_AT_A_TIME;
      this.charactersUnlocked = Math.min(this.charactersUnlocked, Chars.data.length);
    }
  }

  pickCharacter(): string {
    const kind = Math.random();
    const isRandomRefresh = kind < 0.3;
    const justOldest = kind < 0.15;
    //const now = getUnixTimestamp();
    let bestCharacter = '?';
    let bestScore = -1000;

    for (let charIndex = 0; charIndex < this.charactersUnlocked; charIndex++) {
      const character = Chars.data[charIndex][0];
      const showAgeInCards = this.guessDB.getShowAge(character);
      const history = this.guessDB.getHistory(character);
      let score = 0;

      // Proritize new characters.
      if (history.length === 0)
        score += 100e6 + charIndex;

      //// Add a bonus for having shown a long time ago.
      //const showAgeInSeconds = history.length > 0 ? now - history[history.length - 1].timestamp : 1e9;
      //if (!isRandomRefresh)
      //  score += 2 * Math.atan(showAgeInSeconds / (24 * 3600));

      // Add a bonus for being wrong in the past.
      if (!isRandomRefresh) {
        for (let i = 0; i < history.length; i++) {
          const entry = history[history.length - 1 - i];
          score += Math.pow(0.7, i) * (1 - +entry.correct);
        }
      }

      // Bonus to make less shown characters show more often.
      if (!isRandomRefresh)
        score += 2 * Math.pow(0.85, history.length);

      // Subtract a penalty for having shown recently.
      score -= 2 * Math.pow(0.9, showAgeInCards);

      // Add a bonus for not having shown in a long time.
      score += 0.03 * Math.pow(showAgeInCards, 0.25);

      // In justOldest mode only the age of the card matters.
      if (justOldest)
        score += showAgeInCards;

      // Don't show a card twice in a row.
      if (showAgeInCards === 0)
        score -= 10e6;

      // Randomize order.
      score += Math.random();

      if (score > bestScore) {
        bestScore = score;
        bestCharacter = character;
      }
    }

    return bestCharacter;
  }

  onAnswer = (correct: boolean, event: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => {
    if (this.state.showChars || !this.state.showDef)
      return;
    if (event !== null)
      event.stopPropagation();
    if (this.state.testMode) {
      const testModeMistakeList = [...this.state.testModeMistakeList];
      if (!correct)
        testModeMistakeList.push(this.state.character);
      const testModeCharacters = this.state.testModeCharacters.slice(1);
      this.setState({
        testModeCharacters,
        testModeCorrectSoFar: this.state.testModeCorrectSoFar + (+correct),
        testModeMistakeList,
        character: testModeCharacters.length === 0 ? 'test-mode-done' : testModeCharacters[0],
        showDef: false,
      });
      return;
    }
    this.guessDB.addGuess(this.state.character, correct);
    this.processState();
    this.setState({ character: this.pickCharacter(), showDef: false });
  }

  onWrongnessThresholdChanged = (event: any) => {
    localStorage.setItem('wrongnessThreshold', '' + event.currentTarget.value);
    this.setState({
      wrongnessThreshold: Number(event.currentTarget.value),
    });
  }

  charsView() {
    const renderCharacterRow = (character: string) => {
      const { definition, pinyin } = Chars.lookupCharacter(character);
      const history = this.guessDB.getHistory(character);
      const historySummary = [];
      for (let i = 0; i < 5; i++) {
        if (i >= history.length) {
          historySummary.push(<span id={'' + i} style={{ opacity: 0.5 }}>⬤</span>);
        } else {
          const entry = history[history.length - 1 - i];
          historySummary.push(<span id={'' + i} style={{
            opacity: 0.75,
            color: entry.correct ? 'green' : 'red',
          }}>⬤</span>);
        }
      }
      historySummary.reverse();
      return (
        <tr key={character}>
          <td>{character}</td>
          <td style={{ paddingLeft: 10 }}>{historySummary}</td>
          <td style={{ paddingLeft: 5 }}>{pinyin}</td>
          <td style={{ paddingLeft: 15, width: 200, maxWidth: 200, textOverflow: 'ellipsis' }}>{definition}</td>
          <td style={{ paddingLeft: 10 }}>{this.guessDB.getShowAge(character)}</td>
        </tr>
      )
    }

    const testModeCharacters: string[] = [];
    let hardestChars: string[] = [];
    const characterErrorness = new Map<string, number>();
    const chars = [];
    for (let i = 0; i < this.charactersUnlocked; i++) {
      const character = Chars.data[i][0];
      const history = this.guessDB.getHistory(character);
      chars.push(renderCharacterRow(character));
      testModeCharacters.push(character);
      hardestChars.push(character);
      let errorness = 0;
      for (let i = 0; i < history.length; i++) {
        const entry = history[history.length - 1 - i];
        errorness += Math.pow(0.8, i) * (1 - +entry.correct);
      }
      characterErrorness.set(character, errorness);
    }
    shuffleArray(testModeCharacters);
    hardestChars.sort((a, b) => characterErrorness.get(b)! - characterErrorness.get(a)!);
    hardestChars = hardestChars.slice(0, 50);

    const allCharacters = [];
    for (let i = 0; i < this.charactersUnlocked; i++) {
      const character = Chars.data[i][0];
      const history = this.guessDB.getHistory(character);
      // Add an effective double error right after the end of the history.
      const errorness = characterErrorness.get(character)! + 2 * Math.pow(0.8, history.length);
      const interp = Math.max(0, Math.min(1, errorness / 3.0));
      const r = 255 * interp;
      const g = 255 * (1 - interp);
      allCharacters.push(
        <span style={{
          color: `rgb(${r}, ${g}, 120)`,
        }}>{character}</span>
      );
    }

    const radioButtons = [];
    for (const [value, string] of [
      [0, 'No new characters'],
      [10, 'Slower'],
      [15, 'Slow'],
      [20, 'Normal'],
      [30, 'Fast'],
      [40, 'Faster'],
      [50, 'Breakneck'],
      [60, 'Breakerneck'],
      [70, 'Breakestneck'],
    ]) {
      radioButtons.push(
        <div key={string}>
          <input
            type='radio'
            name='wrongnessThreshold'
            value={value}
            checked={this.state.wrongnessThreshold === value}
            onChange={this.onWrongnessThresholdChanged}
          />
          {value} — {string}
        </div>
      );
    }

    return <div style={{
      padding: 50,
      //fontSize: '200%',
      //textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            ...buttonStyle,
            backgroundColor: '#888',
            padding: 15,
            marginBottom: 10,
            fontSize: '120%',
            textAlign: 'center',
          }}
          onClick={() => this.setState({ showChars: false })}
        >
          Back
        </div>
        <div
          style={{
            ...buttonStyle,
            backgroundColor: '#888',
            padding: 15,
            marginLeft: 15,
            marginBottom: 10,
            fontSize: '120%',
            textAlign: 'center',
          }}
          onClick={(event) => {
            this.setState({
              showChars: false,
              showDef: false,
              testMode: true,
              testModeCharacters,
              testModeCorrectSoFar: 0,
              testModeMistakeList: [],
              testModeTotal: testModeCharacters.length,
              character: testModeCharacters[0],
            });
            event.stopPropagation();
          }}
        >
          Test
        </div>
      </div>
      <table>
        {chars}
      </table>
      <div style={{ marginTop: 10 }}>
        {allCharacters}
      </div>
      <span style={{ marginTop: 10 }}>Hardest characters for you:</span>
      <table>
        {hardestChars.map(renderCharacterRow)}
      </table>
      <div style={{ marginTop: 10 }}>
        Current wrongness: {this.wrongness.toFixed(2)}<br/>
        Wrongness threshold:
        <div>{radioButtons}</div>
        <input
          type='checkbox'
          checked={this.state.showWrongness}
          onChange={(event) => {
            localStorage.setItem('showWrongness', event.target.checked ? '1' : '0');
            this.setState({ showWrongness: event.target.checked });
          }}
        /> Show wrongness
        <br/>
        <br/>
        <button onClick={async () => {
          if (window.confirm('Send all of your history to the server?')) {
            const data = {};
            Object.assign(data, localStorage);
            const response = await window.fetch('/api/main', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ kind: 'store', data }),
            });
            const t = await response.text();
            window.alert('Got response: ' + t);
          }
        }}>
          Phone home
        </button>
        <button onClick={() => {
          if (window.confirm('Clear all local storage, losing all progress?')) {
            if (window.confirm('Are you *reaaaaally* sure?')) {
              localStorage.clear();
              window.location.reload();
            }
          }
        }}>
          Clear all data
        </button>
      </div>
    </div>;
  }

  endTest() {
    this.setState({
      testMode: false,
      character: this.pickCharacter(),
      showDef: false,
    });
  }

  mainView() {
    if (this.state.character === 'test-mode-done') {
      const shuffledMistakeList = [...this.state.testModeMistakeList];
      shuffleArray(shuffledMistakeList);
      return <div>
        Test results: {this.state.testModeCorrectSoFar} / {this.state.testModeTotal}<br/>
        Mistakes: {this.state.testModeMistakeList.length > 0
          ? this.state.testModeMistakeList.join('') : 'none!'}<br/>
        {shuffledMistakeList.length > 0 && <><div
          style={{
            ...buttonStyle,
            backgroundColor: '#888',
            padding: 15,
            marginTop: 10,
            fontSize: '120%',
            textAlign: 'center',
          }}
          onClick={(event) => {
            this.setState({
              showChars: false,
              showDef: false,
              testMode: true,
              testModeCharacters: shuffledMistakeList,
              testModeCorrectSoFar: 0,
              testModeMistakeList: [],
              testModeTotal: shuffledMistakeList.length,
              character: shuffledMistakeList[0],
            });
            event.stopPropagation();
          }}
        >
          Refine
        </div><br/></>}
        <div
          style={{
            ...buttonStyle,
            backgroundColor: '#888',
            padding: 15,
            marginTop: 10,
            fontSize: '120%',
            textAlign: 'center',
          }}
          onClick={(event) => {
            this.endTest();
            event.stopPropagation();
          }}
        >
          Done
        </div>
      </div>;
    }
    const characterEntry = Chars.lookupCharacter(this.state.character);
    return <div style={{
      textAlign: 'center',
    }}>
      <span style={{ fontSize: '500%' }}>{this.state.character}</span><br/>
      {this.state.showDef ? characterEntry.pinyin : <>&nbsp;</>}<br/>
      {this.state.showDef ? characterEntry.definition : <>&nbsp;</>}<br/>
      <div style={{
        marginTop: 15,
        opacity: this.state.showDef ? 1 : 0,
        pointerEvents: this.state.showDef ? undefined : 'none',
      }}>
        <div
          style={{ ...buttonStyle, backgroundColor: '#8c8' }}
          onClick={(evt) => this.onAnswer(true, evt)}
        >
          Right
        </div>
        <div
          style={{ ...buttonStyle, backgroundColor: '#c88', marginLeft: 15 }}
          onClick={(evt) => this.onAnswer(false, evt)}
        >
          Wrong
        </div>
      </div>
    </div>;
  }

  render() {
    // Check if we're at /teacher
    const teacher = window.location.pathname.startsWith('/teacher');
    if (teacher) {
      return <TeacherView />;
    }

    if (this.state.showChars)
      return this.charsView();

    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '150%',
        }}
        onClick={() => this.setState({ showDef: true })}
      >
        <div
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
            userSelect: 'none',
          }}
          onClick={(event) => {
            this.setState({ showChars: true });
            event.stopPropagation();
          }}
        >
          Chars: {this.charactersUnlocked}
        </div>
        {this.state.showWrongness && <div
          style={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            cursor: 'pointer',
            userSelect: 'none',
          }}
        >
          {this.wrongness.toFixed(2)}/{this.totalWrongness.toFixed(2)}-{this.ones}-{this.twos}-{this.threes}
        </div>}

        {this.state.testMode && this.state.testModeCharacters.length > 0 &&
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              left: 20,
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={(event) => {
              if (window.confirm('End test early?'))
                this.endTest();
              event.stopPropagation();
            }}
          >
            Test: {this.state.testModeCorrectSoFar} / {this.state.testModeTotal - this.state.testModeCharacters.length} - {this.state.testModeCharacters.length} left
          </div>
        }

        {this.state.showChars ? this.charsView() : this.mainView()}
      </div>
    );
  }
}

export default App;
