export const data: [string, string, string, string][] = [
  [
    "\u7684",
    "possessive p.",
    "de",
    "de"
  ],
  [
    "\u6211",
    "I ; me",
    "w\u01d2",
    "wo3"
  ],
  [
    "\u662f",
    "to be; is",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u4e00",
    "one; a",
    "y\u012b",
    "yi1"
  ],
  [
    "\u4e0d",
    "no; not",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u4e86",
    "completed action p.",
    "le",
    "le"
  ],
  [
    "\u6709",
    "to have; there is",
    "y\u01d2u",
    "you3"
  ],
  [
    "\u5728",
    "at",
    "z\u00e0i",
    "zai4"
  ],
  [
    "\u4f60",
    "you",
    "n\u01d0",
    "ni3"
  ],
  [
    "\u4eba",
    "man; person",
    "r\u00e9n",
    "ren2"
  ],
  [
    "\u8fd9",
    "this",
    "zh\u00e8",
    "zhe4"
  ],
  [
    "\u9019",
    "\u8fd9 (this)",
    "zh\u00e8",
    "zhe4"
  ],
  [
    "\u4eec",
    "plural p.",
    "men",
    "men"
  ],
  [
    "\u5011",
    "\u4eec (plural p.)",
    "men",
    "men"
  ],
  [
    "\u4ed6",
    "he; him",
    "t\u0101",
    "ta1"
  ],
  [
    "\u4e2a",
    "general mw.",
    "g\u00e8",
    "ge4"
  ],
  [
    "\u500b",
    "\u4e2a (general mw.)",
    "g\u00e8",
    "ge4"
  ],
  [
    "\u6765",
    "to come",
    "l\u00e1i",
    "lai2"
  ],
  [
    "\u4f86",
    "\u6765 (to come)",
    "l\u00e1i",
    "lai2"
  ],
  [
    "\u4e3a",
    "because of; for",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u7232",
    "\u4e3a (because of; for)",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u5927",
    "big",
    "d\u00e0",
    "da4"
  ],
  [
    "\u4e2d",
    "middle",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u4e0a",
    "above; top",
    "sh\u00e0ng",
    "shang4"
  ],
  [
    "\u4f1a",
    "can; will; to know",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u6703",
    "\u4f1a (can; will; to know)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u5230",
    "to arrive; until (time)",
    "d\u00e0o",
    "dao4"
  ],
  [
    "\u8981",
    "to want; important",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u4ee5",
    "according to; by",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u5c31",
    "then; just (emp.)",
    "ji\u00f9",
    "jiu4"
  ],
  [
    "\u4e48",
    "interrogative p.",
    "me",
    "me"
  ],
  [
    "\u9ebc",
    "\u4e48 (interrogative p.)",
    "me",
    "me"
  ],
  [
    "\u8bf4",
    "to speak",
    "shu\u014d",
    "shuo1"
  ],
  [
    "\u8aaa",
    "\u8bf4 (to speak)",
    "shu\u014d",
    "shuo1"
  ],
  [
    "\u65f6",
    "time",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u6642",
    "\u65f6 (time)",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u56fd",
    "country",
    "gu\u00f3",
    "guo2"
  ],
  [
    "\u570b",
    "\u56fd (country)",
    "gu\u00f3",
    "guo2"
  ],
  [
    "\u548c",
    "and",
    "h\u00e9",
    "he2"
  ],
  [
    "\u53ef",
    "can; able to",
    "k\u011b",
    "ke3"
  ],
  [
    "\u80fd",
    "to be able to",
    "n\u00e9ng",
    "neng2"
  ],
  [
    "\u90a3",
    "that",
    "n\u00e0",
    "na4"
  ],
  [
    "\u51fa",
    "to go out",
    "ch\u016b",
    "chu1"
  ],
  [
    "\u597d",
    "good; well",
    "h\u01ceo",
    "hao3"
  ],
  [
    "\u4e5f",
    "also; too",
    "y\u011b",
    "ye3"
  ],
  [
    "\u5bf9",
    "correct; opposite",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u5c0d",
    "\u5bf9 (correct; opposite)",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u5730",
    "earth",
    "d\u00ec",
    "di4"
  ],
  [
    "\u751f",
    "life; raw",
    "sh\u0113ng",
    "sheng1"
  ],
  [
    "\u5f97",
    "complement p. (V + \u5f97 + Adverb)",
    "de",
    "de"
  ],
  [
    "\u8fc7",
    "experienced action p.",
    "gu\u00f2",
    "guo4"
  ],
  [
    "\u904e",
    "\u8fc7 (experienced action p.)",
    "gu\u00f2",
    "guo4"
  ],
  [
    "\u6ca1",
    "not",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u6c92",
    "\u6ca1 (not)",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u4e0b",
    "below; down",
    "xi\u00e0",
    "xia4"
  ],
  [
    "\u9053",
    "way; method",
    "d\u00e0o",
    "dao4"
  ],
  [
    "\u81ea",
    "self",
    "z\u00ec",
    "zi4"
  ],
  [
    "\u4e4b",
    "possessive p.",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u5b50",
    "child",
    "z\u01d0",
    "zi3"
  ],
  [
    "\u7528",
    "to use",
    "y\u00f2ng",
    "yong4"
  ],
  [
    "\u53d1",
    "to send; to develop",
    "f\u0101",
    "fa1"
  ],
  [
    "\u767c",
    "\u53d1 (to send; to develop)",
    "f\u0101",
    "fa1"
  ],
  [
    "\u540e",
    "behind; after",
    "h\u00f2u",
    "hou4"
  ],
  [
    "\u5f8c",
    "\u540e (behind; after)",
    "h\u00f2u",
    "hou4"
  ],
  [
    "\u800c",
    "and; as well as",
    "\u00e9r",
    "er2"
  ],
  [
    "\u53bb",
    "to go",
    "q\u00f9",
    "qu4"
  ],
  [
    "\u90fd",
    "all; both",
    "d\u014du",
    "dou1"
  ],
  [
    "\u770b",
    "to see; to look at",
    "k\u00e0n",
    "kan4"
  ],
  [
    "\u4ec0",
    "what",
    "sh\u00e9n",
    "shen2"
  ],
  [
    "\u60f3",
    "to think; to want",
    "xi\u01ceng",
    "xiang3"
  ],
  [
    "\u5bb6",
    "home; family",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u4e8e",
    "in; at; on",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u65bc",
    "\u4e8e (in; at; on)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u8fd8",
    "still; yet; to return",
    "h\u00e1i",
    "hai2"
  ],
  [
    "\u9084",
    "\u8fd8 (still; yet; to return)",
    "h\u00e1i",
    "hai2"
  ],
  [
    "\u6210",
    "to become",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u4e8b",
    "thing; item",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u591a",
    "many; much",
    "du\u014d",
    "duo1"
  ],
  [
    "\u6240",
    "actually; place",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u91cc",
    "inside",
    "l\u01d0",
    "li3"
  ],
  [
    "\u88cf",
    "\u91cc (inside)",
    "l\u01d0",
    "li3"
  ],
  [
    "\u5979",
    "she",
    "t\u0101",
    "ta1"
  ],
  [
    "\u73b0",
    "present",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u73fe",
    "\u73b0 (present)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u5e74",
    "year",
    "ni\u00e1n",
    "nian2"
  ],
  [
    "\u884c",
    "to travel; OK!",
    "x\u00edng",
    "xing2"
  ],
  [
    "\u5f88",
    "very",
    "h\u011bn",
    "hen3"
  ],
  [
    "\u7136",
    "correct",
    "r\u00e1n",
    "ran2"
  ],
  [
    "\u4f5c",
    "to do",
    "zu\u00f2",
    "zuo4"
  ],
  [
    "\u5982",
    "as; as if",
    "r\u00fa",
    "ru2"
  ],
  [
    "\u7ecf",
    "pass through",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u7d93",
    "\u7ecf (pass through)",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u7740",
    "action in progress p.",
    "zhe",
    "zhe"
  ],
  [
    "\u6cd5",
    "law",
    "f\u01ce",
    "fa3"
  ],
  [
    "\u5417",
    "question p.",
    "ma",
    "ma"
  ],
  [
    "\u55ce",
    "\u5417 (question p.)",
    "ma",
    "ma"
  ],
  [
    "\u5929",
    "day; heaven",
    "ti\u0101n",
    "tian1"
  ],
  [
    "\u77e5",
    "to know",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u5fc3",
    "heart",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u5f00",
    "to open; to start",
    "k\u0101i",
    "kai1"
  ],
  [
    "\u958b",
    "\u5f00 (to open; to start)",
    "k\u0101i",
    "kai1"
  ],
  [
    "\u65b9",
    "place; square",
    "f\u0101ng",
    "fang1"
  ],
  [
    "\u8d77",
    "to rise",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u6837",
    "manner",
    "y\u00e0ng",
    "yang4"
  ],
  [
    "\u6a23",
    "\u6837 (manner)",
    "y\u00e0ng",
    "yang4"
  ],
  [
    "\u5f53",
    "to be; just at",
    "d\u0101ng",
    "dang1"
  ],
  [
    "\u7576",
    "\u5f53 (to be; just at)",
    "d\u0101ng",
    "dang1"
  ],
  [
    "\u5b66",
    "to learn",
    "xu\u00e9",
    "xue2"
  ],
  [
    "\u5b78",
    "\u5b66 (to learn)",
    "xu\u00e9",
    "xue2"
  ],
  [
    "\u52a8",
    "to move",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u52d5",
    "\u52a8 (to move)",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u5c0f",
    "small",
    "xi\u01ceo",
    "xiao3"
  ],
  [
    "\u5b9a",
    "to set",
    "d\u00ecng",
    "ding4"
  ],
  [
    "\u4e0e",
    "and; with",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u8207",
    "\u4e0e (and; with)",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u4f46",
    "but; yet",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u524d",
    "front",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u53ea",
    "only",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u9762",
    "side; aspect",
    "mi\u00e0n",
    "mian4"
  ],
  [
    "\u5206",
    "to divide; fraction",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u4e9b",
    "some; few",
    "xi\u0113",
    "xie1"
  ],
  [
    "\u70b9",
    "a dot; o'clock",
    "di\u01cen",
    "dian3"
  ],
  [
    "\u9ede",
    "\u70b9 (a dot; o'clock)",
    "di\u01cen",
    "dian3"
  ],
  [
    "\u540c",
    "same; similar",
    "t\u00f3ng",
    "tong2"
  ],
  [
    "\u56e0",
    "reason",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u610f",
    "idea; meaning",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u79cd",
    "kind",
    "zh\u01d2ng",
    "zhong3"
  ],
  [
    "\u7a2e",
    "\u79cd (kind)",
    "zh\u01d2ng",
    "zhong3"
  ],
  [
    "\u672c",
    "mw. for books; origin",
    "b\u011bn",
    "ben3"
  ],
  [
    "\u8fdb",
    "to enter",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u9032",
    "\u8fdb (to enter)",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u529b",
    "power",
    "l\u00ec",
    "li4"
  ],
  [
    "\u5427",
    "\u2026right?",
    "ba",
    "ba"
  ],
  [
    "\u628a",
    "direct object p.;  to hold",
    "b\u01ce",
    "ba3"
  ],
  [
    "\u771f",
    "really; truly",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u6700",
    "most; -est",
    "zu\u00ec",
    "zui4"
  ],
  [
    "\u516c",
    "public",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u624b",
    "hand",
    "sh\u01d2u",
    "shou3"
  ],
  [
    "\u4e1a",
    "occupation",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u696d",
    "\u4e1a (occupation)",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u673a",
    "machine",
    "j\u012b",
    "ji1"
  ],
  [
    "\u6a5f",
    "\u673a (machine)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u5176",
    "his; hers; its",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u7406",
    "essence; truth",
    "l\u01d0",
    "li3"
  ],
  [
    "\u505a",
    "to do",
    "zu\u00f2",
    "zuo4"
  ],
  [
    "\u4e3b",
    "master; main",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u65e0",
    "not to have; -less",
    "w\u00fa",
    "wu2"
  ],
  [
    "\u7121",
    "\u65e0 (not to have; -less)",
    "w\u00fa",
    "wu2"
  ],
  [
    "\u5b9e",
    "real; honest",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u5be6",
    "\u5b9e (real; honest)",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u8005",
    "one who (is)",
    "zh\u011b",
    "zhe3"
  ],
  [
    "\u4ece",
    "from; to follow",
    "c\u00f3ng",
    "cong2"
  ],
  [
    "\u5f9e",
    "\u4ece (from; to follow)",
    "c\u00f3ng",
    "cong2"
  ],
  [
    "\u9ad8",
    "high; tall",
    "g\u0101o",
    "gao1"
  ],
  [
    "\u8ba9",
    "to allow; passive p.",
    "r\u00e0ng",
    "rang4"
  ],
  [
    "\u8b93",
    "\u8ba9 (to allow; passive p.)",
    "r\u00e0ng",
    "rang4"
  ],
  [
    "\u7ed9",
    "to give; for; passive p.",
    "g\u011bi",
    "gei3"
  ],
  [
    "\u7d66",
    "\u7ed9 (to give; for; passive p.)",
    "g\u011bi",
    "gei3"
  ],
  [
    "\u7b49",
    "rank; to wait for ",
    "d\u011bng",
    "deng3"
  ],
  [
    "\u679c",
    "fruit",
    "gu\u01d2",
    "guo3"
  ],
  [
    "\u5168",
    "all; whole",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u660e",
    "bright",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u5df2",
    "already",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u95f4",
    "between; room",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u9593",
    "\u95f4 (between; room)",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u957f",
    "long; length",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u9577",
    "\u957f (long; length)",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u90e8",
    "section; part",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u8bdd",
    "language; talk",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u8a71",
    "\u8bdd (language; talk)",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u60c5",
    "emotion",
    "q\u00edng",
    "qing2"
  ],
  [
    "\u4e09",
    "three",
    "s\u0101n",
    "san1"
  ],
  [
    "\u5173",
    "to shut",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u95dc",
    "\u5173 (to shut)",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u56de",
    "to answer; to return",
    "hu\u00ed",
    "hui2"
  ],
  [
    "\u89c1",
    "to see; to meet",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u898b",
    "\u89c1 (to see; to meet)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u5341",
    "ten",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u88ab",
    "passive p.",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u5de5",
    "work",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u600e",
    "how",
    "z\u011bn",
    "zen3"
  ],
  [
    "\u522b",
    "must not; other",
    "bi\u00e9",
    "bie2"
  ],
  [
    "\u5225",
    "\u522b (must not; other)",
    "bi\u00e9",
    "bie2"
  ],
  [
    "\u6c11",
    "the people",
    "m\u00edn",
    "min2"
  ],
  [
    "\u6b63",
    "just (right); correct",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u5e94",
    "should; ought to",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u61c9",
    "\u5e94 (should; ought to)",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u6027",
    "nature; gender",
    "x\u00ecng",
    "xing4"
  ],
  [
    "\u897f",
    "West",
    "x\u012b",
    "xi1"
  ],
  [
    "\u513f",
    "son",
    "\u00e9r",
    "er2"
  ],
  [
    "\u5152",
    "\u513f (son)",
    "\u00e9r",
    "er2"
  ],
  [
    "\u5c06",
    "will; shall",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u5c07",
    "\u5c06 (will; shall)",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u5916",
    "outside; foreign",
    "w\u00e0i",
    "wai4"
  ],
  [
    "\u95ee",
    "to ask ",
    "w\u00e8n",
    "wen4"
  ],
  [
    "\u554f",
    "\u95ee (to ask )",
    "w\u00e8n",
    "wen4"
  ],
  [
    "\u4f53",
    "body; form",
    "t\u01d0",
    "ti3"
  ],
  [
    "\u9ad4",
    "\u4f53 (body; form)",
    "t\u01d0",
    "ti3"
  ],
  [
    "\u554a",
    "ah!",
    "a",
    "a"
  ],
  [
    "\u6b64",
    "this; these",
    "c\u01d0",
    "ci3"
  ],
  [
    "\u8eab",
    "body",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u5b83",
    "it",
    "t\u0101",
    "ta1"
  ],
  [
    "\u76f8",
    "mutual; each other",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u7535",
    "electric",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u96fb",
    "\u7535 (electric)",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u8d70",
    "to walk; away",
    "z\u01d2u",
    "zou3"
  ],
  [
    "\u91cd",
    "heavy; serious",
    "zh\u00f2ng",
    "zhong4"
  ],
  [
    "\u5df1",
    "oneself",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u7b2c",
    "ordinal #",
    "d\u00ec",
    "di4"
  ],
  [
    "\u53ca",
    "to reach; up to",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u7f8e",
    "beautiful",
    "m\u011bi",
    "mei3"
  ],
  [
    "\u4e1c",
    "East",
    "d\u014dng",
    "dong1"
  ],
  [
    "\u6771",
    "\u4e1c (East)",
    "d\u014dng",
    "dong1"
  ],
  [
    "\u5973",
    "female",
    "n\u01da",
    "nu:3"
  ],
  [
    "\u6253",
    "to hit",
    "d\u01ce",
    "da3"
  ],
  [
    "\u65b0",
    "new",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u518d",
    "again; then",
    "z\u00e0i",
    "zai4"
  ],
  [
    "\u5934",
    "head",
    "t\u00f3u",
    "tou2"
  ],
  [
    "\u982d",
    "\u5934 (head)",
    "t\u00f3u",
    "tou2"
  ],
  [
    "\u65e5",
    "sun; day",
    "r\u00ec",
    "ri4"
  ],
  [
    "\u52a0",
    "to add; plus",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u6b21",
    "mw. for time",
    "c\u00ec",
    "ci4"
  ],
  [
    "\u4e8c",
    "two",
    "\u00e8r",
    "er4"
  ],
  [
    "\u5148",
    "first; former",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u4e24",
    "two (used with mw.)",
    "li\u01ceng",
    "liang3"
  ],
  [
    "\u5169",
    "\u4e24 (two (used with mw.))",
    "li\u01ceng",
    "liang3"
  ],
  [
    "\u592a",
    "too (much)",
    "t\u00e0i",
    "tai4"
  ],
  [
    "\u8be5",
    "should",
    "g\u0101i",
    "gai1"
  ],
  [
    "\u8a72",
    "\u8be5 (should)",
    "g\u0101i",
    "gai1"
  ],
  [
    "\u8001",
    "old prefix",
    "l\u01ceo",
    "lao3"
  ],
  [
    "\u8868",
    "exterior surface",
    "bi\u01ceo",
    "biao3"
  ],
  [
    "\u5e76",
    "(not) at all",
    "b\u00ecng",
    "bing4"
  ],
  [
    "\u4e26",
    "\u5e76 ((not) at all)",
    "b\u00ecng",
    "bing4"
  ],
  [
    "\u4ea7",
    "give birth; to produce",
    "ch\u01cen",
    "chan3"
  ],
  [
    "\u7522",
    "\u4ea7 (give birth; to produce)",
    "ch\u01cen",
    "chan3"
  ],
  [
    "\u4fe1",
    "letter; to trust",
    "x\u00ecn",
    "xin4"
  ],
  [
    "\u611f",
    "to feel; emotion",
    "g\u01cen",
    "gan3"
  ],
  [
    "\u7279",
    "special; unique",
    "t\u00e8",
    "te4"
  ],
  [
    "\u5408",
    "to close; together",
    "h\u00e9",
    "he2"
  ],
  [
    "\u89c9",
    "to feel",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u89ba",
    "\u89c9 (to feel)",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u542c",
    "to listen",
    "t\u012bng",
    "ting1"
  ],
  [
    "\u807d",
    "\u542c (to listen)",
    "t\u012bng",
    "ting1"
  ],
  [
    "\u8c22",
    "to thank",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u8b1d",
    "\u8c22 (to thank)",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u6bd4",
    "comparison p.",
    "b\u01d0",
    "bi3"
  ],
  [
    "\u4f7f",
    "to cause",
    "sh\u01d0",
    "shi3"
  ],
  [
    "\u53c8",
    "again; also",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u6216",
    "maybe; perhaps",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u5ea6",
    "degree; measure",
    "d\u00f9",
    "du4"
  ],
  [
    "\u5e38",
    "always; general",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u5236",
    "to manufacture",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u540d",
    "name",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u66f4",
    "more",
    "g\u00e8ng",
    "geng4"
  ],
  [
    "\u5229",
    "sharp; benefit",
    "l\u00ec",
    "li4"
  ],
  [
    "\u5185",
    "inside; inner",
    "n\u00e8i",
    "nei4"
  ],
  [
    "\u5167",
    "\u5185 (inside; inner)",
    "n\u00e8i",
    "nei4"
  ],
  [
    "\u7269",
    "thing; object",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u4f4d",
    "mw. for people",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u7531",
    "from; by",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u653f",
    "political",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u573a",
    "open area",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u5834",
    "\u573a (open area)",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u65af",
    "this",
    "s\u012b",
    "si1"
  ],
  [
    "\u53f0",
    "platform",
    "t\u00e1i",
    "tai2"
  ],
  [
    "\u81fa",
    "\u53f0 (platform)",
    "t\u00e1i",
    "tai2"
  ],
  [
    "\u544a",
    "to tell",
    "g\u00e0o",
    "gao4"
  ],
  [
    "\u50cf",
    "to be like; image",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u63d0",
    "to lift",
    "t\u00ed",
    "ti2"
  ],
  [
    "\u8ba4",
    "to recognize",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u8a8d",
    "\u8ba4 (to recognize)",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u5411",
    "towards; direction",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u56db",
    "four",
    "s\u00ec",
    "si4"
  ],
  [
    "\u6587",
    "language; culture",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u624d",
    "just now; ability",
    "c\u00e1i",
    "cai2"
  ],
  [
    "\u5feb",
    "rapid; almost",
    "ku\u00e0i",
    "kuai4"
  ],
  [
    "\u5316",
    "to change into",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u5165",
    "to enter",
    "r\u00f9",
    "ru4"
  ],
  [
    "\u6b7b",
    "to die",
    "s\u01d0",
    "si3"
  ],
  [
    "\u4fdd",
    "to defend",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u63a5",
    "to receive; to join",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u6d3b",
    "to live",
    "hu\u00f3",
    "huo2"
  ],
  [
    "\u53d7",
    "to receive",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u5462",
    "how about\u2026?; continuing p.",
    "ne",
    "ne"
  ],
  [
    "\u519b",
    "army",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u8ecd",
    "\u519b (army)",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u54c1",
    "goods; product",
    "p\u01d0n",
    "pin3"
  ],
  [
    "\u89e3",
    "to divide; to explain",
    "ji\u011b",
    "jie3"
  ],
  [
    "\u7cfb",
    "system; to connect",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u76f4",
    "straight",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u5b89",
    "safe; calm",
    "\u0101n",
    "an1"
  ],
  [
    "\u901a",
    "to connect; open",
    "t\u014dng",
    "tong1"
  ],
  [
    "\u8ba1",
    "to plan",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u8a08",
    "\u8ba1 (to plan)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u7231",
    "to love",
    "\u00e0i",
    "ai4"
  ],
  [
    "\u611b",
    "\u7231 (to love)",
    "\u00e0i",
    "ai4"
  ],
  [
    "\u9898",
    "topic; subject",
    "t\u00ed",
    "ti2"
  ],
  [
    "\u984c",
    "\u9898 (topic; subject)",
    "t\u00ed",
    "ti2"
  ],
  [
    "\u4efb",
    "to appoint; office",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u6559",
    "to teach",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u4e94",
    "five",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u6c14",
    "air",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u6c23",
    "\u6c14 (air)",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u539f",
    "original",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u5458",
    "employee; member",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u54e1",
    "\u5458 (employee; member)",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u627e",
    "to look for",
    "zh\u01ceo",
    "zhao3"
  ],
  [
    "\u5904",
    "place; location",
    "ch\u00f9",
    "chu4"
  ],
  [
    "\u8655",
    "\u5904 (place; location)",
    "ch\u00f9",
    "chu4"
  ],
  [
    "\u5988",
    "mum",
    "m\u0101",
    "ma1"
  ],
  [
    "\u5abd",
    "\u5988 (mum)",
    "m\u0101",
    "ma1"
  ],
  [
    "\u4ee3",
    "to substitute; era",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u5e73",
    "flat; equal",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u53d8",
    "to change",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u8b8a",
    "\u53d8 (to change)",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u95e8",
    "door; gate",
    "m\u00e9n",
    "men2"
  ],
  [
    "\u9580",
    "\u95e8 (door; gate)",
    "m\u00e9n",
    "men2"
  ],
  [
    "\u653e",
    "to put",
    "f\u00e0ng",
    "fang4"
  ],
  [
    "\u8f66",
    "vehicle",
    "ch\u0113",
    "che1"
  ],
  [
    "\u8eca",
    "\u8f66 (vehicle)",
    "ch\u0113",
    "che1"
  ],
  [
    "\u4f55",
    "question word",
    "h\u00e9",
    "he2"
  ],
  [
    "\u5e08",
    "teacher; master",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u5e2b",
    "\u5e08 (teacher; master)",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u7ed3",
    "to tie",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u7d50",
    "\u7ed3 (to tie)",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u6c34",
    "water; liquid",
    "shu\u01d0",
    "shui3"
  ],
  [
    "\u4e16",
    "life; age; world",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8ddf",
    "with; to follow",
    "g\u0113n",
    "gen1"
  ],
  [
    "\u4ef6",
    "mw. for items ",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u76ee",
    "eye",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u5019",
    "to wait; season",
    "h\u00f2u",
    "hou4"
  ],
  [
    "\u8457",
    "manifest",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u53e3",
    "mouth",
    "k\u01d2u",
    "kou3"
  ],
  [
    "\u4eca",
    "today; now",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u51e0",
    "how many",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u5e7e",
    "\u51e0 (how many)",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u91d1",
    "gold",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u8bb8",
    "to permit",
    "x\u01d4",
    "xu3"
  ],
  [
    "\u8a31",
    "\u8bb8 (to permit)",
    "x\u01d4",
    "xu3"
  ],
  [
    "\u5149",
    "bright; only",
    "gu\u0101ng",
    "guang1"
  ],
  [
    "\u6570",
    "number; figure",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u6578",
    "\u6570 (number; figure)",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u514b",
    "gram; to overcome",
    "k\u00e8",
    "ke4"
  ],
  [
    "\u5357",
    "South",
    "n\u00e1n",
    "nan2"
  ],
  [
    "\u52a1",
    "affair; business",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u52d9",
    "\u52a1 (affair; business)",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u5b8c",
    "to finish; complete",
    "w\u00e1n",
    "wan2"
  ],
  [
    "\u9700",
    "to need; to want",
    "x\u016b",
    "xu1"
  ],
  [
    "\u7ba1",
    "to control",
    "gu\u01cen",
    "guan3"
  ],
  [
    "\u975e",
    "not",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u5c11",
    "few; little",
    "sh\u01ceo",
    "shao3"
  ],
  [
    "\u6218",
    "to fight; war",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u6230",
    "\u6218 (to fight; war)",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u5317",
    "North",
    "b\u011bi",
    "bei3"
  ],
  [
    "\u671f",
    "period of time",
    "q\u012b",
    "qi1"
  ],
  [
    "\u5e72",
    "to do",
    "g\u00e0n",
    "gan4"
  ],
  [
    "\u5e79",
    "\u5e72 (to do)",
    "g\u00e0n",
    "gan4"
  ],
  [
    "\u6708",
    "moon; month",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u58f0",
    "sound; voice",
    "sh\u0113ng",
    "sheng1"
  ],
  [
    "\u8072",
    "\u58f0 (sound; voice)",
    "sh\u0113ng",
    "sheng1"
  ],
  [
    "\u5e26",
    "to bring; belt",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u5e36",
    "\u5e26 (to bring; belt)",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u767d",
    "white; pure",
    "b\u00e1i",
    "bai2"
  ],
  [
    "\u5e02",
    "market; city",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u603b",
    "always",
    "z\u01d2ng",
    "zong3"
  ],
  [
    "\u7e3d",
    "\u603b (always)",
    "z\u01d2ng",
    "zong3"
  ],
  [
    "\u91cf",
    "quantity; amount",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u7acb",
    "to stand",
    "l\u00ec",
    "li4"
  ],
  [
    "\u795e",
    "deity; spirit",
    "sh\u00e9n",
    "shen2"
  ],
  [
    "\u547d",
    "life; fate",
    "m\u00ecng",
    "ming4"
  ],
  [
    "\u5c14",
    "thus; thou",
    "\u011br",
    "er3"
  ],
  [
    "\u723e",
    "\u5c14 (thus; thou)",
    "\u011br",
    "er3"
  ],
  [
    "\u81f3",
    "to arrive",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u8bc9",
    "to tell; to complain",
    "s\u00f9",
    "su4"
  ],
  [
    "\u8a34",
    "\u8bc9 (to tell; to complain)",
    "s\u00f9",
    "su4"
  ],
  [
    "\u62a5",
    "to announce",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u5831",
    "\u62a5 (to announce)",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u5b69",
    "child",
    "h\u00e1i",
    "hai2"
  ],
  [
    "\u8bb0",
    "to record",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u8a18",
    "\u8bb0 (to record)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u6d77",
    "ocean; sea",
    "h\u01cei",
    "hai3"
  ],
  [
    "\u8272",
    "color",
    "s\u00e8",
    "se4"
  ],
  [
    "\u9519",
    "mistake; bad",
    "cu\u00f2",
    "cuo4"
  ],
  [
    "\u932f",
    "\u9519 (mistake; bad)",
    "cu\u00f2",
    "cuo4"
  ],
  [
    "\u4f4f",
    "to live; to reside",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u5404",
    "each; every",
    "g\u00e8",
    "ge4"
  ],
  [
    "\u671b",
    "to gaze; to hope",
    "w\u00e0ng",
    "wang4"
  ],
  [
    "\u96be",
    "difficult",
    "n\u00e1n",
    "nan2"
  ],
  [
    "\u96e3",
    "\u96be (difficult)",
    "n\u00e1n",
    "nan2"
  ],
  [
    "\u8bf7",
    "to invite",
    "q\u01d0ng",
    "qing3"
  ],
  [
    "\u8acb",
    "\u8bf7 (to invite)",
    "q\u01d0ng",
    "qing3"
  ],
  [
    "\u4eb2",
    "parent; relative",
    "q\u012bn",
    "qin1"
  ],
  [
    "\u89aa",
    "\u4eb2 (parent; relative)",
    "q\u012bn",
    "qin1"
  ],
  [
    "\u5fc5",
    "must; will",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u8bba",
    "opinion; theory",
    "l\u00f9n",
    "lun4"
  ],
  [
    "\u8ad6",
    "\u8bba (opinion; theory)",
    "l\u00f9n",
    "lun4"
  ],
  [
    "\u516d",
    "six",
    "li\u00f9",
    "liu4"
  ],
  [
    "\u8def",
    "road",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u6bcf",
    "each; every",
    "m\u011bi",
    "mei3"
  ],
  [
    "\u5f0f",
    "type; style",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u670d",
    "clothes; to serve",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u8d44",
    "property; wealth",
    "z\u012b",
    "zi1"
  ],
  [
    "\u8cc7",
    "\u8d44 (property; wealth)",
    "z\u012b",
    "zi1"
  ],
  [
    "\u516b",
    "eight",
    "b\u0101",
    "ba1"
  ],
  [
    "\u773c",
    "eye",
    "y\u01cen",
    "yan3"
  ],
  [
    "\u53f8",
    "to manage; department",
    "s\u012b",
    "si1"
  ],
  [
    "\u9a6c",
    "horse",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u99ac",
    "\u9a6c (horse)",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u6761",
    "mw. long thing obj.",
    "ti\u00e1o",
    "tiao2"
  ],
  [
    "\u689d",
    "\u6761 (mw. long thing obj.)",
    "ti\u00e1o",
    "tiao2"
  ],
  [
    "\u6307",
    "finger; refer to",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u5c55",
    "to spread out",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u6c42",
    "to look for; to request",
    "qi\u00fa",
    "qiu2"
  ],
  [
    "\u6b22",
    "joyous",
    "hu\u0101n",
    "huan1"
  ],
  [
    "\u6b61",
    "\u6b22 (joyous)",
    "hu\u0101n",
    "huan1"
  ],
  [
    "\u4e14",
    "moreover; both",
    "qi\u011b",
    "qie3"
  ],
  [
    "\u7edf",
    "to unite",
    "t\u01d2ng",
    "tong3"
  ],
  [
    "\u7d71",
    "\u7edf (to unite)",
    "t\u01d2ng",
    "tong3"
  ],
  [
    "\u54ea",
    "which",
    "n\u01ce",
    "na3"
  ],
  [
    "\u53cd",
    "contrary; opposite",
    "f\u01cen",
    "fan3"
  ],
  [
    "\u601d",
    "to think; to consider",
    "s\u012b",
    "si1"
  ],
  [
    "\u6743",
    "authority; power",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u6b0a",
    "\u6743 (authority; power)",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u559c",
    "to like",
    "x\u01d0",
    "xi3"
  ],
  [
    "\u5efa",
    "to establish",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u786e",
    "solid; real",
    "qu\u00e8",
    "que4"
  ],
  [
    "\u78ba",
    "\u786e (solid; real)",
    "qu\u00e8",
    "que4"
  ],
  [
    "\u672f",
    "method; technique",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u8853",
    "\u672f (method; technique)",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u5f3a",
    "strong; powerful",
    "qi\u00e1ng",
    "qiang2"
  ],
  [
    "\u5f37",
    "\u5f3a (strong; powerful)",
    "qi\u00e1ng",
    "qiang2"
  ],
  [
    "\u51b3",
    "to decide",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u6c7a",
    "\u51b3 (to decide)",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u529e",
    "to do; to manage",
    "b\u00e0n",
    "ban4"
  ],
  [
    "\u8fa6",
    "\u529e (to do; to manage)",
    "b\u00e0n",
    "ban4"
  ],
  [
    "\u58eb",
    "scholar; soldier",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8fb9",
    "side; edge",
    "bi\u0101n",
    "bian1"
  ],
  [
    "\u908a",
    "\u8fb9 (side; edge)",
    "bi\u0101n",
    "bian1"
  ],
  [
    "\u4e5d",
    "nine",
    "ji\u01d4",
    "jiu3"
  ],
  [
    "\u59cb",
    "to begin",
    "sh\u01d0",
    "shi3"
  ],
  [
    "\u51c6",
    "to allow",
    "zh\u01d4n",
    "zhun3"
  ],
  [
    "\u6e96",
    "\u51c6 (to allow)",
    "zh\u01d4n",
    "zhun3"
  ],
  [
    "\u754c",
    "boundary; scope",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u54e6",
    "oh",
    "\u00f3",
    "o2"
  ],
  [
    "\u9009",
    "to choose",
    "xu\u01cen",
    "xuan3"
  ],
  [
    "\u9078",
    "\u9009 (to choose)",
    "xu\u01cen",
    "xuan3"
  ],
  [
    "\u7a0b",
    "rule; order",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u5fb7",
    "virtue; ethics",
    "d\u00e9",
    "de2"
  ],
  [
    "\u8fbe",
    "to reach",
    "d\u00e1",
    "da2"
  ],
  [
    "\u9054",
    "\u8fbe (to reach)",
    "d\u00e1",
    "da2"
  ],
  [
    "\u8bbe",
    "to set up",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u8a2d",
    "\u8bbe (to set up)",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u57fa",
    "base; foundation",
    "j\u012b",
    "ji1"
  ],
  [
    "\u4e49",
    "justice",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7fa9",
    "\u4e49 (justice)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8bc1",
    "proof",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u8b49",
    "\u8bc1 (proof)",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u5e2e",
    "to help",
    "b\u0101ng",
    "bang1"
  ],
  [
    "\u5e6b",
    "\u5e2e (to help)",
    "b\u0101ng",
    "bang1"
  ],
  [
    "\u4e03",
    "seven",
    "q\u012b",
    "qi1"
  ],
  [
    "\u533a",
    "area",
    "q\u016b",
    "qu1"
  ],
  [
    "\u5340",
    "\u533a (area)",
    "q\u016b",
    "qu1"
  ],
  [
    "\u6cbb",
    "to govern; solve",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u821e",
    "to dance",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u665a",
    "evening; late",
    "w\u01cen",
    "wan3"
  ],
  [
    "\u53cb",
    "friend",
    "y\u01d2u",
    "you3"
  ],
  [
    "\u53eb",
    "to call",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u7b97",
    "to calculate",
    "su\u00e0n",
    "suan4"
  ],
  [
    "\u8fd0",
    "to move; fortune",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u904b",
    "\u8fd0 (to move; fortune)",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u961f",
    "team; group",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u968a",
    "\u961f (team; group)",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u6740",
    "to kill",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u6bba",
    "\u6740 (to kill)",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u62c9",
    "to pull",
    "l\u0101",
    "la1"
  ],
  [
    "\u4ea4",
    "to deliver; to pay",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u8c01",
    "who",
    "shu\u00ed",
    "shui2"
  ],
  [
    "\u8ab0",
    "\u8c01 (who)",
    "shu\u00ed",
    "shui2"
  ],
  [
    "\u6536",
    "to receive",
    "sh\u014du",
    "shou1"
  ],
  [
    "\u6301",
    "to maintain; to manage",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u5374",
    "but; however",
    "qu\u00e8",
    "que4"
  ],
  [
    "\u537b",
    "\u5374 (but; however)",
    "qu\u00e8",
    "que4"
  ],
  [
    "\u79bb",
    "to leave; (distance) from",
    "l\u00ed",
    "li2"
  ],
  [
    "\u96e2",
    "\u79bb (to leave; (distance) from)",
    "l\u00ed",
    "li2"
  ],
  [
    "\u6e05",
    "clear",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u5355",
    "single; list",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u55ae",
    "\u5355 (single; list)",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u5907",
    "get ready",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u5099",
    "\u5907 (get ready)",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u4e66",
    "book",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u66f8",
    "\u4e66 (book)",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u98ce",
    "wind",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u98a8",
    "\u98ce (wind)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u5de6",
    "left",
    "zu\u01d2",
    "zuo3"
  ],
  [
    "\u793e",
    "society",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u5c71",
    "mountain",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u4fbf",
    "ordinary; convenient",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u5f20",
    "mw. for flat obj.",
    "zh\u0101ng",
    "zhang1"
  ],
  [
    "\u5f35",
    "\u5f20 (mw. for flat obj.)",
    "zh\u0101ng",
    "zhang1"
  ],
  [
    "\u53f3",
    "right",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u7b11",
    "to laugh",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u533b",
    "medical",
    "y\u012b",
    "yi1"
  ],
  [
    "\u91ab",
    "\u533b (medical)",
    "y\u012b",
    "yi1"
  ],
  [
    "\u7a7a",
    "empty; air",
    "k\u014dng",
    "kong1"
  ],
  [
    "\u793a",
    "to show",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u5207",
    "to cut; to slice",
    "qi\u0113",
    "qie1"
  ],
  [
    "\u4f20",
    "to transfer; send",
    "chu\u00e1n",
    "chuan2"
  ],
  [
    "\u50b3",
    "\u4f20 (to transfer; send)",
    "chu\u00e1n",
    "chuan2"
  ],
  [
    "\u5931",
    "to lose",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u67e5",
    "to research",
    "ch\u00e1",
    "cha2"
  ],
  [
    "\u7167",
    "according to; to shine",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u672a",
    "not; have not",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u9020",
    "to build; to make",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u6574",
    "ordered",
    "zh\u011bng",
    "zheng3"
  ],
  [
    "\u683c",
    "grid; pattern",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u8bc6",
    "to know",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u8b58",
    "\u8bc6 (to know)",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u8fd1",
    "near",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u5403",
    "to eat",
    "ch\u012b",
    "chi1"
  ],
  [
    "\u55ab",
    "\u5403 (to eat)",
    "ch\u012b",
    "chi1"
  ],
  [
    "\u6539",
    "to change",
    "g\u01cei",
    "gai3"
  ],
  [
    "\u7238",
    "father",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u5f71",
    "image",
    "y\u01d0ng",
    "ying3"
  ],
  [
    "\u8f6c",
    "to revolve; to turn",
    "zhu\u01cen",
    "zhuan3"
  ],
  [
    "\u8f49",
    "\u8f6c (to revolve; to turn)",
    "zhu\u01cen",
    "zhuan3"
  ],
  [
    "\u89c2",
    "to watch",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u89c0",
    "\u89c2 (to watch)",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u6d41",
    "to flow",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u9662",
    "courtyard; institution",
    "yu\u00e0n",
    "yuan4"
  ],
  [
    "\u5219",
    "in contrast; norm",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u5247",
    "\u5219 (in contrast; norm)",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u4e50",
    "happy",
    "l\u00e8",
    "le4"
  ],
  [
    "\u6a02",
    "\u4e50 (happy)",
    "l\u00e8",
    "le4"
  ],
  [
    "\u5b57",
    "word; character",
    "z\u00ec",
    "zi4"
  ],
  [
    "\u6b65",
    "a step",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u5143",
    "unit of money",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u636e",
    "according to; to seize",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u64da",
    "\u636e (according to; to seize)",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u7ea6",
    "appointment; to invite",
    "yu\u0113",
    "yue1"
  ],
  [
    "\u7d04",
    "\u7ea6 (appointment; to invite)",
    "yu\u0113",
    "yue1"
  ],
  [
    "\u4e07",
    "ten thousand",
    "w\u00e0n",
    "wan4"
  ],
  [
    "\u842c",
    "\u4e07 (ten thousand)",
    "w\u00e0n",
    "wan4"
  ],
  [
    "\u8c08",
    "to chat",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u8ac7",
    "\u8c08 (to chat)",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u8c61",
    "elephant; form",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u5e0c",
    "rare: hope",
    "x\u012b",
    "xi1"
  ],
  [
    "\u5f62",
    "form; appear",
    "x\u00edng",
    "xing2"
  ],
  [
    "\u5373",
    "namely; at once",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u5bfc",
    "to guide",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u5c0e",
    "\u5bfc (to guide)",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u7247",
    "thin piece; slice",
    "pi\u00e0n",
    "pian4"
  ],
  [
    "\u6839",
    "root; basis",
    "g\u0113n",
    "gen1"
  ],
  [
    "\u79d1",
    "division",
    "k\u0113",
    "ke1"
  ],
  [
    "\u53d6",
    "to take",
    "q\u01d4",
    "qu3"
  ],
  [
    "\u521a",
    "just (now); hard",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u525b",
    "\u521a (just (now); hard)",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u7ebf",
    "thread",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u7dda",
    "\u7ebf (thread)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u94b1",
    "money",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u9322",
    "\u94b1 (money)",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u6848",
    "legal case; record",
    "\u00e0n",
    "an4"
  ],
  [
    "\u89c4",
    "a rule; compass",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u898f",
    "\u89c4 (a rule; compass)",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u5171",
    "common; general",
    "g\u00f2ng",
    "gong4"
  ],
  [
    "\u8fdc",
    "far; distant",
    "yu\u01cen",
    "yuan3"
  ],
  [
    "\u9060",
    "\u8fdc (far; distant)",
    "yu\u01cen",
    "yuan3"
  ],
  [
    "\u62ff",
    "to take",
    "n\u00e1",
    "na2"
  ],
  [
    "\u623f",
    "house; room",
    "f\u00e1ng",
    "fang2"
  ],
  [
    "\u5546",
    "commerce",
    "sh\u0101ng",
    "shang1"
  ],
  [
    "\u5468",
    "circle; week",
    "zh\u014du",
    "zhou1"
  ],
  [
    "\u9664",
    "to remove",
    "ch\u00fa",
    "chu2"
  ],
  [
    "\u65e9",
    "early; morning",
    "z\u01ceo",
    "zao3"
  ],
  [
    "\u8054",
    "to unite; to join",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u806f",
    "\u8054 (to unite; to join)",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u5668",
    "device; tool",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u7c7b",
    "kind; category",
    "l\u00e8i",
    "lei4"
  ],
  [
    "\u985e",
    "\u7c7b (kind; category)",
    "l\u00e8i",
    "lei4"
  ],
  [
    "\u82b1",
    "flower; to spend",
    "hu\u0101",
    "hua1"
  ],
  [
    "\u4e13",
    "specialized",
    "zhu\u0101n",
    "zhuan1"
  ],
  [
    "\u5c08",
    "\u4e13 (specialized)",
    "zhu\u0101n",
    "zhuan1"
  ],
  [
    "\u75c5",
    "illness; disease",
    "b\u00ecng",
    "bing4"
  ],
  [
    "\u7ec4",
    "to organize; group",
    "z\u01d4",
    "zu3"
  ],
  [
    "\u7d44",
    "\u7ec4 (to organize; group)",
    "z\u01d4",
    "zu3"
  ],
  [
    "\u5e03",
    "cloth; to spread",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u606f",
    "breath; to rest",
    "x\u012b",
    "xi1"
  ],
  [
    "\u5177",
    "tool; ability",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u89c6",
    "to look at",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8996",
    "\u89c6 (to look at)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8c03",
    "to transfer; tone",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u8abf",
    "\u8c03 (to transfer; tone)",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u98de",
    "to fly",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u98db",
    "\u98de (to fly)",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u7cbe",
    "essence; vitality",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u7537",
    "male",
    "n\u00e1n",
    "nan2"
  ],
  [
    "\u5bb9",
    "to contain; appearance",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u8bae",
    "to discuss",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8b70",
    "\u8bae (to discuss)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8fde",
    "to link; even\u2026",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u9023",
    "\u8fde (to link; even\u2026)",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u738b",
    "king",
    "w\u00e1ng",
    "wang2"
  ],
  [
    "\u592b",
    "husband",
    "f\u016b",
    "fu1"
  ],
  [
    "\u53f7",
    "number",
    "h\u00e0o",
    "hao4"
  ],
  [
    "\u865f",
    "\u53f7 (number)",
    "h\u00e0o",
    "hao4"
  ],
  [
    "\u6280",
    "skill",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u6807",
    "sign; prize",
    "bi\u0101o",
    "biao1"
  ],
  [
    "\u6a19",
    "\u6807 (sign; prize)",
    "bi\u0101o",
    "biao1"
  ],
  [
    "\u8a00",
    "words; speech",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u5b98",
    "official",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u6d88",
    "to vanish; need",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u706b",
    "fire",
    "hu\u01d2",
    "huo3"
  ],
  [
    "\u88c5",
    "clothing; to pretend",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u88dd",
    "\u88c5 (clothing; to pretend)",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u5305",
    "to wrap; bag",
    "b\u0101o",
    "bao1"
  ],
  [
    "\u8bd5",
    "to test; to try",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8a66",
    "\u8bd5 (to test; to try)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u9886",
    "neck",
    "l\u01d0ng",
    "ling3"
  ],
  [
    "\u9818",
    "\u9886 (neck)",
    "l\u01d0ng",
    "ling3"
  ],
  [
    "\u591f",
    "to be enough",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u5920",
    "\u591f (to be enough)",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u987b",
    "must; beard",
    "x\u016b",
    "xu1"
  ],
  [
    "\u9808",
    "\u987b (must; beard)",
    "x\u016b",
    "xu1"
  ],
  [
    "\u8b66",
    "police; to warn",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u7403",
    "ball; sphere",
    "qi\u00fa",
    "qiu2"
  ],
  [
    "\u7f51",
    "net; network",
    "w\u01ceng",
    "wang3"
  ],
  [
    "\u7db2",
    "\u7f51 (net; network)",
    "w\u01ceng",
    "wang3"
  ],
  [
    "\u6797",
    "forest",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u5f80",
    "towards",
    "w\u01ceng",
    "wang3"
  ],
  [
    "\u529f",
    "result; achievement",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u6f14",
    "to act;  to develop",
    "y\u01cen",
    "yan3"
  ],
  [
    "\u5174",
    "to rise; to flourish",
    "x\u012bng",
    "xing1"
  ],
  [
    "\u8208",
    "\u5174 (to rise; to flourish)",
    "x\u012bng",
    "xing1"
  ],
  [
    "\u963f",
    "prefix for names",
    "\u0101",
    "a1"
  ],
  [
    "\u8d28",
    "character; quality",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u8cea",
    "\u8d28 (character; quality)",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u97f3",
    "sound; noise",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u6781",
    "extremely",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u6975",
    "\u6781 (extremely)",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u82f1",
    "brave",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u661f",
    "star",
    "x\u012bng",
    "xing1"
  ],
  [
    "\u4ef7",
    "price; value",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u50f9",
    "\u4ef7 (price; value)",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u5bb3",
    "harm",
    "h\u00e0i",
    "hai4"
  ],
  [
    "\u7f57",
    "gauze; to gather",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u7f85",
    "\u7f57 (gauze; to gather)",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u51fb",
    "to hit",
    "j\u012b",
    "ji1"
  ],
  [
    "\u64ca",
    "\u51fb (to hit)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u6ee1",
    "to fill; satisfied",
    "m\u01cen",
    "man3"
  ],
  [
    "\u6eff",
    "\u6ee1 (to fill; satisfied)",
    "m\u01cen",
    "man3"
  ],
  [
    "\u8282",
    "festival",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u7bc0",
    "\u8282 (festival)",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u7ea7",
    "level; rank",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u7d1a",
    "\u7ea7 (level; rank)",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u73a9",
    "to play",
    "w\u00e1n",
    "wan2"
  ],
  [
    "\u8d39",
    "expense",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u8cbb",
    "\u8d39 (expense)",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u56e2",
    "round",
    "tu\u00e1n",
    "tuan2"
  ],
  [
    "\u5718",
    "\u56e2 (round)",
    "tu\u00e1n",
    "tuan2"
  ],
  [
    "\u62a4",
    "to protect",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u8b77",
    "\u62a4 (to protect)",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u53c2",
    "to participate",
    "c\u0101n",
    "can1"
  ],
  [
    "\u53c3",
    "\u53c2 (to participate)",
    "c\u0101n",
    "can1"
  ],
  [
    "\u7559",
    "to stay; to remain",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u670b",
    "friend",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u6ce8",
    "pay attention; to register",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u96c6",
    "to gather; to collect",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u8f7b",
    "light; gentle",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u8f15",
    "\u8f7b (light; gentle)",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u663e",
    "prominent; to show",
    "xi\u01cen",
    "xian3"
  ],
  [
    "\u986f",
    "\u663e (prominent; to show)",
    "xi\u01cen",
    "xian3"
  ],
  [
    "\u5ba2",
    "customer; guest",
    "k\u00e8",
    "ke4"
  ],
  [
    "\u5c40",
    "office; narrow",
    "j\u00fa",
    "ju2"
  ],
  [
    "\u5e95",
    "bottom; end",
    "d\u01d0",
    "di3"
  ],
  [
    "\u4f19",
    "companion; buddy",
    "hu\u01d2",
    "huo3"
  ],
  [
    "\u5925",
    "\u4f19 (companion; buddy)",
    "hu\u01d2",
    "huo3"
  ],
  [
    "\u7eed",
    "to continue",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u7e8c",
    "\u7eed (to continue)",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u6613",
    "easy",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u767e",
    "hundred",
    "b\u01cei",
    "bai3"
  ],
  [
    "\u4e9a",
    "Asia",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u4e9e",
    "\u4e9a (Asia)",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u7236",
    "father",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u590d",
    "to return; to repeat",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u5fa9",
    "\u590d (to return; to repeat)",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u534e",
    "splendid",
    "hu\u00e1",
    "hua2"
  ],
  [
    "\u83ef",
    "\u534e (splendid)",
    "hu\u00e1",
    "hua2"
  ],
  [
    "\u51b5",
    "situation; moreover",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u6cc1",
    "\u51b5 (situation; moreover)",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u6df1",
    "deep; profound",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u7ad9",
    "to stop; station",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u4ee4",
    "command; to order",
    "l\u00ecng",
    "ling4"
  ],
  [
    "\u8d8a",
    "to climb over",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u52a9",
    "to help",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u968f",
    "to follow; comply",
    "su\u00ed",
    "sui2"
  ],
  [
    "\u96a8",
    "\u968f (to follow; comply)",
    "su\u00ed",
    "sui2"
  ],
  [
    "\u60a8",
    "you (polite)",
    "n\u00edn",
    "nin2"
  ],
  [
    "\u57ce",
    "city; town",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u4f17",
    "many; crowd",
    "zh\u00f2ng",
    "zhong4"
  ],
  [
    "\u8846",
    "\u4f17 (many; crowd)",
    "zh\u00f2ng",
    "zhong4"
  ],
  [
    "\u8003",
    "to test",
    "k\u01ceo",
    "kao3"
  ],
  [
    "\u563f",
    "hey",
    "h\u0113i",
    "hei1"
  ],
  [
    "\u4e89",
    "to strive for",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u722d",
    "\u4e89 (to strive for)",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u9645",
    "border; between",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u969b",
    "\u9645 (border; between)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u5b58",
    "to exist; to deposit",
    "c\u00fan",
    "cun2"
  ],
  [
    "\u4f9b",
    "to supply",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u4f24",
    "wound",
    "sh\u0101ng",
    "shang1"
  ],
  [
    "\u50b7",
    "\u4f24 (wound)",
    "sh\u0101ng",
    "shang1"
  ],
  [
    "\u5199",
    "to write",
    "xi\u011b",
    "xie3"
  ],
  [
    "\u5beb",
    "\u5199 (to write)",
    "xi\u011b",
    "xie3"
  ],
  [
    "\u53e6",
    "other; another",
    "l\u00ecng",
    "ling4"
  ],
  [
    "\u652f",
    "to support",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u5c3d",
    "to greatest extent",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u76e1",
    "\u5c3d (to greatest extent)",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u6e38",
    "to swim; to travel",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u904a",
    "\u6e38 (to swim; to travel)",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u7edd",
    "sever; absolutely",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u7d55",
    "\u7edd (sever; absolutely)",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u8d5b",
    "competition",
    "s\u00e0i",
    "sai4"
  ],
  [
    "\u8cfd",
    "\u8d5b (competition)",
    "s\u00e0i",
    "sai4"
  ],
  [
    "\u65ad",
    "to break; defiitely ",
    "du\u00e0n",
    "duan4"
  ],
  [
    "\u65b7",
    "\u65ad (to break; defiitely )",
    "du\u00e0n",
    "duan4"
  ],
  [
    "\u9996",
    "head; chief",
    "sh\u01d2u",
    "shou3"
  ],
  [
    "\u6295",
    "to cast",
    "t\u00f3u",
    "tou2"
  ],
  [
    "\u5947",
    "strange",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u578b",
    "model; type",
    "x\u00edng",
    "xing2"
  ],
  [
    "\u6548",
    "effect",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u70ed",
    "hot",
    "r\u00e8",
    "re4"
  ],
  [
    "\u71b1",
    "\u70ed (hot)",
    "r\u00e8",
    "re4"
  ],
  [
    "\u6599",
    "material; stuff",
    "li\u00e0o",
    "liao4"
  ],
  [
    "\u9ed1",
    "black",
    "h\u0113i",
    "hei1"
  ],
  [
    "\u8840",
    "blood",
    "xi\u011b",
    "xie3"
  ],
  [
    "\u5750",
    "to sit",
    "zu\u00f2",
    "zuo4"
  ],
  [
    "\u7ef4",
    "to maintain",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u7dad",
    "\u7ef4 (to maintain)",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u534a",
    "half",
    "b\u00e0n",
    "ban4"
  ],
  [
    "\u63a8",
    "to push",
    "tu\u012b",
    "tui1"
  ],
  [
    "\u7814",
    "study; research",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u5f15",
    "to pull; to guide",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u9001",
    "to give; to deliver",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u613f",
    "to hope; willing",
    "yu\u00e0n",
    "yuan4"
  ],
  [
    "\u9858",
    "\u613f (to hope; willing)",
    "yu\u00e0n",
    "yuan4"
  ],
  [
    "\u66fe",
    "once; already",
    "c\u00e9ng",
    "ceng2"
  ],
  [
    "\u56fe",
    "diagram; map",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u5716",
    "\u56fe (diagram; map)",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u4e4e",
    "in; at; from",
    "h\u016b",
    "hu1"
  ],
  [
    "\u8f83",
    "comparatively",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u8f03",
    "\u8f83 (comparatively)",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u5ba4",
    "room",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u6389",
    "to fall; to lose",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u5e9c",
    "seat of power",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u5f8b",
    "law",
    "l\u01dc",
    "lu:4"
  ],
  [
    "\u5ff5",
    "to miss (sb); thought",
    "ni\u00e0n",
    "nian4"
  ],
  [
    "\u505c",
    "to stop",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u6bcd",
    "mother",
    "m\u01d4",
    "mu3"
  ],
  [
    "\u4e70",
    "to buy",
    "m\u01cei",
    "mai3"
  ],
  [
    "\u8cb7",
    "\u4e70 (to buy)",
    "m\u01cei",
    "mai3"
  ],
  [
    "\u7d27",
    "strict; tight",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u7dca",
    "\u7d27 (strict; tight)",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u7a76",
    "after all; to investigate",
    "ji\u016b",
    "jiu1"
  ],
  [
    "\u5bdf",
    "to examine; to observe",
    "ch\u00e1",
    "cha2"
  ],
  [
    "\u6015",
    "to fear",
    "p\u00e0",
    "pa4"
  ],
  [
    "\u5b9d",
    "jewel; treasure",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u5bf6",
    "\u5b9d (jewel; treasure)",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u6821",
    "school",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u5361",
    "card; to block",
    "k\u01ce",
    "ka3"
  ],
  [
    "\u5bc6",
    "secret",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u9a8c",
    "to examine",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u9a57",
    "\u9a8c (to examine)",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u9152",
    "wine",
    "ji\u01d4",
    "jiu3"
  ],
  [
    "\u843d",
    "to set; to decline",
    "lu\u00f2",
    "luo4"
  ],
  [
    "\u8bed",
    "language; speech",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u8a9e",
    "\u8bed (language; speech)",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u5386",
    "calendar; to experience",
    "l\u00ec",
    "li4"
  ],
  [
    "\u6b77",
    "\u5386 (calendar; to experience)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u80af",
    "to agree",
    "k\u011bn",
    "ken3"
  ],
  [
    "\u5f85",
    "to wait; about to",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u4efd",
    "mw. for papers, sets",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u5e7f",
    "wide",
    "gu\u01ceng",
    "guang3"
  ],
  [
    "\u5ee3",
    "\u5e7f (wide)",
    "gu\u01ceng",
    "guang3"
  ],
  [
    "\u79f0",
    "to weigh; name",
    "ch\u0113ng",
    "cheng1"
  ],
  [
    "\u7a31",
    "\u79f0 (to weigh; name)",
    "ch\u0113ng",
    "cheng1"
  ],
  [
    "\u7ea2",
    "red",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u7d05",
    "\u7ea2 (red)",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u62b1",
    "to carry in arms",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u6b62",
    "to prohibit",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u5df4",
    "to stick to; close to ",
    "b\u0101",
    "ba1"
  ],
  [
    "\u55ef",
    "approval interjection",
    "en",
    "en"
  ],
  [
    "\u8bb2",
    "to speak; lecture",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u8b1b",
    "\u8bb2 (to speak; lecture)",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u8425",
    "camp; barracks",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u71df",
    "\u8425 (camp; barracks)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u867d",
    "although",
    "su\u012b",
    "sui1"
  ],
  [
    "\u96d6",
    "\u867d (although)",
    "su\u012b",
    "sui1"
  ],
  [
    "\u5c3c",
    "Buddhist nun",
    "n\u00ed",
    "ni2"
  ],
  [
    "\u77f3",
    "stone",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u5426",
    "to deny",
    "f\u01d2u",
    "fou3"
  ],
  [
    "\u9669",
    "danger",
    "xi\u01cen",
    "xian3"
  ],
  [
    "\u96aa",
    "\u9669 (danger)",
    "xi\u01cen",
    "xian3"
  ],
  [
    "\u4f4e",
    "low; beneath",
    "d\u012b",
    "di1"
  ],
  [
    "\u4ec5",
    "barely; merely",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u50c5",
    "\u4ec5 (barely; merely)",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u6551",
    "to save",
    "ji\u00f9",
    "jiu4"
  ],
  [
    "\u4e60",
    "to study; habit",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u7fd2",
    "\u4e60 (to study; habit)",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u4f3c",
    "-like",
    "s\u00ec",
    "si4"
  ],
  [
    "\u7ec8",
    "end; finish",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u7d42",
    "\u7ec8 (end; finish)",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u8db3",
    "foot",
    "z\u00fa",
    "zu2"
  ],
  [
    "\u6bb5",
    "section",
    "du\u00e0n",
    "duan4"
  ],
  [
    "\u5212",
    "to plan; row",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u5283",
    "\u5212 (to plan; row)",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u8d85",
    "to exceed; super-",
    "ch\u0101o",
    "chao1"
  ],
  [
    "\u521b",
    "a wound",
    "chu\u0101ng",
    "chuang1"
  ],
  [
    "\u5275",
    "\u521b (a wound)",
    "chu\u0101ng",
    "chuang1"
  ],
  [
    "\u9879",
    "item; back of neck",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u9805",
    "\u9879 (item; back of neck)",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u4e45",
    "long (time)",
    "ji\u01d4",
    "jiu3"
  ],
  [
    "\u81f4",
    "fine; delicate",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u7ee7",
    "to carry on",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u7e7c",
    "\u7ee7 (to carry on)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u4f01",
    "plan a project; stand on tiptoe",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u6545",
    "old; happening",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u63a7",
    "to accuse; manage",
    "k\u00f2ng",
    "kong4"
  ],
  [
    "\u62c5",
    "to undertake",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u64d4",
    "\u62c5 (to undertake)",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u6a21",
    "to imitate",
    "m\u00f3",
    "mo2"
  ],
  [
    "\u6d3e",
    "school of though",
    "p\u00e0i",
    "pai4"
  ],
  [
    "\u589e",
    "to increase; add",
    "z\u0113ng",
    "zeng1"
  ],
  [
    "\u54e5",
    "elder brother",
    "g\u0113",
    "ge1"
  ],
  [
    "\u4e3e",
    "to lift",
    "j\u01d4",
    "ju3"
  ],
  [
    "\u8209",
    "\u4e3e (to lift)",
    "j\u01d4",
    "ju3"
  ],
  [
    "\u8863",
    "clothes",
    "y\u012b",
    "yi1"
  ],
  [
    "\u7a7f",
    "to wear",
    "chu\u0101n",
    "chuan1"
  ],
  [
    "\u4f9d",
    "to depend on",
    "y\u012b",
    "yi1"
  ],
  [
    "\u901f",
    "fast; rapid",
    "s\u00f9",
    "su4"
  ],
  [
    "\u503c",
    "value",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u6001",
    "attitude",
    "t\u00e0i",
    "tai4"
  ],
  [
    "\u614b",
    "\u6001 (attitude)",
    "t\u00e0i",
    "tai4"
  ],
  [
    "\u8138",
    "face",
    "li\u01cen",
    "lian3"
  ],
  [
    "\u81c9",
    "\u8138 (face)",
    "li\u01cen",
    "lian3"
  ],
  [
    "\u6d4e",
    "aid; ferry; frugal",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u6fdf",
    "\u6d4e (aid; ferry; frugal)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u602a",
    "odd; monster",
    "gu\u00e0i",
    "guai4"
  ],
  [
    "\u53f2",
    "history",
    "sh\u01d0",
    "shi3"
  ],
  [
    "\u5f1f",
    "young brother",
    "d\u00ec",
    "di4"
  ],
  [
    "\u8111",
    "brain; mind",
    "n\u01ceo",
    "nao3"
  ],
  [
    "\u8166",
    "\u8111 (brain; mind)",
    "n\u01ceo",
    "nao3"
  ],
  [
    "\u5e1d",
    "emperor",
    "d\u00ec",
    "di4"
  ],
  [
    "\u523b",
    "quarter (hour); to cut",
    "k\u00e8",
    "ke4"
  ],
  [
    "\u7a81",
    "to dash; sudden",
    "t\u016b",
    "tu1"
  ],
  [
    "\u67aa",
    "gun",
    "qi\u0101ng",
    "qiang1"
  ],
  [
    "\u69cd",
    "\u67aa (gun)",
    "qi\u0101ng",
    "qiang1"
  ],
  [
    "\u5217",
    "to arrange; list",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u515a",
    "party; association",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u9ee8",
    "\u515a (party; association)",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u5343",
    "thousand",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u7c73",
    "rice",
    "m\u01d0",
    "mi3"
  ],
  [
    "\u8d23",
    "duty",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u8cac",
    "\u8d23 (duty)",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u5012",
    "to invert; actually",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u72af",
    "to violate; to offend",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u7b54",
    "to reply; answer",
    "d\u00e1",
    "da2"
  ],
  [
    "\u54cd",
    "sound; noise",
    "xi\u01ceng",
    "xiang3"
  ],
  [
    "\u97ff",
    "\u54cd (sound; noise)",
    "xi\u01ceng",
    "xiang3"
  ],
  [
    "\u98df",
    "food",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u54c8",
    "laughter",
    "h\u0101",
    "ha1"
  ],
  [
    "\u7834",
    "broken; to destroy",
    "p\u00f2",
    "po4"
  ],
  [
    "\u53cc",
    "pair; double",
    "shu\u0101ng",
    "shuang1"
  ],
  [
    "\u96d9",
    "\u53cc (pair; double)",
    "shu\u0101ng",
    "shuang1"
  ],
  [
    "\u52bf",
    "power; potential",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u52e2",
    "\u52bf (power; potential)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u59d0",
    "older sister",
    "ji\u011b",
    "jie3"
  ],
  [
    "\u5047",
    "fake; to borrow",
    "ji\u01ce",
    "jia3"
  ],
  [
    "\u822c",
    "sort; kind",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u7b80",
    "simple",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u7c21",
    "\u7b80 (simple)",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u5883",
    "border; place",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u6392",
    "a row; to arrange",
    "p\u00e1i",
    "pai2"
  ],
  [
    "\u7f6e",
    "to install",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u6e7e",
    "bay; gulf",
    "w\u0101n",
    "wan1"
  ],
  [
    "\u7063",
    "\u6e7e (bay; gulf)",
    "w\u0101n",
    "wan1"
  ],
  [
    "\u5a5a",
    "to marry",
    "h\u016bn",
    "hun1"
  ],
  [
    "\u751a",
    "variant of \u4ec0",
    "sh\u00e9n",
    "shen2"
  ],
  [
    "\u59d4",
    "to entrust",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u5c81",
    "years (of age)",
    "su\u00ec",
    "sui4"
  ],
  [
    "\u6b72",
    "\u5c81 (years (of age))",
    "su\u00ec",
    "sui4"
  ],
  [
    "\u4eae",
    "bright; clear",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u67d0",
    "some; a certain",
    "m\u01d2u",
    "mou3"
  ],
  [
    "\u5fd7",
    "sign; ambition",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u72ec",
    "alone",
    "d\u00fa",
    "du2"
  ],
  [
    "\u7368",
    "\u72ec (alone)",
    "d\u00fa",
    "du2"
  ],
  [
    "\u5170",
    "orchid",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u862d",
    "\u5170 (orchid)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u68c0",
    "to check",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u6aa2",
    "\u68c0 (to check)",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u4f5b",
    "Buddha",
    "f\u00f3",
    "fo2"
  ],
  [
    "\u591c",
    "night",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u9002",
    "to fit; suitable",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u9069",
    "\u9002 (to fit; suitable)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u6c38",
    "forever",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u4e25",
    "strict; tight",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u56b4",
    "\u4e25 (strict; tight)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u7f6a",
    "guilt; crime",
    "zu\u00ec",
    "zui4"
  ],
  [
    "\u9f99",
    "dragon",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u9f8d",
    "\u9f99 (dragon)",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u7387",
    "to lead; frank",
    "shu\u00e0i",
    "shuai4"
  ],
  [
    "\u6258",
    "support; to entrust",
    "tu\u014d",
    "tuo1"
  ],
  [
    "\u8a17",
    "\u6258 (support; to entrust)",
    "tu\u014d",
    "tuo1"
  ],
  [
    "\u7ec6",
    "thin; fine",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u7d30",
    "\u7ec6 (thin; fine)",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u5fd9",
    "busy",
    "m\u00e1ng",
    "mang2"
  ],
  [
    "\u9752",
    "green; blue; young",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u73af",
    "ring; to circle",
    "hu\u00e1n",
    "huan2"
  ],
  [
    "\u74b0",
    "\u73af (ring; to circle)",
    "hu\u00e1n",
    "huan2"
  ],
  [
    "\u538b",
    "to push down",
    "y\u0101",
    "ya1"
  ],
  [
    "\u58d3",
    "\u538b (to push down)",
    "y\u0101",
    "ya1"
  ],
  [
    "\u836f",
    "medicine",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u85e5",
    "\u836f (medicine)",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u6e90",
    "source; origin",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u4fee",
    "to repair",
    "xi\u016b",
    "xiu1"
  ],
  [
    "\u5fae",
    "tiny",
    "w\u0113i",
    "wei1"
  ],
  [
    "\u83b7",
    "to capture; to reap",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u7372",
    "\u83b7 (to capture; to reap)",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u804c",
    "office; duty",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u8077",
    "\u804c (office; duty)",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u5dee",
    "inferior; bad",
    "ch\u00e0",
    "cha4"
  ],
  [
    "\u5c5e",
    "category; affiliation",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u5c6c",
    "\u5c5e (category; affiliation)",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u9632",
    "to protect",
    "f\u00e1ng",
    "fang2"
  ],
  [
    "\u9650",
    "limit",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u9759",
    "still; calm",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u975c",
    "\u9759 (still; calm)",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u949f",
    "clock; o'clock",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u937e",
    "\u949f (clock; o'clock)",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u5662",
    "moan sound",
    "\u014d",
    "o1"
  ],
  [
    "\u674e",
    "plum",
    "l\u01d0",
    "li3"
  ],
  [
    "\u574f",
    "bad; broken",
    "hu\u00e0i",
    "huai4"
  ],
  [
    "\u58de",
    "\u574f (bad; broken)",
    "hu\u00e0i",
    "huai4"
  ],
  [
    "\u5a01",
    "power",
    "w\u0113i",
    "wei1"
  ],
  [
    "\u5175",
    "soldiers",
    "b\u012bng",
    "bing1"
  ],
  [
    "\u6237",
    "door; household",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u6236",
    "\u6237 (door; household)",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u5370",
    "to print; image",
    "y\u00ecn",
    "yin4"
  ],
  [
    "\u793c",
    "gift; ceremony",
    "l\u01d0",
    "li3"
  ],
  [
    "\u79ae",
    "\u793c (gift; ceremony)",
    "l\u01d0",
    "li3"
  ],
  [
    "\u8dd1",
    "to run",
    "p\u01ceo",
    "pao3"
  ],
  [
    "\u86cb",
    "egg",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u53e4",
    "ancient",
    "g\u01d4",
    "gu3"
  ],
  [
    "\u60ca",
    "frightened",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u9a5a",
    "\u60ca (frightened)",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u666e",
    "general; popular",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u7ae0",
    "chapter",
    "zh\u0101ng",
    "zhang1"
  ],
  [
    "\u72b6",
    "suit; strong",
    "zhu\u00e0ng",
    "zhuang4"
  ],
  [
    "\u72c0",
    "\u72b6 (suit; strong)",
    "zhu\u00e0ng",
    "zhuang4"
  ],
  [
    "\u914d",
    "to join; to match",
    "p\u00e8i",
    "pei4"
  ],
  [
    "\u641e",
    "to make; to set up",
    "g\u01ceo",
    "gao3"
  ],
  [
    "\u798f",
    "luck; fortune",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u559d",
    "to drink",
    "h\u0113",
    "he1"
  ],
  [
    "\u6b66",
    "martial",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u6784",
    "to assign; faciton",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u69cb",
    "\u6784 (to assign; faciton)",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u6362",
    "to change",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u63db",
    "\u6362 (to change)",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u89d2",
    "angle; 0.1\u5143",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u627f",
    "to bear; to carry",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u4f8b",
    "example; case",
    "l\u00ec",
    "li4"
  ],
  [
    "\u5473",
    "taste; smell",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u56f4",
    "to encircle",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u570d",
    "\u56f4 (to encircle)",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u9884",
    "to advance; prepare",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u9810",
    "\u9884 (to advance; prepare)",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u91c7",
    "to select; looks",
    "c\u01cei",
    "cai3"
  ],
  [
    "\u63a1",
    "\u91c7 (to select; looks)",
    "c\u01cei",
    "cai3"
  ],
  [
    "\u65cf",
    "race; nationality",
    "z\u00fa",
    "zu2"
  ],
  [
    "\u7075",
    "quick; effective",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u9748",
    "\u7075 (quick; effective)",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u7fa4",
    "group",
    "q\u00fan",
    "qun2"
  ],
  [
    "\u7fa3",
    "\u7fa4 (group)",
    "q\u00fan",
    "qun2"
  ],
  [
    "\u96f7",
    "thunder",
    "l\u00e9i",
    "lei2"
  ],
  [
    "\u5f39",
    "bullet",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u5f48",
    "\u5f39 (bullet)",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u6b49",
    "to apologize",
    "qi\u00e0n",
    "qian4"
  ],
  [
    "\u571f",
    "earth",
    "t\u01d4",
    "tu3"
  ],
  [
    "\u7968",
    "ticket",
    "pi\u00e0o",
    "piao4"
  ],
  [
    "\u6076",
    "evil; ugly",
    "\u00e8",
    "e4"
  ],
  [
    "\u60e1",
    "\u6076 (evil; ugly)",
    "\u00e8",
    "e4"
  ],
  [
    "\u7eaa",
    "order; to discipline",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u7d00",
    "\u7eaa (order; to discipline)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u51b7",
    "cold",
    "l\u011bng",
    "leng3"
  ],
  [
    "\u5e97",
    "inn; shop",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u517b",
    "to raise; to bring up",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u990a",
    "\u517b (to raise; to bring up)",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u80b2",
    "to bring up",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u7a0e",
    "taxes; duties",
    "shu\u00ec",
    "shui4"
  ],
  [
    "\u7a05",
    "\u7a0e (taxes; duties)",
    "shu\u00ec",
    "shui4"
  ],
  [
    "\u6b4c",
    "song",
    "g\u0113",
    "ge1"
  ],
  [
    "\u76ae",
    "leather; skin",
    "p\u00ed",
    "pi2"
  ],
  [
    "\u82e6",
    "bitter; pain",
    "k\u01d4",
    "ku3"
  ],
  [
    "\u8df3",
    "to jump",
    "ti\u00e0o",
    "tiao4"
  ],
  [
    "\u6fc0",
    "to excite; fierce",
    "j\u012b",
    "ji1"
  ],
  [
    "\u6309",
    "to press; to restrain",
    "\u00e0n",
    "an4"
  ],
  [
    "\u4e3d",
    "beautiful",
    "l\u00ec",
    "li4"
  ],
  [
    "\u9e97",
    "\u4e3d (beautiful)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u8d1d",
    "shell",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u8c9d",
    "\u8d1d (shell)",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u8d1f",
    "to bear",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u8ca0",
    "\u8d1f (to bear)",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u5e78",
    "fortunate",
    "x\u00ecng",
    "xing4"
  ],
  [
    "\u753b",
    "to draw; picture",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u756b",
    "\u753b (to draw; picture)",
    "hu\u00e0",
    "hua4"
  ],
  [
    "\u76d1",
    "to supervise",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u76e3",
    "\u76d1 (to supervise)",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u4ecd",
    "still; yet",
    "r\u00e9ng",
    "reng2"
  ],
  [
    "\u514d",
    "to exempt; to avoid",
    "mi\u01cen",
    "mian3"
  ],
  [
    "\u4f18",
    "excellent",
    "y\u014du",
    "you1"
  ],
  [
    "\u512a",
    "\u4f18 (excellent)",
    "y\u014du",
    "you1"
  ],
  [
    "\u620f",
    "drama; trick",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u6232",
    "\u620f (drama; trick)",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u6ce2",
    "wave",
    "b\u014d",
    "bo1"
  ],
  [
    "\u519c",
    "agriculture",
    "n\u00f3ng",
    "nong2"
  ],
  [
    "\u8fb2",
    "\u519c (agriculture)",
    "n\u00f3ng",
    "nong2"
  ],
  [
    "\u5438",
    "to breathe; inhale",
    "x\u012b",
    "xi1"
  ],
  [
    "\u51b2",
    "to clash; to rinse",
    "ch\u014dng",
    "chong1"
  ],
  [
    "\u885d",
    "\u51b2 (to clash; to rinse)",
    "ch\u014dng",
    "chong1"
  ],
  [
    "\u811a",
    "foot",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u8173",
    "\u811a (foot)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u5584",
    "virtuous; kind",
    "sh\u00e0n",
    "shan4"
  ],
  [
    "\u5c04",
    "to shoot",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u80a1",
    "portion; thigh",
    "g\u01d4",
    "gu3"
  ],
  [
    "\u7eb3",
    "to receive; to bring in",
    "n\u00e0",
    "na4"
  ],
  [
    "\u7d0d",
    "\u7eb3 (to receive; to bring in)",
    "n\u00e0",
    "na4"
  ],
  [
    "\u7adf",
    "unexpectedly",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u9000",
    "to retreat; to decline",
    "tu\u00ec",
    "tui4"
  ],
  [
    "\u5c45",
    "to reside; residence",
    "j\u016b",
    "ju1"
  ],
  [
    "\u8bfb",
    "to read",
    "d\u00fa",
    "du2"
  ],
  [
    "\u8b80",
    "\u8bfb (to read)",
    "d\u00fa",
    "du2"
  ],
  [
    "\u9769",
    "leather; remove",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u7597",
    "to be healed",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u7642",
    "\u7597 (to be healed)",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u9ec4",
    "yellow",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u9ec3",
    "\u9ec4 (yellow)",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u65bd",
    "to grant",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u5f55",
    "diary; record",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u9304",
    "\u5f55 (diary; record)",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u534f",
    "to cooperate",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u5354",
    "\u534f (to cooperate)",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u6597",
    "to fight",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u9b25",
    "\u6597 (to fight)",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u7591",
    "to doubt",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u4ed8",
    "to pay; hand over",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u677f",
    "board; plank",
    "b\u01cen",
    "ban3"
  ],
  [
    "\u5757",
    "colloq. money; lump",
    "ku\u00e0i",
    "kuai4"
  ],
  [
    "\u584a",
    "\u5757 (colloq. money; lump)",
    "ku\u00e0i",
    "kuai4"
  ],
  [
    "\u75db",
    "ache; pain",
    "t\u00f2ng",
    "tong4"
  ],
  [
    "\u6293",
    "to grab",
    "zhu\u0101",
    "zhua1"
  ],
  [
    "\u6162",
    "slow",
    "m\u00e0n",
    "man4"
  ],
  [
    "\u6cb9",
    "oil",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u7761",
    "to sleep",
    "shu\u00ec",
    "shui4"
  ],
  [
    "\u5c42",
    "layer; floor (of building)",
    "c\u00e9ng",
    "ceng2"
  ],
  [
    "\u5c64",
    "\u5c42 (layer; floor (of building))",
    "c\u00e9ng",
    "ceng2"
  ],
  [
    "\u6069",
    "grace",
    "\u0113n",
    "en1"
  ],
  [
    "\u7d20",
    "raw silk; nature",
    "s\u00f9",
    "su4"
  ],
  [
    "\u73ed",
    "team; class",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u62e9",
    "to select",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u64c7",
    "\u62e9 (to select)",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u8ba8",
    "to invite; to discuss",
    "t\u01ceo",
    "tao3"
  ],
  [
    "\u8a0e",
    "\u8ba8 (to invite; to discuss)",
    "t\u01ceo",
    "tao3"
  ],
  [
    "\u6562",
    "to dare",
    "g\u01cen",
    "gan3"
  ],
  [
    "\u827a",
    "skill; art",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u85dd",
    "\u827a (skill; art)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u63a2",
    "to explore",
    "t\u00e0n",
    "tan4"
  ],
  [
    "\u5723",
    "holy",
    "sh\u00e8ng",
    "sheng4"
  ],
  [
    "\u8056",
    "\u5723 (holy)",
    "sh\u00e8ng",
    "sheng4"
  ],
  [
    "\u6d4b",
    "to measure",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u6e2c",
    "\u6d4b (to measure)",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u80cc",
    "back of object",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u7ec3",
    "to practice",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u7df4",
    "\u7ec3 (to practice)",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u6025",
    "urgent; worried",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u5ead",
    "main hall; courtyard",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u68d2",
    "wonderful; stick",
    "b\u00e0ng",
    "bang4"
  ],
  [
    "\u82e5",
    "seem like",
    "ru\u00f2",
    "ruo4"
  ],
  [
    "\u62cd",
    "to clap; to take (photo)",
    "p\u0101i",
    "pai1"
  ],
  [
    "\u6bdb",
    "hair; coarse",
    "m\u00e1o",
    "mao2"
  ],
  [
    "\u9760",
    "to lean on",
    "k\u00e0o",
    "kao4"
  ],
  [
    "\u4f26",
    "relationship",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u502b",
    "\u4f26 (relationship)",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u677e",
    "loose; to relax",
    "s\u014dng",
    "song1"
  ],
  [
    "\u9646",
    "shore; land",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u9678",
    "\u9646 (shore; land)",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u5144",
    "elder brother",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u5f04",
    "to do",
    "n\u00f2ng",
    "nong4"
  ],
  [
    "\u5145",
    "to fill; to satisfy",
    "ch\u014dng",
    "chong1"
  ],
  [
    "\u68a6",
    "dream",
    "m\u00e8ng",
    "meng4"
  ],
  [
    "\u5922",
    "\u68a6 (dream)",
    "m\u00e8ng",
    "meng4"
  ],
  [
    "\u8239",
    "boat",
    "chu\u00e1n",
    "chuan2"
  ],
  [
    "\u8ffd",
    "to chase ",
    "zhu\u012b",
    "zhui1"
  ],
  [
    "\u7ec7",
    "to weave",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u7e54",
    "\u7ec7 (to weave)",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u55e8",
    "an exclamation",
    "h\u0101i",
    "hai1"
  ],
  [
    "\u536b",
    "to guard",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u885b",
    "\u536b (to guard)",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u6000",
    "bosom; heart",
    "hu\u00e1i",
    "huai2"
  ],
  [
    "\u61f7",
    "\u6000 (bosom; heart)",
    "hu\u00e1i",
    "huai2"
  ],
  [
    "\u8bc4",
    "to comment; criticize",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u8a55",
    "\u8bc4 (to comment; criticize)",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u6bd2",
    "poison",
    "d\u00fa",
    "du2"
  ],
  [
    "\u9b3c",
    "ghost",
    "gu\u01d0",
    "gui3"
  ],
  [
    "\u5356",
    "to sell",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u8ce3",
    "\u5356 (to sell)",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u8d22",
    "money; wealth",
    "c\u00e1i",
    "cai2"
  ],
  [
    "\u8ca1",
    "\u8d22 (money; wealth)",
    "c\u00e1i",
    "cai2"
  ],
  [
    "\u9633",
    "male; sun",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u967d",
    "\u9633 (male; sun)",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u91ca",
    "to explain",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u91cb",
    "\u91ca (to explain)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u5347",
    "to ascend; promote",
    "sh\u0113ng",
    "sheng1"
  ],
  [
    "\u6df7",
    "to mix",
    "h\u00f9n",
    "hun4"
  ],
  [
    "\u9648",
    "arrange; exhibit; narrate; tell",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u9673",
    "\u9648 (arrange; exhibit; narrate; tell)",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u9999",
    "fragrant",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u6e29",
    "warm",
    "w\u0113n",
    "wen1"
  ],
  [
    "\u6eab",
    "\u6e29 (warm)",
    "w\u0113n",
    "wen1"
  ],
  [
    "\u5f02",
    "different; other",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7570",
    "\u5f02 (different; other)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u695a",
    "distinct; clear",
    "ch\u01d4",
    "chu3"
  ],
  [
    "\u987e",
    "to look after",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u9867",
    "\u987e (to look after)",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u547c",
    "to call",
    "h\u016b",
    "hu1"
  ],
  [
    "\u671d",
    "dynasty",
    "ch\u00e1o",
    "chao2"
  ],
  [
    "\u5634",
    "mouth",
    "zu\u01d0",
    "zui3"
  ],
  [
    "\u535a",
    "rich; extensive",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u5348",
    "noon",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u4e71",
    "messy; disorder",
    "lu\u00e0n",
    "luan4"
  ],
  [
    "\u4e82",
    "\u4e71 (messy; disorder)",
    "lu\u00e0n",
    "luan4"
  ],
  [
    "\u62dc",
    "to pay respect",
    "b\u00e0i",
    "bai4"
  ],
  [
    "\u666f",
    "scenery",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u697c",
    "building; floor",
    "l\u00f3u",
    "lou2"
  ],
  [
    "\u6a13",
    "\u697c (building; floor)",
    "l\u00f3u",
    "lou2"
  ],
  [
    "\u53e5",
    "sentence",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u5ba1",
    "to examine",
    "sh\u011bn",
    "shen3"
  ],
  [
    "\u5be9",
    "\u5ba1 (to examine)",
    "sh\u011bn",
    "shen3"
  ],
  [
    "\u5fd8",
    "to forget",
    "w\u00e0ng",
    "wang4"
  ],
  [
    "\u5443",
    "belch; hiccup",
    "\u00e8",
    "e4"
  ],
  [
    "\u5dde",
    "a state",
    "zh\u014du",
    "zhou1"
  ],
  [
    "\u79fb",
    "to move; to alter",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u5224",
    "to judge",
    "p\u00e0n",
    "pan4"
  ],
  [
    "\u5531",
    "to sing",
    "ch\u00e0ng",
    "chang4"
  ],
  [
    "\u76ca",
    "benefit; increase",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u5bcc",
    "rich; abundant",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u95fb",
    "to hear; news",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u805e",
    "\u95fb (to hear; news)",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u653b",
    "to attack",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u521d",
    "at first",
    "ch\u016b",
    "chu1"
  ],
  [
    "\u6b3e",
    "section",
    "ku\u01cen",
    "kuan3"
  ],
  [
    "\u996d",
    "food; meal",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u98ef",
    "\u996d (food; meal)",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u4ecb",
    "to introduce",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u5566",
    "exclamation p.",
    "la",
    "la"
  ],
  [
    "\u6267",
    "to execute; to grasp",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u57f7",
    "\u6267 (to execute; to grasp)",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u8da3",
    "interesting",
    "q\u00f9",
    "qu4"
  ],
  [
    "\u52b3",
    "to toil",
    "l\u00e1o",
    "lao2"
  ],
  [
    "\u52de",
    "\u52b3 (to toil)",
    "l\u00e1o",
    "lao2"
  ],
  [
    "\u82cf",
    "to revive",
    "s\u016b",
    "su1"
  ],
  [
    "\u8607",
    "\u82cf (to revive)",
    "s\u016b",
    "su1"
  ],
  [
    "\u987f",
    "to pause; mw. for meals ",
    "d\u00f9n",
    "dun4"
  ],
  [
    "\u9813",
    "\u987f (to pause; mw. for meals )",
    "d\u00f9n",
    "dun4"
  ],
  [
    "\u65c5",
    "journey",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u7d22",
    "to search; to ask",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u575a",
    "strong",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u5805",
    "\u575a (strong)",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u9ebb",
    "hemp; rough",
    "m\u00e1",
    "ma2"
  ],
  [
    "\u675f",
    "to bind; bundle",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u8d27",
    "goods",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u8ca8",
    "\u8d27 (goods)",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u6050",
    "afraid",
    "k\u01d2ng",
    "kong3"
  ],
  [
    "\u561b",
    "obvious p.; pause p.",
    "ma",
    "ma"
  ],
  [
    "\u8303",
    "pattern; model",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u7bc4",
    "\u8303 (pattern; model)",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u9192",
    "to wake up",
    "x\u01d0ng",
    "xing3"
  ],
  [
    "\u654c",
    "enemy; to resist",
    "d\u00ed",
    "di2"
  ],
  [
    "\u6575",
    "\u654c (enemy; to resist)",
    "d\u00ed",
    "di2"
  ],
  [
    "\u4f0a",
    "he; she",
    "y\u012b",
    "yi1"
  ],
  [
    "\u9b54",
    "devil; magic",
    "m\u00f3",
    "mo2"
  ],
  [
    "\u6279",
    "to criticize",
    "p\u012b",
    "pi1"
  ],
  [
    "\u9047",
    "to meet; to encounter",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u4e91",
    "(classical) to say, cloud",
    "y\u00fan",
    "yun2"
  ],
  [
    "\u96f2",
    "\u4e91 ((classical) to say, cloud)",
    "y\u00fan",
    "yun2"
  ],
  [
    "\u767b",
    "to ascend",
    "d\u0113ng",
    "deng1"
  ],
  [
    "\u72d7",
    "dog",
    "g\u01d2u",
    "gou3"
  ],
  [
    "\u9500",
    "to melt",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u92b7",
    "\u9500 (to melt)",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u5371",
    "danger",
    "w\u0113i",
    "wei1"
  ],
  [
    "\u6770",
    "distinguished",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u5091",
    "\u6770 (distinguished)",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u964d",
    "to drop; descend",
    "ji\u00e0ng",
    "jiang4"
  ],
  [
    "\u5956",
    "prize",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u734e",
    "\u5956 (prize)",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u56ed",
    "park",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u5712",
    "\u56ed (park)",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u8d76",
    "to hurry",
    "g\u01cen",
    "gan3"
  ],
  [
    "\u8d95",
    "\u8d76 (to hurry)",
    "g\u01cen",
    "gan3"
  ],
  [
    "\u9732",
    "dew; to reveal",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u6811",
    "tree",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u6a39",
    "\u6811 (tree)",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u9986",
    "building; shop",
    "gu\u01cen",
    "guan3"
  ],
  [
    "\u9928",
    "\u9986 (building; shop)",
    "gu\u01cen",
    "guan3"
  ],
  [
    "\u7701",
    "to save; province",
    "sh\u011bng",
    "sheng3"
  ],
  [
    "\u8bfe",
    "subject; lesson",
    "k\u00e8",
    "ke4"
  ],
  [
    "\u8ab2",
    "\u8bfe (subject; lesson)",
    "k\u00e8",
    "ke4"
  ],
  [
    "\u8fce",
    "to welcome",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u67b6",
    "to support; framework",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u70e6",
    "to trouble",
    "f\u00e1n",
    "fan2"
  ],
  [
    "\u7169",
    "\u70e6 (to trouble)",
    "f\u00e1n",
    "fan2"
  ],
  [
    "\u6cb3",
    "river",
    "h\u00e9",
    "he2"
  ],
  [
    "\u5267",
    "drama; play",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u5287",
    "\u5267 (drama; play)",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u900f",
    "to penetrate",
    "t\u00f2u",
    "tou4"
  ],
  [
    "\u6728",
    "tree; wood",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u5ea7",
    "seat",
    "zu\u00f2",
    "zuo4"
  ],
  [
    "\u6742",
    "mixed",
    "z\u00e1",
    "za2"
  ],
  [
    "\u96dc",
    "\u6742 (mixed)",
    "z\u00e1",
    "za2"
  ],
  [
    "\u85cf",
    "to conceal; to store",
    "c\u00e1ng",
    "cang2"
  ],
  [
    "\u5440",
    "surprise p.",
    "y\u0101",
    "ya1"
  ],
  [
    "\u94f6",
    "silver",
    "y\u00edn",
    "yin2"
  ],
  [
    "\u9280",
    "\u94f6 (silver)",
    "y\u00edn",
    "yin2"
  ],
  [
    "\u987a",
    "to obey; along",
    "sh\u00f9n",
    "shun4"
  ],
  [
    "\u9806",
    "\u987a (to obey; along)",
    "sh\u00f9n",
    "shun4"
  ],
  [
    "\u9910",
    "meal",
    "c\u0101n",
    "can1"
  ],
  [
    "\u6297",
    "to resist; anti-",
    "k\u00e0ng",
    "kang4"
  ],
  [
    "\u5065",
    "healthy",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u8089",
    "flesh; meat",
    "r\u00f2u",
    "rou4"
  ],
  [
    "\u80dc",
    "victory; better",
    "sh\u00e8ng",
    "sheng4"
  ],
  [
    "\u52dd",
    "\u80dc (victory; better)",
    "sh\u00e8ng",
    "sheng4"
  ],
  [
    "\u5409",
    "lucky",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u6697",
    "dark; gloomy",
    "\u00e0n",
    "an4"
  ],
  [
    "\u4f11",
    "to rest",
    "xi\u016b",
    "xiu1"
  ],
  [
    "\u5b88",
    "to guard",
    "sh\u01d2u",
    "shou3"
  ],
  [
    "\u6750",
    "material",
    "c\u00e1i",
    "cai2"
  ],
  [
    "\u79d8",
    "secret",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u7955",
    "\u79d8 (secret)",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u75af",
    "insane",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u760b",
    "\u75af (insane)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u6751",
    "village",
    "c\u016bn",
    "cun1"
  ],
  [
    "\u5eb7",
    "healthy",
    "k\u0101ng",
    "kang1"
  ],
  [
    "\u706f",
    "light; lamp",
    "d\u0113ng",
    "deng1"
  ],
  [
    "\u71c8",
    "\u706f (light; lamp)",
    "d\u0113ng",
    "deng1"
  ],
  [
    "\u4eab",
    "to enjoy",
    "xi\u01ceng",
    "xiang3"
  ],
  [
    "\u989d",
    "forehead",
    "\u00e9",
    "e2"
  ],
  [
    "\u984d",
    "\u989d (forehead)",
    "\u00e9",
    "e2"
  ],
  [
    "\u552f",
    "only",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u667a",
    "wisdom",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u4e34",
    "to face",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u81e8",
    "\u4e34 (to face)",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u65e2",
    "already; both\u2026",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u8bef",
    "mistake",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u8aa4",
    "\u8bef (mistake)",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u5e8a",
    "bed",
    "chu\u00e1ng",
    "chuang2"
  ],
  [
    "\u7240",
    "\u5e8a (bed)",
    "chu\u00e1ng",
    "chuang2"
  ],
  [
    "\u4e1d",
    "silk",
    "s\u012b",
    "si1"
  ],
  [
    "\u7d72",
    "\u4e1d (silk)",
    "s\u012b",
    "si1"
  ],
  [
    "\u51b0",
    "ice",
    "b\u012bng",
    "bing1"
  ],
  [
    "\u7b56",
    "policy",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u8d25",
    "to defeat",
    "b\u00e0i",
    "bai4"
  ],
  [
    "\u6557",
    "\u8d25 (to defeat)",
    "b\u00e0i",
    "bai4"
  ],
  [
    "\u8131",
    "to take off",
    "tu\u014d",
    "tuo1"
  ],
  [
    "\u812b",
    "\u8131 (to take off)",
    "tu\u014d",
    "tuo1"
  ],
  [
    "\u526f",
    "secondary; vice-",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u6d32",
    "continent; island",
    "zh\u014du",
    "zhou1"
  ],
  [
    "\u4f59",
    "surplus",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u9918",
    "\u4f59 (surplus)/\u9980 (remainder)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u62e5",
    "to embrace",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u64c1",
    "\u62e5 (to embrace)",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u7248",
    "edition",
    "b\u01cen",
    "ban3"
  ],
  [
    "\u77ed",
    "short; brief",
    "du\u01cen",
    "duan3"
  ],
  [
    "\u8f93",
    "to lose",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u8f38",
    "\u8f93 (to lose)",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u5ba3",
    "to announce",
    "xu\u0101n",
    "xuan1"
  ],
  [
    "\u9c7c",
    "fish",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u9b5a",
    "\u9c7c (fish)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u6838",
    "seed",
    "h\u00e9",
    "he2"
  ],
  [
    "\u7b14",
    "pen; brush",
    "b\u01d0",
    "bi3"
  ],
  [
    "\u7b46",
    "\u7b14 (pen; brush)",
    "b\u01d0",
    "bi3"
  ],
  [
    "\u7f16",
    "to organize; to compile",
    "bi\u0101n",
    "bian1"
  ],
  [
    "\u7de8",
    "\u7f16 (to organize; to compile)",
    "bi\u0101n",
    "bian1"
  ],
  [
    "\u5c4b",
    "house; room",
    "w\u016b",
    "wu1"
  ],
  [
    "\u6c49",
    "Han people",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u6f22",
    "\u6c49 (Han people)",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u6982",
    "approximate",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u5a18",
    "mother; young lady",
    "ni\u00e1ng",
    "niang2"
  ],
  [
    "\u4e92",
    "mutual",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u724c",
    "card; tablet",
    "p\u00e1i",
    "pai2"
  ],
  [
    "\u59b9",
    "younger sister",
    "m\u00e8i",
    "mei4"
  ],
  [
    "\u6325",
    "to wave; to disperse",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u63ee",
    "\u6325 (to wave; to disperse)",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u8857",
    "street",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u8ff7",
    "fan (of)",
    "m\u00ed",
    "mi2"
  ],
  [
    "\u62ec",
    "to enclose; to include",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u8ff0",
    "to tell",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u4eac",
    "capital; Beijing (abbrev.)",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u5957",
    "cover; mw. for sets",
    "t\u00e0o",
    "tao4"
  ],
  [
    "\u826f",
    "good; very much",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u8d35",
    "expensive",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u8cb4",
    "\u8d35 (expensive)",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u9003",
    "to escape",
    "t\u00e1o",
    "tao2"
  ],
  [
    "\u94c1",
    "iron",
    "ti\u011b",
    "tie3"
  ],
  [
    "\u9435",
    "\u94c1 (iron)",
    "ti\u011b",
    "tie3"
  ],
  [
    "\u5965",
    "obscure",
    "\u00e0o",
    "ao4"
  ],
  [
    "\u5967",
    "\u5965 (obscure)",
    "\u00e0o",
    "ao4"
  ],
  [
    "\u638c",
    "palm of hand",
    "zh\u01ceng",
    "zhang3"
  ],
  [
    "\u9635",
    "short time",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u9663",
    "\u9635 (short time)",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u8651",
    "to think over",
    "l\u01dc",
    "lu:4"
  ],
  [
    "\u616e",
    "\u8651 (to think over)",
    "l\u01dc",
    "lu:4"
  ],
  [
    "\u56f0",
    "sleepy; difficult",
    "k\u00f9n",
    "kun4"
  ],
  [
    "\u5382",
    "factory",
    "ch\u01ceng",
    "chang3"
  ],
  [
    "\u5ee0",
    "\u5382 (factory)",
    "ch\u01ceng",
    "chang3"
  ],
  [
    "\u5077",
    "to steal",
    "t\u014du",
    "tou1"
  ],
  [
    "\u5c01",
    "to grant; title",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u8865",
    "to make up for",
    "b\u01d4",
    "bu3"
  ],
  [
    "\u88dc",
    "\u8865 (to make up for)",
    "b\u01d4",
    "bu3"
  ],
  [
    "\u5802",
    "hall; mw. for furniture sets",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u51cf",
    "to lower",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u6e1b",
    "\u51cf (to lower)",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u80e1",
    "beard; mustache",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u6563",
    "to scatter",
    "s\u00e0n",
    "san4"
  ],
  [
    "\u70c8",
    "fierce; intense",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u72c2",
    "mad; wild",
    "ku\u00e1ng",
    "kuang2"
  ],
  [
    "\u7801",
    "number; code",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u78bc",
    "\u7801 (number; code)",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u79c1",
    "private",
    "s\u012b",
    "si1"
  ],
  [
    "\u7565",
    "summary; strategy",
    "l\u00fc\u00e8",
    "lu:e4"
  ],
  [
    "\u6628",
    "yesterday",
    "zu\u00f3",
    "zuo2"
  ],
  [
    "\u6c89",
    "to submerge; sink",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u62db",
    "to recruit",
    "zh\u0101o",
    "zhao1"
  ],
  [
    "\u9547",
    "suppress; small town",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u93ae",
    "\u9547 (suppress; small town)",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u8349",
    "grass",
    "c\u01ceo",
    "cao3"
  ],
  [
    "\u7533",
    "to extend; to state",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u5f81",
    "to recruit",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u5fb5",
    "\u5f81 (to recruit)",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u8bfa",
    "promise",
    "nu\u00f2",
    "nuo4"
  ],
  [
    "\u8afe",
    "\u8bfa (promise)",
    "nu\u00f2",
    "nuo4"
  ],
  [
    "\u52aa",
    "to exert",
    "n\u01d4",
    "nu3"
  ],
  [
    "\u9057",
    "to lose; to omit",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u907a",
    "\u9057 (to lose; to omit)",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u96c5",
    "elegant",
    "y\u01ce",
    "ya3"
  ],
  [
    "\u6b27",
    "Europe",
    "\u014du",
    "ou1"
  ],
  [
    "\u6b50",
    "\u6b27 (Europe)",
    "\u014du",
    "ou1"
  ],
  [
    "\u6c99",
    "sand",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u676f",
    "cup",
    "b\u0113i",
    "bei1"
  ],
  [
    "\u8fea",
    "to enlighten",
    "d\u00ed",
    "di2"
  ],
  [
    "\u57df",
    "field; region",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u65c1",
    "beside; side",
    "p\u00e1ng",
    "pang2"
  ],
  [
    "\u8336",
    "tea",
    "ch\u00e1",
    "cha2"
  ],
  [
    "\u5bfb",
    "to search",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u5c0b",
    "\u5bfb (to search)",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u59bb",
    "wife",
    "q\u012b",
    "qi1"
  ],
  [
    "\u4ea1",
    "to die",
    "w\u00e1ng",
    "wang2"
  ],
  [
    "\u4f2f",
    "eldest uncle",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u5f03",
    "to abandon",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u68c4",
    "\u5f03 (to abandon)",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u95ed",
    "to close; to shut",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u9589",
    "\u95ed (to close; to shut)",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u8bcd",
    "word",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u8a5e",
    "\u8bcd (word)",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u6d17",
    "to wash",
    "x\u01d0",
    "xi3"
  ],
  [
    "\u725b",
    "cow",
    "ni\u00fa",
    "niu2"
  ],
  [
    "\u5360",
    "to divine",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u4f54",
    "\u5360 (to divine)",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u96ea",
    "snow",
    "xu\u011b",
    "xue3"
  ],
  [
    "\u8bad",
    "to tell; to teach",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u8a13",
    "\u8bad (to tell; to teach)",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u5f69",
    "color; variety",
    "c\u01cei",
    "cai3"
  ],
  [
    "\u5c9b",
    "island",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u5cf6",
    "\u5c9b (island)",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u91ce",
    "field; wild",
    "y\u011b",
    "ye3"
  ],
  [
    "\u66b4",
    "violent; cruel",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u6f02",
    "to float",
    "pi\u0101o",
    "piao1"
  ],
  [
    "\u5e8f",
    "order; sequence",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u8428",
    "Bodhissatva",
    "s\u00e0",
    "sa4"
  ],
  [
    "\u85a9",
    "\u8428 (Bodhissatva)",
    "s\u00e0",
    "sa4"
  ],
  [
    "\u66f2",
    "song; tune",
    "q\u01d4",
    "qu3"
  ],
  [
    "\u9876",
    "apex; to replace",
    "d\u01d0ng",
    "ding3"
  ],
  [
    "\u9802",
    "\u9876 (apex; to replace)",
    "d\u01d0ng",
    "ding3"
  ],
  [
    "\u5de8",
    "huge",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u9644",
    "to be close to; to add",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u6c5f",
    "river",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u7eb8",
    "paper",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u7d19",
    "\u7eb8 (paper)",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u8d62",
    "to win",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u8d0f",
    "\u8d62 (to win)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u7f3a",
    "lack",
    "qu\u0113",
    "que1"
  ],
  [
    "\u75c7",
    "disease; illness",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u6beb",
    "fine hair",
    "h\u00e1o",
    "hao2"
  ],
  [
    "\u76d8",
    "plate",
    "p\u00e1n",
    "pan2"
  ],
  [
    "\u76e4",
    "\u76d8 (plate)",
    "p\u00e1n",
    "pan2"
  ],
  [
    "\u8d2d",
    "to purchase",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u8cfc",
    "\u8d2d (to purchase)",
    "g\u00f2u",
    "gou4"
  ],
  [
    "\u6d6a",
    "wave",
    "l\u00e0ng",
    "lang4"
  ],
  [
    "\u5200",
    "knife",
    "d\u0101o",
    "dao1"
  ],
  [
    "\u6bd5",
    "the whole of; to finish",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u7562",
    "\u6bd5 (the whole of; to finish)",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u8363",
    "glory; honor",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u69ae",
    "\u8363 (glory; honor)",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u70b8",
    "to fry in oil",
    "zh\u00e1",
    "zha2"
  ],
  [
    "\u79ef",
    "to amass; to accumulate",
    "j\u012b",
    "ji1"
  ],
  [
    "\u7a4d",
    "\u79ef (to amass; to accumulate)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u907f",
    "to flee; to avoid",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5de7",
    "opportunely; timely",
    "qi\u01ceo",
    "qiao3"
  ],
  [
    "\u775b",
    "eye; eyeball",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u68ee",
    "forest",
    "s\u0113n",
    "sen1"
  ],
  [
    "\u7edc",
    "net",
    "lu\u00f2",
    "luo4"
  ],
  [
    "\u7d61",
    "\u7edc (net)",
    "lu\u00f2",
    "luo4"
  ],
  [
    "\u719f",
    "cooked; ripe; familiar",
    "sh\u00fa",
    "shu2"
  ],
  [
    "\u7259",
    "tooth",
    "y\u00e1",
    "ya2"
  ],
  [
    "\u5c1a",
    "still; yet; to value",
    "sh\u00e0ng",
    "shang4"
  ],
  [
    "\u9aa8",
    "bone",
    "g\u01d4",
    "gu3"
  ],
  [
    "\u59c6",
    "matron",
    "m\u01d4",
    "mu3"
  ],
  [
    "\u7981",
    "to prohibit",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u9c81",
    "foolish, Shandong",
    "l\u01d4",
    "lu3"
  ],
  [
    "\u9b6f",
    "\u9c81 (foolish, Shandong)",
    "l\u01d4",
    "lu3"
  ],
  [
    "\u61c2",
    "to understand",
    "d\u01d2ng",
    "dong3"
  ],
  [
    "\u8f6e",
    "wheel; by turns",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u8f2a",
    "\u8f6e (wheel; by turns)",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u7687",
    "emperor",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u8033",
    "ear",
    "\u011br",
    "er3"
  ],
  [
    "\u96c4",
    "male; grand",
    "xi\u00f3ng",
    "xiong2"
  ],
  [
    "\u6388",
    "to teach",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u8c13",
    "to speak; meaning",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u8b02",
    "\u8c13 (to speak; meaning)",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u8bbf",
    "to call on; to inquire",
    "f\u01ceng",
    "fang3"
  ],
  [
    "\u8a2a",
    "\u8bbf (to call on; to inquire)",
    "f\u01ceng",
    "fang3"
  ],
  [
    "\u64cd",
    "to operate",
    "c\u0101o",
    "cao1"
  ],
  [
    "\u822a",
    "boat; to sail",
    "h\u00e1ng",
    "hang2"
  ],
  [
    "\u7b7e",
    "to sign; label",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u7c64",
    "\u7b7e (to sign; label)",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u501f",
    "to lend; to borrow",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u5b63",
    "season",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u5f52",
    "to return",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u6b78",
    "\u5f52 (to return)",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u65e7",
    "old",
    "ji\u00f9",
    "jiu4"
  ],
  [
    "\u820a",
    "\u65e7 (old)",
    "ji\u00f9",
    "jiu4"
  ],
  [
    "\u5976",
    "milk; breast",
    "n\u01cei",
    "nai3"
  ],
  [
    "\u79c0",
    "handsome; elegant",
    "xi\u00f9",
    "xiu4"
  ],
  [
    "\u5178",
    "dictionary; law",
    "di\u01cen",
    "dian3"
  ],
  [
    "\u8baf",
    "to question",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u8a0a",
    "\u8baf (to question)",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u7aef",
    "to hold; end points",
    "du\u0101n",
    "duan1"
  ],
  [
    "\u8c0b",
    "plan; scheme",
    "m\u00f3u",
    "mou2"
  ],
  [
    "\u8b00",
    "\u8c0b (plan; scheme)",
    "m\u00f3u",
    "mou2"
  ],
  [
    "\u4e61",
    "countryside",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u9109",
    "\u4e61 (countryside)",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u6469",
    "to rub",
    "m\u00f3",
    "mo2"
  ],
  [
    "\u635f",
    "to decrease; damage",
    "s\u01d4n",
    "sun3"
  ],
  [
    "\u640d",
    "\u635f (to decrease; damage)",
    "s\u01d4n",
    "sun3"
  ],
  [
    "\u66ff",
    "on behalf",
    "t\u00ec",
    "ti4"
  ],
  [
    "\u8f6f",
    "soft; flexible",
    "ru\u01cen",
    "ruan3"
  ],
  [
    "\u8edf",
    "\u8f6f (soft; flexible)",
    "ru\u01cen",
    "ruan3"
  ],
  [
    "\u7cdf",
    "dregs",
    "z\u0101o",
    "zao1"
  ],
  [
    "\u7a33",
    "steady; stable",
    "w\u011bn",
    "wen3"
  ],
  [
    "\u7a69",
    "\u7a33 (steady; stable)",
    "w\u011bn",
    "wen3"
  ],
  [
    "\u523a",
    "to stab; to pierce",
    "c\u00ec",
    "ci4"
  ],
  [
    "\u552e",
    "to sell",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u83ab",
    "do not",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u84dd",
    "blue",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u85cd",
    "\u84dd (blue)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u5e2d",
    "mat; banquet",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u6311",
    "to choose",
    "ti\u0101o",
    "tiao1"
  ],
  [
    "\u6447",
    "to shake ",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u6416",
    "\u6447 (to shake )",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u9690",
    "secret",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u96b1",
    "\u9690 (secret)",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u904d",
    "everywhere; all over",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u9ea6",
    "wheat; oats",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u9ea5",
    "\u9ea6 (wheat; oats)",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u5747",
    "equal; even; all; uniform",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u9488",
    "needle",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u91dd",
    "\u9488 (needle)",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u70df",
    "cigarette; to smoke",
    "y\u0101n",
    "yan1"
  ],
  [
    "\u7159",
    "\u70df (cigarette; to smoke)",
    "y\u0101n",
    "yan1"
  ],
  [
    "\u4f34",
    "partner",
    "b\u00e0n",
    "ban4"
  ],
  [
    "\u963b",
    "to obstruct",
    "z\u01d4",
    "zu3"
  ],
  [
    "\u76d6",
    "lid; cover",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u84cb",
    "\u76d6 (lid; cover)",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u7ffb",
    "to translate; to flip",
    "f\u0101n",
    "fan1"
  ],
  [
    "\u5987",
    "married woman",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u5a66",
    "\u5987 (married woman)",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u7ade",
    "to compete",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u7af6",
    "\u7ade (to compete)",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u745e",
    "auspicious",
    "ru\u00ec",
    "rui4"
  ],
  [
    "\u63e1",
    "to grasp",
    "w\u00f2",
    "wo4"
  ],
  [
    "\u6d0b",
    "ocean",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u6625",
    "Spring",
    "ch\u016bn",
    "chun1"
  ],
  [
    "\u5854",
    "pagoda; tower",
    "t\u01ce",
    "ta3"
  ],
  [
    "\u731c",
    "to guess",
    "c\u0101i",
    "cai1"
  ],
  [
    "\u7206",
    "to explode",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u72f1",
    "prison; lawsuit",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u7344",
    "\u72f1 (prison; lawsuit)",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u5c0a",
    "senior; to honor",
    "z\u016bn",
    "zun1"
  ],
  [
    "\u6234",
    "to put on",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u79cb",
    "autumn; fall; harvest time",
    "qi\u016b",
    "qiu1"
  ],
  [
    "\u786c",
    "hard",
    "y\u00ecng",
    "ying4"
  ],
  [
    "\u805a",
    "to assemble",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u8ff9",
    "traces",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u8de1",
    "\u8ff9 (traces)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u96e8",
    "rain",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u5f31",
    "weak",
    "ru\u00f2",
    "ruo4"
  ],
  [
    "\u5218",
    "(surname); to kill",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u5289",
    "\u5218 ((surname); to kill)",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u89e6",
    "to touch",
    "ch\u00f9",
    "chu4"
  ],
  [
    "\u89f8",
    "\u89e6 (to touch)",
    "ch\u00f9",
    "chu4"
  ],
  [
    "\u8f7d",
    "to carry",
    "z\u00e0i",
    "zai4"
  ],
  [
    "\u8f09",
    "\u8f7d (to carry)",
    "z\u00e0i",
    "zai4"
  ],
  [
    "\u9636",
    "rank or step; stairs",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u968e",
    "\u9636 (rank or step; stairs)",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u5fcd",
    "to endure",
    "r\u011bn",
    "ren3"
  ],
  [
    "\u6446",
    "to arrange",
    "b\u01cei",
    "bai3"
  ],
  [
    "\u64fa",
    "\u6446 (to arrange)",
    "b\u01cei",
    "bai3"
  ],
  [
    "\u78b0",
    "to bump",
    "p\u00e8ng",
    "peng4"
  ],
  [
    "\u64ad",
    "to scatter",
    "b\u014d",
    "bo1"
  ],
  [
    "\u62bd",
    "to draw out",
    "ch\u014du",
    "chou1"
  ],
  [
    "\u8ba2",
    "to agree",
    "d\u00ecng",
    "ding4"
  ],
  [
    "\u8a02",
    "\u8ba2 (to agree)",
    "d\u00ecng",
    "ding4"
  ],
  [
    "\u5446",
    "stupid",
    "d\u0101i",
    "dai1"
  ],
  [
    "\u9c9c",
    "fresh",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u9bae",
    "\u9c9c (fresh)",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u6bc1",
    "to destroy",
    "hu\u01d0",
    "hui3"
  ],
  [
    "\u6bc0",
    "\u6bc1 (to destroy)",
    "hu\u01d0",
    "hui3"
  ],
  [
    "\u9ed8",
    "silent",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u95ea",
    "to dodge; flash",
    "sh\u01cen",
    "shan3"
  ],
  [
    "\u9583",
    "\u95ea (to dodge; flash)",
    "sh\u01cen",
    "shan3"
  ],
  [
    "\u706d",
    "to extinguish",
    "mi\u00e8",
    "mie4"
  ],
  [
    "\u6ec5",
    "\u706d (to extinguish)",
    "mi\u00e8",
    "mie4"
  ],
  [
    "\u52d2",
    "to rein in; to compel",
    "l\u00e8",
    "le4"
  ],
  [
    "\u5c24",
    "especially",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u5582",
    "call attention",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u633a",
    "to stand upright",
    "t\u01d0ng",
    "ting3"
  ],
  [
    "\u5bab",
    "palace",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u5bae",
    "\u5bab (palace)",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u7237",
    "father; grandfather",
    "y\u00e9",
    "ye2"
  ],
  [
    "\u723a",
    "\u7237 (father; grandfather)",
    "y\u00e9",
    "ye2"
  ],
  [
    "\u6444",
    "to absorb",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u651d",
    "\u6444 (to absorb)",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u585e",
    "to stop up",
    "s\u0101i",
    "sai1"
  ],
  [
    "\u5e93",
    "warehouse",
    "k\u00f9",
    "ku4"
  ],
  [
    "\u5eab",
    "\u5e93 (warehouse)",
    "k\u00f9",
    "ku4"
  ],
  [
    "\u969c",
    "to block",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u51ef",
    "triumphant",
    "k\u01cei",
    "kai3"
  ],
  [
    "\u51f1",
    "\u51ef (triumphant)",
    "k\u01cei",
    "kai3"
  ],
  [
    "\u9634",
    "cloudy; female",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u9670",
    "\u9634 (cloudy; female)",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u8e2a",
    "footprints; traces",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u8e64",
    "\u8e2a (footprints; traces)",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u4f73",
    "beautiful",
    "jia",
    "jia"
  ],
  [
    "\u6298",
    "to break; to turn",
    "zh\u00e9",
    "zhe2"
  ],
  [
    "\u7ae5",
    "child",
    "t\u00f3ng",
    "tong2"
  ],
  [
    "\u70e7",
    "to cook; roast; bake",
    "sh\u0101o",
    "shao1"
  ],
  [
    "\u71d2",
    "\u70e7 (to cook; roast; bake)",
    "sh\u0101o",
    "shao1"
  ],
  [
    "\u795d",
    "to wish; to pray",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u4fe9",
    "two (coll.  \u4e24\uff09",
    "li\u01ce",
    "lia3"
  ],
  [
    "\u5006",
    "\u4fe9 (two (coll.  \u4e24\uff09)",
    "li\u01ce",
    "lia3"
  ],
  [
    "\u888b",
    "pocket",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u83dc",
    "dish; cuisine; vegetable",
    "c\u00e0i",
    "cai4"
  ],
  [
    "\u906d",
    "meet by chance",
    "z\u0101o",
    "zao1"
  ],
  [
    "\u9f13",
    "drum",
    "g\u01d4",
    "gu3"
  ],
  [
    "\u7eff",
    "green",
    "l\u01d6",
    "lu:1"
  ],
  [
    "\u7da0",
    "\u7eff (green)",
    "l\u01d6",
    "lu:1"
  ],
  [
    "\u52c7",
    "brave",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u8499",
    "cover; ignorant",
    "m\u00e9ng",
    "meng2"
  ],
  [
    "\u5192",
    "to emit",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u57c3",
    "fine dust; dirt",
    "\u0101i",
    "ai1"
  ],
  [
    "\u6885",
    "plum",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u955c",
    "mirror",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u93e1",
    "\u955c (mirror)",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u53bf",
    "a district; county",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u7e23",
    "\u53bf (a district; county)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u9e21",
    "chicken",
    "j\u012b",
    "ji1"
  ],
  [
    "\u96de",
    "\u9e21 (chicken)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u8bda",
    "honest; sincere",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u8aa0",
    "\u8bda (honest; sincere)",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u8feb",
    "to force",
    "p\u00f2",
    "po4"
  ],
  [
    "\u5385",
    "hall",
    "t\u012bng",
    "ting1"
  ],
  [
    "\u5ef3",
    "\u5385 (hall)",
    "t\u012bng",
    "ting1"
  ],
  [
    "\u4e01",
    "male adult; robust",
    "d\u012bng",
    "ding1"
  ],
  [
    "\u59d1",
    "aunt",
    "g\u016b",
    "gu1"
  ],
  [
    "\u5211",
    "to punish",
    "x\u00edng",
    "xing2"
  ],
  [
    "\u8482",
    "stem of plant",
    "d\u00ec",
    "di4"
  ],
  [
    "\u5b81",
    "peaceful",
    "n\u00edng",
    "ning2"
  ],
  [
    "\u5be7",
    "\u5b81 (peaceful)",
    "n\u00edng",
    "ning2"
  ],
  [
    "\u5a1c",
    "elegant; delicate",
    "nu\u00f3",
    "nuo2"
  ],
  [
    "\u5251",
    "(double-edged) sword",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u528d",
    "\u5251 ((double-edged) sword)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u6b8b",
    "to injure; cruel",
    "c\u00e1n",
    "can2"
  ],
  [
    "\u6b98",
    "\u6b8b (to injure; cruel)",
    "c\u00e1n",
    "can2"
  ],
  [
    "\u67d3",
    "to dye; to acquire",
    "r\u01cen",
    "ran3"
  ],
  [
    "\u5766",
    "flat; smooth",
    "t\u01cen",
    "tan3"
  ],
  [
    "\u5b97",
    "school; sect; purpose; model",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u642d",
    "to put up",
    "d\u0101",
    "da1"
  ],
  [
    "\u5f92",
    "apprentice",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u5377",
    "roll; to roll up",
    "ju\u01cen",
    "juan3"
  ],
  [
    "\u7763",
    "to supervise",
    "d\u016b",
    "du1"
  ],
  [
    "\u865a",
    "emptiness; modest",
    "x\u016b",
    "xu1"
  ],
  [
    "\u865b",
    "\u865a (emptiness; modest)",
    "x\u016b",
    "xu1"
  ],
  [
    "\u966a",
    "to accompany",
    "p\u00e9i",
    "pei2"
  ],
  [
    "\u9a97",
    "to cheat",
    "pi\u00e0n",
    "pian4"
  ],
  [
    "\u9a19",
    "\u9a97 (to cheat)",
    "pi\u00e0n",
    "pian4"
  ],
  [
    "\u817f",
    "legs; thighs",
    "tu\u01d0",
    "tui3"
  ],
  [
    "\u76db",
    "to ladle",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u590f",
    "Summer",
    "xi\u00e0",
    "xia4"
  ],
  [
    "\u60b2",
    "sorrow",
    "b\u0113i",
    "bei1"
  ],
  [
    "\u9707",
    "to shake",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u6e10",
    "imbue, gradual; gradually",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u6f38",
    "\u6e10 (imbue, gradual; gradually)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u5e86",
    "to celebrate",
    "q\u00ecng",
    "qing4"
  ],
  [
    "\u6176",
    "\u5e86 (to celebrate)",
    "q\u00ecng",
    "qing4"
  ],
  [
    "\u656c",
    "to respect",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u51f6",
    "culprit; scary",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u5147",
    "\u51f6 (culprit; scary)",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u6eda",
    "to boil",
    "g\u01d4n",
    "gun3"
  ],
  [
    "\u6efe",
    "\u6eda (to boil)",
    "g\u01d4n",
    "gun3"
  ],
  [
    "\u6b32",
    "to wish for; desire",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u8e48",
    "to stamp feet",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u7ecd",
    "to continue",
    "sh\u00e0o",
    "shao4"
  ],
  [
    "\u7d39",
    "\u7ecd (to continue)",
    "sh\u00e0o",
    "shao4"
  ],
  [
    "\u54b1",
    "we; us",
    "z\u00e1n",
    "zan2"
  ],
  [
    "\u8f86",
    "mw. for vehicles",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u8f1b",
    "\u8f86 (mw. for vehicles)",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u5c38",
    "corpse",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u5c4d",
    "\u5c38 (corpse)",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u7bb1",
    "box; chest",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u7a97",
    "window",
    "chu\u0101ng",
    "chuang1"
  ],
  [
    "\u5e55",
    "curtain",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u5413",
    "to scare",
    "xi\u00e0",
    "xia4"
  ],
  [
    "\u5687",
    "\u5413 (to scare)",
    "xi\u00e0",
    "xia4"
  ],
  [
    "\u9014",
    "way; route",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u80f8",
    "breast; chest",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u8d5e",
    "to praise; to support",
    "z\u00e0n",
    "zan4"
  ],
  [
    "\u8d0a",
    "\u8d5e (to praise; to support)",
    "z\u00e0n",
    "zan4"
  ],
  [
    "\u6012",
    "anger; rage",
    "n\u00f9",
    "nu4"
  ],
  [
    "\u6355",
    "to arrest",
    "b\u01d4",
    "bu3"
  ],
  [
    "\u6d1e",
    "cave; hole",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u542b",
    "to keep; to contain",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u83f2",
    "fragrant; luxuriant",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u77a7",
    "to glance at",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u7532",
    "first; shell",
    "ji\u01ce",
    "jia3"
  ],
  [
    "\u5708",
    "ring; to circle",
    "qu\u0101n",
    "quan1"
  ],
  [
    "\u672b",
    "tip; final ",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u542f",
    "to start; to open",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u5553",
    "\u542f (to start; to open)",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u66fc",
    "extended",
    "m\u00e0n",
    "man4"
  ],
  [
    "\u51c0",
    "clean",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u6de8",
    "\u51c0 (clean)",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u764c",
    "cancer",
    "\u00e1i",
    "ai2"
  ],
  [
    "\u7956",
    "ancestor",
    "z\u01d4",
    "zu3"
  ],
  [
    "\u6270",
    "to disturb",
    "r\u01ceo",
    "rao3"
  ],
  [
    "\u64fe",
    "\u6270 (to disturb)",
    "r\u01ceo",
    "rao3"
  ],
  [
    "\u7b26",
    "symbol; charm",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u5999",
    "wonderful",
    "mi\u00e0o",
    "miao4"
  ],
  [
    "\u594b",
    "to strive",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u596e",
    "\u594b (to strive)",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u7c89",
    "powder",
    "f\u011bn",
    "fen3"
  ],
  [
    "\u810f",
    "dirty",
    "z\u0101ng",
    "zang1"
  ],
  [
    "\u9ad2",
    "\u810f (dirty)",
    "z\u0101ng",
    "zang1"
  ],
  [
    "\u4e08",
    "husband",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u7eaf",
    "pure",
    "ch\u00fan",
    "chun2"
  ],
  [
    "\u7d14",
    "\u7eaf (pure)",
    "ch\u00fan",
    "chun2"
  ],
  [
    "\u62a2",
    "to plunder",
    "qi\u01ceng",
    "qiang3"
  ],
  [
    "\u6436",
    "\u62a2 (to plunder)",
    "qi\u01ceng",
    "qiang3"
  ],
  [
    "\u604b",
    "to feel attached to",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u6200",
    "\u604b (to feel attached to)",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u5f17",
    "not, negative",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u684c",
    "table",
    "zhu\u014d",
    "zhuo1"
  ],
  [
    "\u5a46",
    "grandmother",
    "p\u00f3",
    "po2"
  ],
  [
    "\u54ed",
    "to cry",
    "k\u016b",
    "ku1"
  ],
  [
    "\u4f1f",
    "big; great",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u5049",
    "\u4f1f (big; great)",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u79df",
    "to hire",
    "z\u016b",
    "zu1"
  ],
  [
    "\u5269",
    "to remain",
    "sh\u00e8ng",
    "sheng4"
  ],
  [
    "\u4ea6",
    "also",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u4f30",
    "to estimate",
    "g\u016b",
    "gu1"
  ],
  [
    "\u5076",
    "accidental",
    "\u01d2u",
    "ou3"
  ],
  [
    "\u804a",
    "to chat",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u53f6",
    "(surname); leaf; page",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u8449",
    "\u53f6 ((surname); leaf; page)",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u6c7d",
    "steam",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u5fc6",
    "to remember",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u61b6",
    "\u5fc6 (to remember)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u6302",
    "to hang; to register",
    "gu\u00e0",
    "gua4"
  ],
  [
    "\u639b",
    "\u6302 (to hang; to register)",
    "gu\u00e0",
    "gua4"
  ],
  [
    "\u7d2f",
    "tired",
    "l\u00e9i",
    "lei2"
  ],
  [
    "\u4fc4",
    "sudden(ly), Russia",
    "\u00e9",
    "e2"
  ],
  [
    "\u54c7",
    "child cry sound",
    "w\u0101",
    "wa1"
  ],
  [
    "\u538c",
    "to loathe",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u53ad",
    "\u538c (to loathe)",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u4e39",
    "cinnabar",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u952e",
    "key(board)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u9375",
    "\u952e (key(board))",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u5f7c",
    "that; those",
    "b\u01d0",
    "bi3"
  ],
  [
    "\u4eea",
    "ceremony",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u5100",
    "\u4eea (ceremony)",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u4e54",
    "tall; lofty",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u55ac",
    "\u4e54 (tall; lofty)",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u96f6",
    "zero",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u6e2f",
    "harbor; Hong Kong (abbr.)",
    "g\u01ceng",
    "gang3"
  ],
  [
    "\u9891",
    "frequently",
    "p\u00edn",
    "pin2"
  ],
  [
    "\u983b",
    "\u9891 (frequently)",
    "p\u00edn",
    "pin2"
  ],
  [
    "\u8389",
    "white jasmine",
    "l\u00ec",
    "li4"
  ],
  [
    "\u5b87",
    "universe",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u4fb5",
    "to invade",
    "q\u012bn",
    "qin1"
  ],
  [
    "\u53d4",
    "father's younger brother",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u8fdd",
    "to disobey",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u9055",
    "\u8fdd (to disobey)",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u6492",
    "to release",
    "s\u0101",
    "sa1"
  ],
  [
    "\u5242",
    "liquid solution",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u5291",
    "\u5242 (liquid solution)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u5899",
    "wall",
    "qi\u00e1ng",
    "qiang2"
  ],
  [
    "\u7246",
    "\u5899 (wall)",
    "qi\u00e1ng",
    "qiang2"
  ],
  [
    "\u9501",
    "lock",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u9396",
    "\u9501 (lock)",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u592e",
    "beg; center",
    "y\u0101ng",
    "yang1"
  ],
  [
    "\u6cf0",
    "safe; grand",
    "t\u00e0i",
    "tai4"
  ],
  [
    "\u6068",
    "to hate",
    "h\u00e8n",
    "hen4"
  ],
  [
    "\u7389",
    "jade",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u654f",
    "quick",
    "m\u01d0n",
    "min3"
  ],
  [
    "\u8fdf",
    "late; slow",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u9072",
    "\u8fdf (late; slow)",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u9677",
    "to submerge",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u65e6",
    "dawn",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u8212",
    "leisurely; to stretch",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u6f5c",
    "to hide; hidden",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u6f5b",
    "\u6f5c (to hide; hidden)",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u593a",
    "to take by force",
    "du\u00f3",
    "duo2"
  ],
  [
    "\u596a",
    "\u593a (to take by force)",
    "du\u00f3",
    "duo2"
  ],
  [
    "\u504f",
    "to lean; oblique",
    "pi\u0101n",
    "pian1"
  ],
  [
    "\u59d3",
    "surname",
    "x\u00ecng",
    "xing4"
  ],
  [
    "\u5e9f",
    "to abolish",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u5ee2",
    "\u5e9f (to abolish)",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u50bb",
    "foolish",
    "sh\u01ce",
    "sha3"
  ],
  [
    "\u9a91",
    "to ride",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u9a0e",
    "\u9a91 (to ride)",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u9b42",
    "soul; spirit",
    "h\u00fan",
    "hun2"
  ],
  [
    "\u60ef",
    "accustomed to",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u6163",
    "\u60ef (accustomed to)",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u6db2",
    "sap; juice",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u52b2",
    "strong; tough",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u52c1",
    "\u52b2 (strong; tough)",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u4e22",
    "to lose",
    "di\u016b",
    "diu1"
  ],
  [
    "\u4e1f",
    "\u4e22 (to lose)",
    "di\u016b",
    "diu1"
  ],
  [
    "\u56fa",
    "hard; strong; solid; sure",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u7f5a",
    "penalty",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u7f70",
    "\u7f5a (penalty)",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u6212",
    "to warn",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u8d34",
    "to paste; to post",
    "ti\u0113",
    "tie1"
  ],
  [
    "\u8cbc",
    "\u8d34 (to paste; to post)",
    "ti\u0113",
    "tie1"
  ],
  [
    "\u6863",
    "grade (of goods); shelves",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u6a94",
    "\u6863 (grade (of goods); shelves)",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u9694",
    "to partition",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u6ed1",
    "to slip; cunning",
    "hu\u00e1",
    "hua2"
  ],
  [
    "\u73cd",
    "precious",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u732e",
    "to offer",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u737b",
    "\u732e (to offer)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u4e88",
    "I, to give",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u62d2",
    "to resist",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u9e1f",
    "bird",
    "ni\u01ceo",
    "niao3"
  ],
  [
    "\u9ce5",
    "\u9e1f (bird)",
    "ni\u01ceo",
    "niao3"
  ],
  [
    "\u989c",
    "face",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u984f",
    "\u989c (face)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u7a0d",
    "somewhat; a little",
    "sh\u0101o",
    "shao1"
  ],
  [
    "\u641c",
    "to seek",
    "s\u014du",
    "sou1"
  ],
  [
    "\u5c41",
    "fart; nonsense",
    "p\u00ec",
    "pi4"
  ],
  [
    "\u74e6",
    "tile; pottery",
    "w\u01ce",
    "wa3"
  ],
  [
    "\u9f3b",
    "nose",
    "b\u00ed",
    "bi2"
  ],
  [
    "\u88ad",
    "to raid",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u8972",
    "\u88ad (to raid)",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u6f2b",
    "free; to inundate",
    "m\u00e0n",
    "man4"
  ],
  [
    "\u8d4f",
    "to bestow",
    "sh\u01ceng",
    "shang3"
  ],
  [
    "\u8cde",
    "\u8d4f (to bestow)",
    "sh\u01ceng",
    "shang3"
  ],
  [
    "\u5c3e",
    "tail",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u8bca",
    "to diagnose",
    "zh\u011bn",
    "zhen3"
  ],
  [
    "\u8a3a",
    "\u8bca (to diagnose)",
    "zh\u011bn",
    "zhen3"
  ],
  [
    "\u978b",
    "shoes",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u5c3a",
    "Chinese \"foot\"",
    "ch\u01d0",
    "chi3"
  ],
  [
    "\u6478",
    "feel with hand",
    "m\u014d",
    "mo1"
  ],
  [
    "\u72b9",
    "like; similar to",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u7336",
    "\u72b9 (like; similar to)",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u8f9b",
    "hot taste; hard",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u7530",
    "(surname); field; farm",
    "ti\u00e1n",
    "tian2"
  ],
  [
    "\u5c1d",
    "taste; experience",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u5617",
    "\u5c1d (taste; experience)",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u751c",
    "sweet",
    "ti\u00e1n",
    "tian2"
  ],
  [
    "\u7cd5",
    "cake",
    "g\u0101o",
    "gao1"
  ],
  [
    "\u8c0e",
    "lie",
    "hu\u01ceng",
    "huang3"
  ],
  [
    "\u8b0a",
    "\u8c0e (lie)",
    "hu\u01ceng",
    "huang3"
  ],
  [
    "\u649e",
    "to hit",
    "zhu\u00e0ng",
    "zhuang4"
  ],
  [
    "\u95f9",
    "noisy",
    "n\u00e0o",
    "nao4"
  ],
  [
    "\u9b27",
    "\u95f9 (noisy)",
    "n\u00e0o",
    "nao4"
  ],
  [
    "\u9010",
    "to pursue; to chase",
    "zh\u00fa",
    "zhu2"
  ],
  [
    "\u51a0",
    "hat; crown",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u5f7b",
    "to penetrate",
    "ch\u00e8",
    "che4"
  ],
  [
    "\u5fb9",
    "\u5f7b (to penetrate)",
    "ch\u00e8",
    "che4"
  ],
  [
    "\u9080",
    "to invite",
    "y\u0101o",
    "yao1"
  ],
  [
    "\u5594",
    "I see; oh",
    "\u014d",
    "o1"
  ],
  [
    "\u5bbe",
    "guest; visitor",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u8cd3",
    "\u5bbe (guest; visitor)",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u8d56",
    "to depend on; bad",
    "l\u00e0i",
    "lai4"
  ],
  [
    "\u8cf4",
    "\u8d56 (to depend on; bad)",
    "l\u00e0i",
    "lai4"
  ],
  [
    "\u58a8",
    "black; corrupt",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u4ed4",
    "meticulous; young",
    "z\u01d0",
    "zi3"
  ],
  [
    "\u558a",
    "to shout",
    "h\u01cen",
    "han3"
  ],
  [
    "\u7ebd",
    "knot; button; knob",
    "ni\u01d4",
    "niu3"
  ],
  [
    "\u7d10",
    "\u7ebd (knot; button; knob)",
    "ni\u01d4",
    "niu3"
  ],
  [
    "\u4e58",
    "to ride",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u60a3",
    "misfortune",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u6c64",
    "soup; hot water",
    "t\u0101ng",
    "tang1"
  ],
  [
    "\u6e6f",
    "\u6c64 (soup; hot water)",
    "t\u0101ng",
    "tang1"
  ],
  [
    "\u5e7b",
    "fantasy",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u5cb8",
    "bank; shore; beach; coast",
    "\u00e0n",
    "an4"
  ],
  [
    "\u6c60",
    "pond; reservoir",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u6790",
    "to separate; to divide",
    "x\u012b",
    "xi1"
  ],
  [
    "\u63f4",
    "to help",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u5b64",
    "lonely ",
    "g\u016b",
    "gu1"
  ],
  [
    "\u6682",
    "temporary",
    "z\u00e0n",
    "zan4"
  ],
  [
    "\u66ab",
    "\u6682 (temporary)",
    "z\u00e0n",
    "zan4"
  ],
  [
    "\u7cd6",
    "sugar; sweets",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u90a6",
    "country; nation",
    "b\u0101ng",
    "bang1"
  ],
  [
    "\u6e56",
    "lake",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u76df",
    "oath; pledge; union; to ally",
    "m\u00e9ng",
    "meng2"
  ],
  [
    "\u788e",
    "to break; broken",
    "su\u00ec",
    "sui4"
  ],
  [
    "\u5fe0",
    "loyal",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u5806",
    "to pile up; heap",
    "du\u012b",
    "dui1"
  ],
  [
    "\u7eb9",
    "line; streak",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u7d0b",
    "\u7eb9 (line; streak)",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u517d",
    "beast",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u7378",
    "\u517d (beast)",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u9897",
    "particle",
    "k\u0113",
    "ke1"
  ],
  [
    "\u9846",
    "\u9897 (particle)",
    "k\u0113",
    "ke1"
  ],
  [
    "\u5c16",
    "sharp; acute",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u642c",
    "to move (house)",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u5ffd",
    "suddenly",
    "h\u016b",
    "hu1"
  ],
  [
    "\u9177",
    "ruthless; cool",
    "k\u00f9",
    "ku4"
  ],
  [
    "\u8f88",
    "generation",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u8f29",
    "\u8f88 (generation)",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u60dc",
    "to cherish",
    "x\u012b",
    "xi1"
  ],
  [
    "\u6062",
    "to restore",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u6717",
    "clear; bright",
    "l\u01ceng",
    "lang3"
  ],
  [
    "\u806a",
    "intelligent",
    "c\u014dng",
    "cong1"
  ],
  [
    "\u8070",
    "\u806a (intelligent)",
    "c\u014dng",
    "cong1"
  ],
  [
    "\u6148",
    "kind; charitable",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u8bde",
    "give birth",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u8a95",
    "\u8bde (give birth)",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u866b",
    "insects",
    "ch\u00f3ng",
    "chong2"
  ],
  [
    "\u87f2",
    "\u866b (insects)",
    "ch\u00f3ng",
    "chong2"
  ],
  [
    "\u5496",
    "coffee (phonetic)",
    "k\u0101",
    "ka1"
  ],
  [
    "\u5389",
    "strict",
    "l\u00ec",
    "li4"
  ],
  [
    "\u53b2",
    "\u5389 (strict)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u624e",
    "to pierce",
    "zh\u0101",
    "zha1"
  ],
  [
    "\u8d4c",
    "to gamble",
    "d\u01d4",
    "du3"
  ],
  [
    "\u8ced",
    "\u8d4c (to gamble)",
    "d\u01d4",
    "du3"
  ],
  [
    "\u626b",
    "to sweep",
    "s\u01ceo",
    "sao3"
  ],
  [
    "\u6383",
    "\u626b (to sweep)",
    "s\u01ceo",
    "sao3"
  ],
  [
    "\u4e30",
    "buxom; good-looking",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u8c50",
    "\u4e30 (buxom; good-looking)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u78e8",
    "grind; rub",
    "m\u00f3",
    "mo2"
  ],
  [
    "\u63cf",
    "to copy",
    "mi\u00e1o",
    "miao2"
  ],
  [
    "\u732b",
    "cat",
    "m\u0101o",
    "mao1"
  ],
  [
    "\u8c93",
    "\u732b (cat)",
    "m\u0101o",
    "mao1"
  ],
  [
    "\u827e",
    "(surname); Artemisia vulgaris",
    "\u00e0i",
    "ai4"
  ],
  [
    "\u80c6",
    "gall; bravery",
    "d\u01cen",
    "dan3"
  ],
  [
    "\u81bd",
    "\u80c6 (gall; bravery)",
    "d\u01cen",
    "dan3"
  ],
  [
    "\u8c6a",
    "heroic",
    "h\u00e1o",
    "hao2"
  ],
  [
    "\u88c2",
    "crack",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u90d1",
    "(surname)",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u912d",
    "\u90d1 ((surname))",
    "zh\u00e8ng",
    "zheng4"
  ],
  [
    "\u5561",
    "coffee; morphine",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u95f2",
    "idle",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u9592",
    "\u95f2 (idle)",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u4e4c",
    "crow",
    "w\u016b",
    "wu1"
  ],
  [
    "\u70cf",
    "\u4e4c (crow)",
    "w\u016b",
    "wu1"
  ],
  [
    "\u7f18",
    "along; predestined affinity",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u7de3",
    "\u7f18 (along; predestined affinity)",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u59ae",
    "girl",
    "n\u012b",
    "ni1"
  ],
  [
    "\u5baa",
    "statute; constitution",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u61b2",
    "\u5baa (statute; constitution)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u5e01",
    "money; coins; currency",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5e63",
    "\u5e01 (money; coins; currency)",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u7eea",
    "beginnings; thread",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u7dd2",
    "\u7eea (beginnings; thread)",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u62d6",
    "to drag",
    "tu\u014d",
    "tuo1"
  ],
  [
    "\u4f0d",
    "to associate with",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u6d01",
    "clean",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u6f54",
    "\u6d01 (clean)",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u9f20",
    "rat",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u5ef6",
    "to prolong; to extend; to delay",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u5141",
    "to grant",
    "y\u01d4n",
    "yun3"
  ],
  [
    "\u7840",
    "foundation; base",
    "ch\u01d4",
    "chu3"
  ],
  [
    "\u790e",
    "\u7840 (foundation; base)",
    "ch\u01d4",
    "chu3"
  ],
  [
    "\u8fa9",
    "to dispute",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u8faf",
    "\u8fa9 (to dispute)",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u601c",
    "to pity",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u6190",
    "\u601c (to pity)",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u8fc8",
    "to pass by",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u9081",
    "\u8fc8 (to pass by)",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u5e10",
    "screen",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u5e33",
    "\u5e10 (screen)",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u8eb2",
    "to hide",
    "du\u01d2",
    "duo3"
  ],
  [
    "\u878d",
    "harmonious; melt; mild",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u7070",
    "ashes",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u626c",
    "hurl; to raise; to scatter",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u63da",
    "\u626c (hurl; to raise; to scatter)",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u74f6",
    "bottle; vase",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u7f13",
    "slow; sluggish; gradual",
    "hu\u01cen",
    "huan3"
  ],
  [
    "\u7de9",
    "\u7f13 (slow; sluggish; gradual)",
    "hu\u01cen",
    "huan3"
  ],
  [
    "\u6768",
    "(surname); poplar",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u694a",
    "\u6768 ((surname); poplar)",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u903c",
    "to pressure",
    "b\u012b",
    "bi1"
  ],
  [
    "\u63d2",
    "to insert; pierce",
    "ch\u0101",
    "cha1"
  ],
  [
    "\u5706",
    "circle; round; circular",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u5713",
    "\u5706 (circle; round; circular)",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u70c2",
    "soft; rotten",
    "l\u00e0n",
    "lan4"
  ],
  [
    "\u721b",
    "\u70c2 (soft; rotten)",
    "l\u00e0n",
    "lan4"
  ],
  [
    "\u96c7",
    "to employ",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u50f1",
    "\u96c7 (to employ)",
    "g\u00f9",
    "gu4"
  ],
  [
    "\u5439",
    "to blow",
    "chu\u012b",
    "chui1"
  ],
  [
    "\u675c",
    "to prevent",
    "d\u00f9",
    "du4"
  ],
  [
    "\u88e4",
    "trousers",
    "k\u00f9",
    "ku4"
  ],
  [
    "\u8932",
    "\u88e4 (trousers)",
    "k\u00f9",
    "ku4"
  ],
  [
    "\u75bc",
    "aches; pains",
    "t\u00e9ng",
    "teng2"
  ],
  [
    "\u6269",
    "enlarge",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u64f4",
    "\u6269 (enlarge)",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u9189",
    "intoxicated",
    "zu\u00ec",
    "zui4"
  ],
  [
    "\u80c1",
    "ribs; to threaten",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u8105",
    "\u80c1 (ribs; to threaten)",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u51e1",
    "ordinary; every; all; whatever",
    "f\u00e1n",
    "fan2"
  ],
  [
    "\u7b51",
    "build; five-string lute",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u7bc9",
    "\u7b51 (build; five-string lute)",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u62f3",
    "fist; boxing",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u7f8a",
    "sheep",
    "y\u00e1ng",
    "yang2"
  ],
  [
    "\u7f62",
    "to stop; to suspend",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u7f77",
    "\u7f62 (to stop; to suspend)",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u8bd7",
    "poem; poetry; verse",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u8a69",
    "\u8bd7 (poem; poetry; verse)",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u8822",
    "stupid",
    "ch\u01d4n",
    "chun3"
  ],
  [
    "\u6ce1",
    "bubble; to steep",
    "p\u00e0o",
    "pao4"
  ],
  [
    "\u79d2",
    "a second",
    "mi\u01ceo",
    "miao3"
  ],
  [
    "\u7bc7",
    "sheet; piece of writing; (a mw.)",
    "pi\u0101n",
    "pian1"
  ],
  [
    "\u5b9c",
    "proper; should; suitable",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u8352",
    "wasteland",
    "hu\u0101ng",
    "huang1"
  ],
  [
    "\u64a4",
    "to omit",
    "ch\u00e8",
    "che4"
  ],
  [
    "\u5bc4",
    "to mail",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u732a",
    "pig",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u8c6c",
    "\u732a (pig)",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u90ae",
    "post; mail",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u90f5",
    "\u90ae (post; mail)",
    "y\u00f3u",
    "you2"
  ],
  [
    "\u7eb7",
    "numerous; confused; disorderly",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u7d1b",
    "\u7eb7 (numerous; confused; disorderly)",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u543b",
    "kiss; lips",
    "w\u011bn",
    "wen3"
  ],
  [
    "\u5e15",
    "turban; veil",
    "p\u00e0",
    "pa4"
  ],
  [
    "\u9a7e",
    "to operate vehicle",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u99d5",
    "\u9a7e (to operate vehicle)",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u6668",
    "early morning",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u6cfd",
    "beneficence; marsh",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u6fa4",
    "\u6cfd (beneficence; marsh)",
    "z\u00e9",
    "ze2"
  ],
  [
    "\u541b",
    "monarch; lord; gentleman; ruler",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u5acc",
    "to hate",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u4e27",
    "to lose",
    "s\u00e0ng",
    "sang4"
  ],
  [
    "\u55aa",
    "\u4e27 (to lose)",
    "s\u00e0ng",
    "sang4"
  ],
  [
    "\u7262",
    "prison; stable",
    "l\u00e1o",
    "lao2"
  ],
  [
    "\u74dc",
    "melon",
    "gu\u0101",
    "gua1"
  ],
  [
    "\u57f9",
    "to cultivate; to earth up",
    "p\u00e9i",
    "pei2"
  ],
  [
    "\u4f69",
    "to respect; wear (belt)",
    "p\u00e8i",
    "pei4"
  ],
  [
    "\u690d",
    "to plant",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u8bf8",
    "(surname); all; many; various",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u8af8",
    "\u8bf8 ((surname); all; many; various)",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u5510",
    "Tang dynasty (618-907)",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u60e8",
    "miserable",
    "c\u01cen",
    "can3"
  ],
  [
    "\u6158",
    "\u60e8 (miserable)",
    "c\u01cen",
    "can3"
  ],
  [
    "\u5821",
    "fortress",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u6254",
    "to throw",
    "r\u0113ng",
    "reng1"
  ],
  [
    "\u5410",
    "to vomit",
    "t\u00f9",
    "tu4"
  ],
  [
    "\u5c3f",
    "urine",
    "ni\u00e0o",
    "niao4"
  ],
  [
    "\u4fc3",
    "to hurry; to rush; to hasten",
    "c\u00f9",
    "cu4"
  ],
  [
    "\u6905",
    "chair",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u5e84",
    "farm; village",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u838a",
    "\u5e84 (farm; village)",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u606d",
    "polite",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u6de1",
    "insipid; diluted; weak",
    "d\u00e0n",
    "dan4"
  ],
  [
    "\u8f9e",
    "words",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u8fad",
    "\u8f9e (words)",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u722c",
    "to crawl",
    "p\u00e1",
    "pa2"
  ],
  [
    "\u9f50",
    "(surname); even; to make even",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u9f4a",
    "\u9f50 ((surname); even; to make even)",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u594f",
    "to play music",
    "z\u00f2u",
    "zou4"
  ],
  [
    "\u5bbd",
    "lenient; wide; broad",
    "ku\u0101n",
    "kuan1"
  ],
  [
    "\u5bec",
    "\u5bbd (lenient; wide; broad)",
    "ku\u0101n",
    "kuan1"
  ],
  [
    "\u63a9",
    "to cover",
    "y\u01cen",
    "yan3"
  ],
  [
    "\u9875",
    "page; leaf",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u9801",
    "\u9875 (page; leaf)",
    "y\u00e8",
    "ye4"
  ],
  [
    "\u626e",
    "to dress up",
    "b\u00e0n",
    "ban4"
  ],
  [
    "\u4fa6",
    "spy",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u5075",
    "\u4fa6 (spy)",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u80a5",
    "fat; plump",
    "f\u00e9i",
    "fei2"
  ],
  [
    "\u6b8a",
    "unique",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u94bb",
    "to drill",
    "zu\u0101n",
    "zuan1"
  ],
  [
    "\u947d",
    "\u94bb (to drill)",
    "zu\u0101n",
    "zuan1"
  ],
  [
    "\u90aa",
    "demonic; evil",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u62b5",
    "hold up; on the whole",
    "d\u01d0",
    "di3"
  ],
  [
    "\u60e7",
    "to fear",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u61fc",
    "\u60e7 (to fear)",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u5a92",
    "medium; intermediary; matchmaker",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u5b59",
    "grandson",
    "s\u016bn",
    "sun1"
  ],
  [
    "\u5b6b",
    "\u5b59 (grandson)",
    "s\u016bn",
    "sun1"
  ],
  [
    "\u8a93",
    "swear; oath",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u8d38",
    "commerce; trade",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u8cbf",
    "\u8d38 (commerce; trade)",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u6016",
    "terror; fear",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u68af",
    "ladder",
    "t\u012b",
    "ti1"
  ],
  [
    "\u7cca",
    "paste",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u997c",
    "biscuits",
    "b\u01d0ng",
    "bing3"
  ],
  [
    "\u9905",
    "\u997c (biscuits)",
    "b\u01d0ng",
    "bing3"
  ],
  [
    "\u54ac",
    "bite; gnaw",
    "y\u01ceo",
    "yao3"
  ],
  [
    "\u7f29",
    "to withdraw; to pull back",
    "su\u014d",
    "suo1"
  ],
  [
    "\u7e2e",
    "\u7f29 (to withdraw; to pull back)",
    "su\u014d",
    "suo1"
  ],
  [
    "\u8fc5",
    "rapid",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u6028",
    "to blame",
    "yu\u00e0n",
    "yuan4"
  ],
  [
    "\u51cc",
    "encroach; soar; thick ice",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u7433",
    "gem",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u6c83",
    "to irrigate",
    "w\u00f2",
    "wo4"
  ],
  [
    "\u8ddd",
    "at a distance of; distance",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u864e",
    "tiger",
    "h\u01d4",
    "hu3"
  ],
  [
    "\u88c1",
    "cut out (as a dress); cut; trim",
    "c\u00e1i",
    "cai2"
  ],
  [
    "\u76d7",
    "to steal",
    "d\u00e0o",
    "dao4"
  ],
  [
    "\u76dc",
    "\u76d7 (to steal)",
    "d\u00e0o",
    "dao4"
  ],
  [
    "\u7235",
    "feudal title",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u75f4",
    "imbecile",
    "ch\u012b",
    "chi1"
  ],
  [
    "\u7661",
    "\u75f4 (imbecile)",
    "ch\u012b",
    "chi1"
  ],
  [
    "\u62fc",
    "to join together",
    "p\u012bn",
    "pin1"
  ],
  [
    "\u4f38",
    "to stretch; to extend",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u7d2b",
    "purple",
    "z\u01d0",
    "zi3"
  ],
  [
    "\u5434",
    "(surname); province of Jiangsu",
    "w\u00fa",
    "wu2"
  ],
  [
    "\u5433",
    "\u5434 ((surname); province of Jiangsu)",
    "w\u00fa",
    "wu2"
  ],
  [
    "\u52ab",
    "to take by force",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u8c37",
    "grain; corn, valley, (surname)",
    "g\u01d4",
    "gu3"
  ],
  [
    "\u629b",
    "throw (away)",
    "p\u0101o",
    "pao1"
  ],
  [
    "\u62cb",
    "\u629b (throw (away))",
    "p\u0101o",
    "pao1"
  ],
  [
    "\u5740",
    "location",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u90ce",
    "young man",
    "l\u00e1ng",
    "lang2"
  ],
  [
    "\u4ec7",
    "enemy; hate",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u8c46",
    "bean",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u7ff0",
    "writing brush",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u5b55",
    "be pregnant",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u5435",
    "to quarrel",
    "ch\u01ceo",
    "chao3"
  ],
  [
    "\u8eba",
    "to lie down",
    "t\u01ceng",
    "tang3"
  ],
  [
    "\u8d5a",
    "to earn",
    "zhu\u00e0n",
    "zhuan4"
  ],
  [
    "\u8cfa",
    "\u8d5a (to earn)",
    "zhu\u00e0n",
    "zhuan4"
  ],
  [
    "\u4e73",
    "breast; milk",
    "r\u01d4",
    "ru3"
  ],
  [
    "\u64e6",
    "to wipe",
    "c\u0101",
    "ca1"
  ],
  [
    "\u5bd2",
    "cold; poor; to tremble",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u9ece",
    "dawn; black",
    "l\u00ed",
    "li2"
  ],
  [
    "\u6d1b",
    "(surname); name of a river",
    "lu\u00f2",
    "luo4"
  ],
  [
    "\u76d2",
    "small box",
    "h\u00e9",
    "he2"
  ],
  [
    "\u90bb",
    "neighbour",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u9130",
    "\u90bb (neighbour)",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u6f6e",
    "tide; current; damp; moist; humid",
    "ch\u00e1o",
    "chao2"
  ],
  [
    "\u81ed",
    "smell; stink",
    "ch\u00f2u",
    "chou4"
  ],
  [
    "\u970d",
    "quickly",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u997f",
    "hungry",
    "\u00e8",
    "e4"
  ],
  [
    "\u9913",
    "\u997f (hungry)",
    "\u00e8",
    "e4"
  ],
  [
    "\u5e05",
    "handsome",
    "shu\u00e0i",
    "shuai4"
  ],
  [
    "\u5e25",
    "\u5e05 (handsome)",
    "shu\u00e0i",
    "shuai4"
  ],
  [
    "\u4ebf",
    "a hundred million; calculate",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u5104",
    "\u4ebf (a hundred million; calculate)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u75be",
    "sickness; disease; hate; envy",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u6b3a",
    "cheat",
    "q\u012b",
    "qi1"
  ],
  [
    "\u7ee9",
    "merit; accomplishment",
    "j\u012b",
    "ji1"
  ],
  [
    "\u7e3e",
    "\u7ee9 (merit; accomplishment)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u6d82",
    "to apply (paint)",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u5857",
    "\u6d82 (to apply (paint))",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u8be2",
    "inquire",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u8a62",
    "\u8be2 (inquire)",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u730e",
    "to hunt",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u7375",
    "\u730e (to hunt)",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u50b2",
    "proud",
    "\u00e0o",
    "ao4"
  ],
  [
    "\u6094",
    "to regret",
    "hu\u01d0",
    "hui3"
  ],
  [
    "\u5a03",
    "baby; doll",
    "w\u00e1",
    "wa2"
  ],
  [
    "\u539a",
    "generous",
    "h\u00f2u",
    "hou4"
  ],
  [
    "\u718a",
    "bear",
    "xi\u00f3ng",
    "xiong2"
  ],
  [
    "\u97e9",
    "South Korea; Korean",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u97d3",
    "\u97e9 (South Korea; Korean)",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u5565",
    "what?",
    "sh\u00e0",
    "sha4"
  ],
  [
    "\u6b20",
    "owe; lack",
    "qi\u00e0n",
    "qian4"
  ],
  [
    "\u4ff1",
    "all; together",
    "j\u00f9",
    "ju4"
  ],
  [
    "\u6c47",
    "remit; to converge (of rivers)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u532f",
    "\u6c47 (remit; to converge (of rivers))",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u4fa7",
    "the side; to incline towards",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u5074",
    "\u4fa7 (the side; to incline towards)",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u57cb",
    "to bury",
    "m\u00e1i",
    "mai2"
  ],
  [
    "\u6676",
    "crystal",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u7686",
    "all; each and every",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u70ae",
    "cannon; gun; firecracker, gun",
    "p\u00e0o",
    "pao4"
  ],
  [
    "\u52b1",
    "exhort",
    "l\u00ec",
    "li4"
  ],
  [
    "\u52f5",
    "\u52b1 (exhort)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u8230",
    "warship",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u8266",
    "\u8230 (warship)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u7ed1",
    "to tie",
    "b\u01ceng",
    "bang3"
  ],
  [
    "\u7d81",
    "\u7ed1 (to tie)",
    "b\u01ceng",
    "bang3"
  ],
  [
    "\u838e",
    "sedge grass",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u53db",
    "to rebel; rebellion",
    "p\u00e0n",
    "pan4"
  ],
  [
    "\u6d89",
    "involve; concern; wade",
    "sh\u00e8",
    "she4"
  ],
  [
    "\u6108",
    "heal; the more...the more",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u6321",
    "to obstruct",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u64cb",
    "\u6321 (to obstruct)",
    "d\u01ceng",
    "dang3"
  ],
  [
    "\u73e0",
    "bead; pearl",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u6851",
    "mulberry tree",
    "s\u0101ng",
    "sang1"
  ],
  [
    "\u53a8",
    "kitchen",
    "ch\u00fa",
    "chu2"
  ],
  [
    "\u5eda",
    "\u53a8 (kitchen)",
    "ch\u00fa",
    "chu2"
  ],
  [
    "\u5e3d",
    "hat",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u7483",
    "colored glaze; glass",
    "l\u00ed",
    "li2"
  ],
  [
    "\u4e11",
    "clown; ugly",
    "ch\u01d2u",
    "chou3"
  ],
  [
    "\u919c",
    "\u4e11 (clown; ugly)",
    "ch\u01d2u",
    "chou3"
  ],
  [
    "\u731b",
    "ferocious; suddenly; fierce",
    "m\u011bng",
    "meng3"
  ],
  [
    "\u5c4a",
    "arrive at (place or time)",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u5c46",
    "\u5c4a (arrive at (place or time))",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u7b28",
    "stupid",
    "b\u00e8n",
    "ben4"
  ],
  [
    "\u75d5",
    "scar",
    "h\u00e9n",
    "hen2"
  ],
  [
    "\u8c05",
    "to forgive",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u8ad2",
    "\u8c05 (to forgive)",
    "li\u00e0ng",
    "liang4"
  ],
  [
    "\u8f91",
    "gather up; collect; edit; compile",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u8f2f",
    "\u8f91 (gather up; collect; edit; compile)",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u4e43",
    "to be; thus; so; therefore; then",
    "n\u01cei",
    "nai3"
  ],
  [
    "\u8d3a",
    "congratulate",
    "h\u00e8",
    "he4"
  ],
  [
    "\u8cc0",
    "\u8d3a (congratulate)",
    "h\u00e8",
    "he4"
  ],
  [
    "\u5179",
    "now; here; this",
    "z\u012b",
    "zi1"
  ],
  [
    "\u8332",
    "\u5179 (now; here; this)",
    "z\u012b",
    "zi1"
  ],
  [
    "\u80a4",
    "skin",
    "f\u016b",
    "fu1"
  ],
  [
    "\u819a",
    "\u80a4 (skin)",
    "f\u016b",
    "fu1"
  ],
  [
    "\u76ef",
    "to keep eyes on",
    "d\u012bng",
    "ding1"
  ],
  [
    "\u51e4",
    "phoenix",
    "f\u00e8ng",
    "feng4"
  ],
  [
    "\u9cf3",
    "\u51e4 (phoenix)",
    "f\u00e8ng",
    "feng4"
  ],
  [
    "\u4ed9",
    "immortal",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u9178",
    "sour; sore; ache; acid",
    "su\u0101n",
    "suan1"
  ],
  [
    "\u871c",
    "honey",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u996e",
    "drink",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u98f2",
    "\u996e (drink)",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u5b54",
    "(surname); hole",
    "k\u01d2ng",
    "kong3"
  ],
  [
    "\u8584",
    "mean; slight; thin, thin; slight",
    "b\u00e1o",
    "bao2"
  ],
  [
    "\u5c4f",
    "get rid of; put aside; reject",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u80d6",
    "fat",
    "p\u00e0ng",
    "pang4"
  ],
  [
    "\u626f",
    "rip up; haul",
    "ch\u011b",
    "che3"
  ],
  [
    "\u723d",
    "bright; crisp",
    "shu\u01ceng",
    "shuang3"
  ],
  [
    "\u80ce",
    "embryo; fetus",
    "t\u0101i",
    "tai1"
  ],
  [
    "\u6731",
    "(surname); vermilion, vermilion",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u6572",
    "to strike",
    "qi\u0101o",
    "qiao1"
  ],
  [
    "\u6dd8",
    "wash in sieve",
    "t\u00e1o",
    "tao2"
  ],
  [
    "\u500d",
    "(two, three, etc) -fold",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u63aa",
    "put in order; arrange",
    "cu\u00f2",
    "cuo4"
  ],
  [
    "\u820d",
    "give up; abandon, residence",
    "sh\u011b",
    "she3"
  ],
  [
    "\u6109",
    "pleasant",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u80de",
    "the placenta; womb",
    "b\u0101o",
    "bao1"
  ],
  [
    "\u8102",
    "fat",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u8861",
    "to weigh; weight; measure",
    "h\u00e9ng",
    "heng2"
  ],
  [
    "\u60f9",
    "to irritate",
    "r\u011b",
    "re3"
  ],
  [
    "\u5ddd",
    "river; creek; plain",
    "chu\u0101n",
    "chuan1"
  ],
  [
    "\u6d74",
    "to bathe",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u707e",
    "disaster; calamity",
    "z\u0101i",
    "zai1"
  ],
  [
    "\u707d",
    "\u707e (disaster; calamity)",
    "z\u0101i",
    "zai1"
  ],
  [
    "\u611a",
    "stupid",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u7838",
    "to smash",
    "z\u00e1",
    "za2"
  ],
  [
    "\u5e45",
    "(mw. for textile or picture)",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u62fe",
    "to collect",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u4ec1",
    "humane",
    "r\u00e9n",
    "ren2"
  ],
  [
    "\u632f",
    "rouse",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u6167",
    "intelligent",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u9605",
    "peruse; review; to read",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u95b1",
    "\u9605 (peruse; review; to read)",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u5b8b",
    "(surname); name of a dynasty",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u5448",
    "to assume (a form); to submit",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u6cdb",
    "broad; vast; float; pan-, float",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u9686",
    "sound of drums, grand; intense",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u902e",
    "to seize",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u53f9",
    "to sigh",
    "t\u00e0n",
    "tan4"
  ],
  [
    "\u5606",
    "\u53f9 (to sigh)",
    "t\u00e0n",
    "tan4"
  ],
  [
    "\u53ec",
    "(surname)",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u7f9e",
    "disgrace; shy",
    "xi\u016b",
    "xiu1"
  ],
  [
    "\u788d",
    "to hinder; to obstruct; to block",
    "\u00e0i",
    "ai4"
  ],
  [
    "\u7919",
    "\u788d (to hinder; to obstruct; to block)",
    "\u00e0i",
    "ai4"
  ],
  [
    "\u83b2",
    "lotus",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u84ee",
    "\u83b2 (lotus)",
    "li\u00e1n",
    "lian2"
  ],
  // [
  //   "\u65bc",
  //   "(surname)",
  //   "y\u016b",
  //   "yu1"
  // ],
  [
    "\u503a",
    "debt",
    "zh\u00e0i",
    "zhai4"
  ],
  [
    "\u50b5",
    "\u503a (debt)",
    "zh\u00e0i",
    "zhai4"
  ],
  [
    "\u51ac",
    "winter",
    "d\u014dng",
    "dong1"
  ],
  [
    "\u6070",
    "exactly; just",
    "qi\u00e0",
    "qia4"
  ],
  [
    "\u51ed",
    "lean against; proof; to rely on",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u6191",
    "\u51ed (lean against; proof; to rely on)",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u5cf0",
    "peak; summit",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u5cef",
    "\u5cf0 (peak; summit)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u54f2",
    "philosophy; wise",
    "zh\u00e9",
    "zhe2"
  ],
  [
    "\u6316",
    "to dig",
    "w\u0101",
    "wa1"
  ],
  [
    "\u8bd1",
    "to translate; to interpret",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8b6f",
    "\u8bd1 (to translate; to interpret)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8036",
    "(final part.); (phonetic)",
    "y\u0113",
    "ye1"
  ],
  [
    "\u6c61",
    "see \u6c61, dirt; filth",
    "w\u016b",
    "wu1"
  ],
  [
    "\u808c",
    "flesh; muscle",
    "j\u012b",
    "ji1"
  ],
  [
    "\u520a",
    "to print; publish",
    "k\u0101n",
    "kan1"
  ],
  [
    "\u5319",
    "spoon",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u8d75",
    "(surname)",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u8d99",
    "\u8d75 ((surname))",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u6653",
    "dawn; to know; to tell (sb sth)",
    "xi\u01ceo",
    "xiao3"
  ],
  [
    "\u66c9",
    "\u6653 (dawn; to know; to tell (sb sth))",
    "xi\u01ceo",
    "xiao3"
  ],
  [
    "\u8e22",
    "kick",
    "t\u012b",
    "ti1"
  ],
  [
    "\u5339",
    "mw. for horses",
    "p\u01d0",
    "pi3"
  ],
  [
    "\u5783",
    "waste",
    "l\u0101",
    "la1"
  ],
  [
    "\u67d4",
    "soft",
    "r\u00f3u",
    "rou2"
  ],
  [
    "\u6cbf",
    "along, riverside (with -r)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u573e",
    "garbage",
    "j\u012b",
    "ji1"
  ],
  [
    "\u8150",
    "decay; rotten",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u60e0",
    "favor; kind act (from above)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u6263",
    "10 percent; button",
    "k\u00f2u",
    "kou4"
  ],
  [
    "\u61be",
    "to regret",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u6cea",
    "tears",
    "l\u00e8i",
    "lei4"
  ],
  [
    "\u6dda",
    "\u6cea (tears)",
    "l\u00e8i",
    "lei4"
  ],
  [
    "\u94a5",
    "key; lock",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u9470",
    "\u94a5 (key; lock)",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u6b23",
    "happy",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u9c8d",
    "dried fish",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u9b91",
    "\u9c8d (dried fish)",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u8010",
    "to be unbearable",
    "n\u00e0i",
    "nai4"
  ],
  [
    "\u6865",
    "bridge",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u6a4b",
    "\u6865 (bridge)",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u4e56",
    "clever; shrewd",
    "gu\u0101i",
    "guai1"
  ],
  [
    "\u82b3",
    "fragrant",
    "f\u0101ng",
    "fang1"
  ],
  [
    "\u900a",
    "humble; worse",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u905c",
    "\u900a (humble; worse)",
    "x\u00f9n",
    "xun4"
  ],
  [
    "\u7eb5",
    "vertical, even if; release",
    "z\u00f2ng",
    "zong4"
  ],
  [
    "\u7e31",
    "\u7eb5 (vertical, even if; release)",
    "z\u00f2ng",
    "zong4"
  ],
  [
    "\u70e4",
    "bake; roast",
    "k\u01ceo",
    "kao3"
  ],
  [
    "\u6da6",
    "smooth; moist",
    "r\u00f9n",
    "run4"
  ],
  [
    "\u6f64",
    "\u6da6 (smooth; moist)",
    "r\u00f9n",
    "run4"
  ],
  [
    "\u7e41",
    "complicated; many",
    "f\u00e1n",
    "fan2"
  ],
  [
    "\u80a9",
    "shoulder",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u5bff",
    "(long) life",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u58fd",
    "\u5bff ((long) life)",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u4eff",
    "to imitate",
    "f\u01ceng",
    "fang3"
  ],
  [
    "\u6620",
    "reflect; shine",
    "y\u00ecng",
    "ying4"
  ],
  [
    "\u6a2a",
    "horizontal; across",
    "h\u00e9ng",
    "heng2"
  ],
  [
    "\u6a6b",
    "\u6a2a (horizontal; across)",
    "h\u00e9ng",
    "heng2"
  ],
  [
    "\u5f84",
    "path",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u5f91",
    "\u5f84 (path)",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u5954",
    "to hurry or rush",
    "b\u0113n",
    "ben1"
  ],
  [
    "\u9a71",
    "to expel; to urge on; to drive",
    "q\u016b",
    "qu1"
  ],
  [
    "\u9a45",
    "\u9a71 (to expel; to urge on; to drive)",
    "q\u016b",
    "qu1"
  ],
  [
    "\u59fb",
    "marriage",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u6089",
    "in all cases; know",
    "x\u012b",
    "xi1"
  ],
  [
    "\u8be6",
    "detailed; comprehensive",
    "xi\u00e1ng",
    "xiang2"
  ],
  [
    "\u8a73",
    "\u8be6 (detailed; comprehensive)",
    "xi\u00e1ng",
    "xiang2"
  ],
  [
    "\u8de8",
    "step across; step astride",
    "ku\u00e0",
    "kua4"
  ],
  [
    "\u5367",
    "to crouch",
    "w\u00f2",
    "wo4"
  ],
  [
    "\u81e5",
    "\u5367 (to crouch)",
    "w\u00f2",
    "wo4"
  ],
  [
    "\u503e",
    "to overturn; to collapse",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u50be",
    "\u503e (to overturn; to collapse)",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u4ef0",
    "look up",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u65d7",
    "banner; flag",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u7f34",
    "hand in; hand over",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u7e73",
    "\u7f34 (hand in; hand over)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u83b1",
    "Chenopodium album",
    "l\u00e1i",
    "lai2"
  ],
  [
    "\u840a",
    "\u83b1 (Chenopodium album)",
    "l\u00e1i",
    "lai2"
  ],
  [
    "\u8fa3",
    "hot; spicy",
    "l\u00e0",
    "la4"
  ],
  [
    "\u65cb",
    "revolve, lathe",
    "xu\u00e1n",
    "xuan2"
  ],
  [
    "\u5bd3",
    "residence",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u8109",
    "mountain range; pulse",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u8108",
    "\u8109 (mountain range; pulse)",
    "m\u00e0i",
    "mai4"
  ],
  [
    "\u5f90",
    "slow; gentle; Xu (a surname)",
    "x\u00fa",
    "xu2"
  ],
  [
    "\u6881",
    "(surname); beam of roof",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u71d5",
    "(surname)",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u8361",
    "a pond; pool; wash; squander",
    "d\u00e0ng",
    "dang4"
  ],
  [
    "\u8569",
    "\u8361 (a pond; pool; wash; squander)",
    "d\u00e0ng",
    "dang4"
  ],
  [
    "\u5ac1",
    "to marry",
    "ji\u00e0",
    "jia4"
  ],
  [
    "\u507f",
    "to compensate; pay back",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u511f",
    "\u507f (to compensate; pay back)",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u89c8",
    "look over; to view",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u89bd",
    "\u89c8 (look over; to view)",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u90ed",
    "(surname); outer city wall",
    "gu\u014d",
    "guo1"
  ],
  [
    "\u8d8b",
    "to hasten; to hurry; walk fast",
    "q\u016b",
    "qu1"
  ],
  [
    "\u8da8",
    "\u8d8b (to hasten; to hurry; walk fast)",
    "q\u016b",
    "qu1"
  ],
  [
    "\u846c",
    "to bury (dead)",
    "z\u00e0ng",
    "zang4"
  ],
  [
    "\u8170",
    "waist",
    "y\u0101o",
    "yao1"
  ],
  [
    "\u518c",
    "book; a mw. for books; booklet",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u518a",
    "\u518c (book; a mw. for books; booklet)",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u4fd7",
    "vulgar",
    "s\u00fa",
    "su2"
  ],
  [
    "\u7948",
    "to pray",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u9970",
    "adorn; ornaments",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u98fe",
    "\u9970 (adorn; ornaments)",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u6cf3",
    "to dive; swim",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u7f72",
    "office; bureau",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u8463",
    "(surname); supervise; to direct",
    "d\u01d2ng",
    "dong3"
  ],
  [
    "\u5bbf",
    "lodge for the night; old",
    "s\u00f9",
    "su4"
  ],
  [
    "\u6454",
    "to stumble",
    "shu\u0101i",
    "shuai1"
  ],
  [
    "\u6cc9",
    "fountain; spring",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u7ffc",
    "wing",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7a77",
    "exhausted; poor",
    "qi\u00f3ng",
    "qiong2"
  ],
  [
    "\u7aae",
    "\u7a77 (exhausted; poor)",
    "qi\u00f3ng",
    "qiong2"
  ],
  [
    "\u7efc",
    "to sum up",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u7d9c",
    "\u7efc (to sum up)",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u8d2b",
    "poor",
    "p\u00edn",
    "pin2"
  ],
  [
    "\u8ca7",
    "\u8d2b (poor)",
    "p\u00edn",
    "pin2"
  ],
  [
    "\u5237",
    "brush, to select",
    "shu\u0101",
    "shua1"
  ],
  [
    "\u526a",
    "scissors",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u517c",
    "double; twice; simultaneous",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u800d",
    "to frolic",
    "shu\u01ce",
    "shua3"
  ],
  [
    "\u62ac",
    "to lift; to raise",
    "t\u00e1i",
    "tai2"
  ],
  [
    "\u68b0",
    "tools",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u7c4d",
    "(surname); record; register",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u9a82",
    "scold; abuse",
    "m\u00e0",
    "ma4"
  ],
  [
    "\u7f75",
    "\u9a82 (scold; abuse)",
    "m\u00e0",
    "ma4"
  ],
  [
    "\u58c1",
    "wall; rampart",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u739b",
    "agate; cornelian",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u746a",
    "\u739b (agate; cornelian)",
    "m\u01ce",
    "ma3"
  ],
  [
    "\u708e",
    "flame; inflammation; -itis",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u7709",
    "eyebrow; upper margin",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u4e4f",
    "short of; tired",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u8dc3",
    "to jump; to leap",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u8e8d",
    "\u8dc3 (to jump; to leap)",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u5fe7",
    "worried",
    "y\u014du",
    "you1"
  ],
  [
    "\u6182",
    "\u5fe7 (worried)",
    "y\u014du",
    "you1"
  ],
  [
    "\u756a",
    "(mw. for acts); deeds",
    "f\u0101n",
    "fan1"
  ],
  [
    "\u58ee",
    "to strengthen; strong; robust",
    "zhu\u00e0ng",
    "zhuang4"
  ],
  [
    "\u58ef",
    "\u58ee (to strengthen; strong; robust)",
    "zhu\u00e0ng",
    "zhuang4"
  ],
  [
    "\u9f84",
    "age",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u9f61",
    "\u9f84 (age)",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u62df",
    "plan to",
    "n\u01d0",
    "ni3"
  ],
  [
    "\u64ec",
    "\u62df (plan to)",
    "n\u01d0",
    "ni3"
  ],
  [
    "\u5949",
    "to receive (from superior)",
    "f\u00e8ng",
    "feng4"
  ],
  [
    "\u81e3",
    "statesman; vassal; courtier",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u6e7f",
    "moist; wet",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u6ebc",
    "\u6e7f (moist; wet)",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u848b",
    "(surname)",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u8523",
    "\u848b ((surname))",
    "ji\u01ceng",
    "jiang3"
  ],
  [
    "\u9a7b",
    "resident in; stationed in",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u99d0",
    "\u9a7b (resident in; stationed in)",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u8f70",
    "explosion; bang; boom; rumble",
    "h\u014dng",
    "hong1"
  ],
  [
    "\u8f5f",
    "\u8f70 (explosion; bang; boom; rumble)",
    "h\u014dng",
    "hong1"
  ],
  [
    "\u9a84",
    "pride",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u9a55",
    "\u9a84 (pride)",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u50a8",
    "savings; to save; to deposit",
    "ch\u01d4",
    "chu3"
  ],
  [
    "\u5132",
    "\u50a8 (savings; to save; to deposit)",
    "ch\u01d4",
    "chu3"
  ],
  [
    "\u6d2a",
    "flood",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u7af9",
    "bamboo",
    "zh\u00fa",
    "zhu2"
  ],
  [
    "\u72e0",
    "fierce; very",
    "h\u011bn",
    "hen3"
  ],
  [
    "\u5415",
    "(surname)",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u5442",
    "\u5415 ((surname))",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u6749",
    "pine; fir tree",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u609f",
    "comprehend",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u94a2",
    "steel",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u92fc",
    "\u94a2 (steel)",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u7977",
    "to pray",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u79b1",
    "\u7977 (to pray)",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u76fe",
    "shield",
    "d\u00f9n",
    "dun4"
  ],
  [
    "\u8017",
    "mouse; new; to waste; to spend",
    "h\u00e0o",
    "hao4"
  ],
  [
    "\u6d53",
    "concentrated; dense",
    "n\u00f3ng",
    "nong2"
  ],
  [
    "\u6fc3",
    "\u6d53 (concentrated; dense)",
    "n\u00f3ng",
    "nong2"
  ],
  [
    "\u7c97",
    "coarse; rough; thick; unfinished",
    "c\u016b",
    "cu1"
  ],
  [
    "\u6c0f",
    "clan name; maiden name",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u5851",
    "to model (a figure) in clay",
    "s\u00f9",
    "su4"
  ],
  [
    "\u4fa0",
    "heroic",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u4fe0",
    "\u4fa0 (heroic)",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u5609",
    "excellent",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u8d6b",
    "(surname); awe-inspiring",
    "h\u00e8",
    "he4"
  ],
  [
    "\u7ed5",
    "go around; to wind (around)",
    "r\u00e0o",
    "rao4"
  ],
  [
    "\u7e5e",
    "\u7ed5 (go around; to wind (around))",
    "r\u00e0o",
    "rao4"
  ],
  [
    "\u6e21",
    "to cross; to pass through",
    "d\u00f9",
    "du4"
  ],
  [
    "\u6d6e",
    "to float",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u8377",
    "lotus, peppermint",
    "h\u00e9",
    "he2"
  ],
  [
    "\u4f0f",
    "conceal (ambush); prostrate",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u5238",
    "deed; bond; contract; ticket",
    "qu\u00e0n",
    "quan4"
  ],
  [
    "\u8fd4",
    "to return (to)",
    "f\u01cen",
    "fan3"
  ],
  [
    "\u5e7d",
    "quiet; secluded",
    "y\u014du",
    "you1"
  ],
  [
    "\u5c18",
    "dust; dirt; earth",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u5875",
    "\u5c18 (dust; dirt; earth)",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u7518",
    "(surname); sweet",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u6c9f",
    "ditch; gutter",
    "g\u014du",
    "gou1"
  ],
  [
    "\u6e9d",
    "\u6c9f (ditch; gutter)",
    "g\u014du",
    "gou1"
  ],
  [
    "\u73b2",
    "(used in compounds)",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u5761",
    "slope",
    "p\u014d",
    "po1"
  ],
  [
    "\u5948",
    "how can one help",
    "n\u00e0i",
    "nai4"
  ],
  [
    "\u6dfb",
    "to add; to increase",
    "ti\u0101n",
    "tian1"
  ],
  [
    "\u9093",
    "Deng (Xiaoping)",
    "d\u00e8ng",
    "deng4"
  ],
  [
    "\u9127",
    "\u9093 (Deng (Xiaoping))",
    "d\u00e8ng",
    "deng4"
  ],
  [
    "\u5f79",
    "service",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7434",
    "(mus. instr.)",
    "q\u00edn",
    "qin2"
  ],
  [
    "\u6ce5",
    "mud; paste; pulp, restrained",
    "n\u00ed",
    "ni2"
  ],
  [
    "\u5b8f",
    "spacious",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u7409",
    "precious stone",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u60d1",
    "confuse",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u8c8c",
    "appearance",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u80bf",
    "swollen",
    "zh\u01d2ng",
    "zhong3"
  ],
  [
    "\u816b",
    "\u80bf (swollen)",
    "zh\u01d2ng",
    "zhong3"
  ],
  [
    "\u77ff",
    "ore; mine",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u7926",
    "\u77ff (ore; mine)",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u7126",
    "burnt; scorched; worried; anxious",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u8f89",
    "bright; glorious",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u8f1d",
    "\u8f89 (bright; glorious)",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u80f6",
    "glue; gum",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u81a0",
    "\u80f6 (glue; gum)",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u80a0",
    "intestines",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u8178",
    "\u80a0 (intestines)",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u7fbd",
    "feather",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u8e0f",
    "step on",
    "t\u00e0",
    "ta4"
  ],
  [
    "\u6843",
    "peach",
    "t\u00e1o",
    "tao2"
  ],
  [
    "\u5fa1",
    "defend; imperial",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u7bad",
    "arrow",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u6c70",
    "excessive",
    "t\u00e0i",
    "tai4"
  ],
  [
    "\u575b",
    "altar",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u58c7",
    "\u575b (altar)",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u9676",
    "pottery, (surname); pleased",
    "t\u00e1o",
    "tao2"
  ],
  [
    "\u77db",
    "spear; lance; pike",
    "m\u00e1o",
    "mao2"
  ],
  [
    "\u98d8",
    "to float",
    "pi\u0101o",
    "piao1"
  ],
  [
    "\u98c4",
    "\u98d8 (to float)",
    "pi\u0101o",
    "piao1"
  ],
  [
    "\u62d4",
    "pull up; pull out; select",
    "b\u00e1",
    "ba2"
  ],
  [
    "\u8fc1",
    "to move; to shift",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u9077",
    "\u8fc1 (to move; to shift)",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u622a",
    "a section; cut off (a length)",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u6124",
    "indignant; anger; resentment",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u61a4",
    "\u6124 (indignant; anger; resentment)",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u5e7c",
    "young",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u9012",
    "to hand over; to pass; to give",
    "d\u00ec",
    "di4"
  ],
  [
    "\u905e",
    "\u9012 (to hand over; to pass; to give)",
    "d\u00ec",
    "di4"
  ],
  [
    "\u817e",
    "to soar; to gallop; to rise",
    "t\u00e9ng",
    "teng2"
  ],
  [
    "\u9a30",
    "\u817e (to soar; to gallop; to rise)",
    "t\u00e9ng",
    "teng2"
  ],
  [
    "\u7985",
    "meditation; abstraction",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u79aa",
    "\u7985 (meditation; abstraction)",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u6d25",
    "Tianjin; ferry",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u5b85",
    "residence",
    "zh\u00e1i",
    "zhai2"
  ],
  [
    "\u8d2f",
    "pierce; to string",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u8cab",
    "\u8d2f (pierce; to string)",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u9274",
    "example; mirror; to view",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u9451",
    "\u9274 (example; mirror; to view)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u819c",
    "(n) membrane; film",
    "m\u00f3",
    "mo2"
  ],
  [
    "\u6c57",
    "perspiration; sweat",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u8d54",
    "lose in trade; pay damage",
    "p\u00e9i",
    "pei2"
  ],
  [
    "\u8ce0",
    "\u8d54 (lose in trade; pay damage)",
    "p\u00e9i",
    "pei2"
  ],
  [
    "\u79e6",
    "(surname); name of a dynasty",
    "q\u00edn",
    "qin2"
  ],
  [
    "\u72fc",
    "wolf",
    "l\u00e1ng",
    "lang2"
  ],
  [
    "\u8f69",
    "covered carriage; pavilion",
    "xu\u0101n",
    "xuan1"
  ],
  [
    "\u8ed2",
    "\u8f69 (covered carriage; pavilion)",
    "xu\u0101n",
    "xuan1"
  ],
  [
    "\u5475",
    "(phonetic particle), expel breath",
    "\u0101",
    "a1"
  ],
  [
    "\u6fb3",
    "Australia; deep bay; cove; bay",
    "\u00e0o",
    "ao4"
  ],
  [
    "\u59ff",
    "beauty; disposition; looks",
    "z\u012b",
    "zi1"
  ],
  [
    "\u51dd",
    "congeal; concentrate attention",
    "n\u00edng",
    "ning2"
  ],
  [
    "\u8986",
    "to cover; to overflow",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u6696",
    "warm",
    "nu\u01cen",
    "nuan3"
  ],
  [
    "\u5d07",
    "high; dignified; lofty; to honor",
    "ch\u00f3ng",
    "chong2"
  ],
  [
    "\u5ca9",
    "cliff; rock, cliff",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u5dd6",
    "\u5ca9 (cliff; rock, cliff)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u4fca",
    "smart; eminent; handsome",
    "j\u00f9n",
    "jun4"
  ],
  [
    "\u5974",
    "slave",
    "n\u00fa",
    "nu2"
  ],
  [
    "\u6052",
    "permanent",
    "h\u00e9ng",
    "heng2"
  ],
  [
    "\u6046",
    "\u6052 (permanent)",
    "h\u00e9ng",
    "heng2"
  ],
  [
    "\u8df5",
    "fulfill (a promise); tread; walk",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u8e10",
    "\u8df5 (fulfill (a promise); tread; walk)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u5bfa",
    "Buddhist temple",
    "s\u00ec",
    "si4"
  ],
  [
    "\u7cae",
    "provisions",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u7ce7",
    "\u7cae (provisions)",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u6377",
    "victory; triumph; quick; prompt",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u81c2",
    "arm",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5564",
    "beer",
    "p\u00ed",
    "pi2"
  ],
  [
    "\u5b5f",
    "first month; eldest brother",
    "m\u00e8ng",
    "meng4"
  ],
  [
    "\u96fe",
    "fog; mist",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u9727",
    "\u96fe (fog; mist)",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u62ef",
    "to help; to save",
    "zh\u011bng",
    "zheng3"
  ],
  [
    "\u6276",
    "to support with hand",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u51c9",
    "cool; cold",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u6dbc",
    "\u51c9 (cool; cold)",
    "li\u00e1ng",
    "liang2"
  ],
  [
    "\u5782",
    "to hang (down); droop; dangle",
    "chu\u00ed",
    "chui2"
  ],
  [
    "\u96d5",
    "engrave, engrave",
    "di\u0101o",
    "diao1"
  ],
  [
    "\u6084",
    "quiet; sad",
    "qi\u0101o",
    "qiao1"
  ],
  [
    "\u8a89",
    "reputation",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u8b7d",
    "\u8a89 (reputation)",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u5bf8",
    "a unit of length; inch; thumb",
    "c\u00f9n",
    "cun4"
  ],
  [
    "\u6251",
    "to rush at",
    "p\u016b",
    "pu1"
  ],
  [
    "\u64b2",
    "\u6251 (to rush at)",
    "p\u016b",
    "pu1"
  ],
  [
    "\u5de1",
    "to patrol; to make one's rounds",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u6d8c",
    "bubble up; rush forth",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u6e67",
    "\u6d8c (bubble up; rush forth)",
    "y\u01d2ng",
    "yong3"
  ],
  [
    "\u83cc",
    "germ; bacteria, bacteria; mold",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u8179",
    "abdomen; stomach; belly",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u77ac",
    "to wink",
    "sh\u00f9n",
    "shun4"
  ],
  [
    "\u6ecb",
    "excite; nourish; this",
    "z\u012b",
    "zi1"
  ],
  [
    "\u8000",
    "brilliant; glorious",
    "y\u00e0o",
    "yao4"
  ],
  [
    "\u9887",
    "quite; rather; uneven; sloping",
    "p\u014d",
    "po1"
  ],
  [
    "\u9817",
    "\u9887 (quite; rather; uneven; sloping)",
    "p\u014d",
    "po1"
  ],
  [
    "\u73bb",
    "glass",
    "b\u014d",
    "bo1"
  ],
  [
    "\u5c09",
    "officer",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u809d",
    "liver",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u903b",
    "logic; patrol",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u908f",
    "\u903b (logic; patrol)",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u8d60",
    "give present",
    "z\u00e8ng",
    "zeng4"
  ],
  [
    "\u8d08",
    "\u8d60 (give present)",
    "z\u00e8ng",
    "zeng4"
  ],
  [
    "\u5faa",
    "to follow; to adhere to",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u8f85",
    "to assist; to complement",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u8f14",
    "\u8f85 (to assist; to complement)",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u77f6",
    "jetty",
    "j\u012b",
    "ji1"
  ],
  [
    "\u78ef",
    "\u77f6 (jetty)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u607c",
    "get mad",
    "n\u01ceo",
    "nao3"
  ],
  [
    "\u60f1",
    "\u607c (get mad)",
    "n\u01ceo",
    "nao3"
  ],
  [
    "\u52e4",
    "diligent; frequent",
    "q\u00edn",
    "qin2"
  ],
  [
    "\u63ed",
    "lift off (a cover); divulge",
    "ji\u0113",
    "jie1"
  ],
  [
    "\u8d21",
    "tribute; gifts",
    "g\u00f2ng",
    "gong4"
  ],
  [
    "\u8ca2",
    "\u8d21 (tribute; gifts)",
    "g\u00f2ng",
    "gong4"
  ],
  [
    "\u85c9",
    "in disorder, by means of",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u71c3",
    "burn; combustion",
    "r\u00e1n",
    "ran2"
  ],
  [
    "\u55b7",
    "to puff; to spout; to spray",
    "p\u0113n",
    "pen1"
  ],
  [
    "\u5674",
    "\u55b7 (to puff; to spout; to spray)",
    "p\u0113n",
    "pen1"
  ],
  [
    "\u6bbf",
    "palace hall",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u6c27",
    "oxygen",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u83e9",
    "Bodhisattva",
    "p\u00fa",
    "pu2"
  ],
  [
    "\u5951",
    "contract",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u8dcc",
    "to drop; to fall; to tumble",
    "di\u0113",
    "die1"
  ],
  [
    "\u7275",
    "lead along",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u727d",
    "\u7275 (lead along)",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u7965",
    "auspicious; propitious",
    "xi\u00e1ng",
    "xiang2"
  ],
  [
    "\u6170",
    "reassure",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u4e32",
    "to string together; to mix up",
    "chu\u00e0n",
    "chuan4"
  ],
  [
    "\u7b79",
    "a tally; counter; ticket; plan",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u7c4c",
    "\u7b79 (a tally; counter; ticket; plan)",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u6da8",
    "to rise (of prices, rivers), to swell",
    "zh\u01ceng",
    "zhang3"
  ],
  [
    "\u6f32",
    "\u6da8 (to rise (of prices, rivers), to swell)",
    "zh\u01ceng",
    "zhang3"
  ],
  [
    "\u950b",
    "point or edge of a tool",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u92d2",
    "\u950b (point or edge of a tool)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u6735",
    "M for flowers",
    "du\u01d2",
    "duo3"
  ],
  [
    "\u9526",
    "brocade; embroidered work",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u9326",
    "\u9526 (brocade; embroidered work)",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u7a3f",
    "manuscript; draft",
    "g\u01ceo",
    "gao3"
  ],
  [
    "\u660f",
    "muddle-headed; twilight",
    "h\u016bn",
    "hun1"
  ],
  [
    "\u67f1",
    "pillar",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u6d45",
    "sound of moving water, shallow",
    "qi\u01cen",
    "qian3"
  ],
  [
    "\u6dfa",
    "\u6d45 (sound of moving water, shallow)",
    "qi\u01cen",
    "qian3"
  ],
  [
    "\u78c1",
    "magnetic; magnetism; porcelain",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u679d",
    "branch; (a mw.)",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u8896",
    "sleeve",
    "xi\u00f9",
    "xiu4"
  ],
  [
    "\u62e8",
    "to push aside",
    "b\u014d",
    "bo1"
  ],
  [
    "\u64a5",
    "\u62e8 (to push aside)",
    "b\u014d",
    "bo1"
  ],
  [
    "\u626d",
    "to turn; to twist; to grab",
    "ni\u01d4",
    "niu3"
  ],
  [
    "\u5618",
    "to exhale",
    "x\u016b",
    "xu1"
  ],
  [
    "\u5653",
    "\u5618 (to exhale)",
    "x\u016b",
    "xu1"
  ],
  [
    "\u66f9",
    "a company; a class; a generation",
    "c\u00e1o",
    "cao2"
  ],
  [
    "\u660c",
    "prosperous; flourish",
    "ch\u0101ng",
    "chang1"
  ],
  [
    "\u70bc",
    "refine; smelt, refine",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u7149",
    "\u70bc (refine; smelt, refine)",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u62bc",
    "detain in custody",
    "y\u0101",
    "ya1"
  ],
  [
    "\u6350",
    "to contribute; to donate; tax",
    "ju\u0101n",
    "juan1"
  ],
  [
    "\u6324",
    "crowded; to squeeze",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u64e0",
    "\u6324 (crowded; to squeeze)",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u586b",
    "to fill in",
    "ti\u00e1n",
    "tian2"
  ],
  [
    "\u6208",
    "(surname); spear",
    "g\u0113",
    "ge1"
  ],
  [
    "\u4e95",
    "warn; well",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u94fa",
    "to spread, a store, a bed",
    "p\u00f9",
    "pu4"
  ],
  [
    "\u92ea",
    "\u94fa (to spread, a store, a bed)",
    "p\u00f9",
    "pu4"
  ],
  [
    "\u9075",
    "to observe; to obey; to follow",
    "z\u016bn",
    "zun1"
  ],
  [
    "\u4e8f",
    "deficiency; deficit",
    "ku\u012b",
    "kui1"
  ],
  [
    "\u8667",
    "\u4e8f (deficiency; deficit)",
    "ku\u012b",
    "kui1"
  ],
  [
    "\u5939",
    "clip; folder; hold between",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u593e",
    "\u5939 (clip; folder; hold between)",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u8270",
    "difficult; hard; hardship",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u8271",
    "\u8270 (difficult; hard; hardship)",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u8d64",
    "red; scarlet; bare; naked",
    "ch\u00ec",
    "chi4"
  ],
  [
    "\u5f2f",
    "bend; bent",
    "w\u0101n",
    "wan1"
  ],
  [
    "\u5f4e",
    "\u5f2f (bend; bent)",
    "w\u0101n",
    "wan1"
  ],
  [
    "\u7c92",
    "a grain; a granule",
    "l\u00ec",
    "li4"
  ],
  [
    "\u8083",
    "Gansu; respectful",
    "s\u00f9",
    "su4"
  ],
  [
    "\u8085",
    "\u8083 (Gansu; respectful)",
    "s\u00f9",
    "su4"
  ],
  [
    "\u582a",
    "endure",
    "k\u0101n",
    "kan1"
  ],
  [
    "\u5272",
    "cut off",
    "g\u0113",
    "ge1"
  ],
  [
    "\u8d2a",
    "greedy",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u8caa",
    "\u8d2a (greedy)",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u7ea4",
    "boatman's tow-rope, fine",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u7e96",
    "\u7ea4 (boatman's tow-rope, fine)",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u65e8",
    "imperial decree; purport; aim",
    "zh\u01d0",
    "zhi3"
  ],
  [
    "\u4f2a",
    "false; fake; forged; bogus",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u50de",
    "\u4f2a (false; fake; forged; bogus)",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u8870",
    "mourning garments, weak; feeble",
    "shu\u0101i",
    "shuai1"
  ],
  [
    "\u9a76",
    "hasten; proceed to",
    "sh\u01d0",
    "shi3"
  ],
  [
    "\u99db",
    "\u9a76 (hasten; proceed to)",
    "sh\u01d0",
    "shi3"
  ],
  [
    "\u94fe",
    "chain; lead or tin ore",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u93c8",
    "\u94fe (chain; lead or tin ore)",
    "li\u00e0n",
    "lian4"
  ],
  [
    "\u529d",
    "to advise; to urge",
    "qu\u00e0n",
    "quan4"
  ],
  [
    "\u52f8",
    "\u529d (to advise; to urge)",
    "qu\u00e0n",
    "quan4"
  ],
  [
    "\u7624",
    "tumor",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u9881",
    "promulgate; send out; issue",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u9812",
    "\u9881 (promulgate; send out; issue)",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u6b96",
    "grow; reproduce",
    "zh\u00ed",
    "zhi2"
  ],
  [
    "\u97e6",
    "(surname); soft leather",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u97cb",
    "\u97e6 ((surname); soft leather)",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u9065",
    "distant; remote; far; far away",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u9059",
    "\u9065 (distant; remote; far; far away)",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u82ac",
    "fragrant",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u82af",
    "lamp pith; core",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u6c1b",
    "miasma; vapor",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u67cf",
    "(surname); cedar",
    "b\u01cei",
    "bai3"
  ],
  [
    "\u8d37",
    "lend on interest; borrow; loan",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u8cb8",
    "\u8d37 (lend on interest; borrow; loan)",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u5ef7",
    "palace courtyard",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u6291",
    "to restrain; to restrict",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8bf1",
    "entice; tempt",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u8a98",
    "\u8bf1 (entice; tempt)",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u614c",
    "nervous",
    "hu\u0101ng",
    "huang1"
  ],
  [
    "\u6eaa",
    "creek, creek",
    "x\u012b",
    "xi1"
  ],
  [
    "\u67dc",
    "cupboard; cabinet",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u6ac3",
    "\u67dc (cupboard; cabinet)",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u8d24",
    "worthy (person)",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u8ce2",
    "\u8d24 (worthy (person))",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u94c3",
    "(small) bell",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u9234",
    "\u94c3 ((small) bell)",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u5b99",
    "universe",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u9e23",
    "to cry (of birds)",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u9cf4",
    "\u9e23 (to cry (of birds))",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u9971",
    "to eat till full; satisfied",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u98fd",
    "\u9971 (to eat till full; satisfied)",
    "b\u01ceo",
    "bao3"
  ],
  [
    "\u809a",
    "tripe, belly",
    "d\u00f9",
    "du4"
  ],
  [
    "\u5e9e",
    "(surname); huge; enormous",
    "p\u00e1ng",
    "pang2"
  ],
  [
    "\u9f90",
    "\u5e9e ((surname); huge; enormous)",
    "p\u00e1ng",
    "pang2"
  ],
  [
    "\u52c3",
    "flourishing; prosperous",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u95f7",
    "smother; stuffy, melancholy",
    "m\u00e8n",
    "men4"
  ],
  [
    "\u60b6",
    "\u95f7 (smother; stuffy, melancholy)",
    "m\u00e8n",
    "men4"
  ],
  [
    "\u5306",
    "hurried; hasty",
    "c\u014dng",
    "cong1"
  ],
  [
    "\u7ea0",
    "gather together; to investigate",
    "ji\u016b",
    "jiu1"
  ],
  [
    "\u7cfe",
    "\u7ea0 (gather together; to investigate)",
    "ji\u016b",
    "jiu1"
  ],
  [
    "\u5ec9",
    "incorrupt; inexpensive",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u8fa8",
    "distinguish; recognize",
    "bi\u00e0n",
    "bian4"
  ],
  [
    "\u5f6d",
    "(surname)",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u5524",
    "to call",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u559a",
    "\u5524 (to call)",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u5f25",
    "full; to fill, overflowing",
    "m\u00ed",
    "mi2"
  ],
  [
    "\u5f4c",
    "\u5f25 (full; to fill, overflowing)",
    "m\u00ed",
    "mi2"
  ],
  [
    "\u7ed8",
    "to draw; to paint",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u7e6a",
    "\u7ed8 (to draw; to paint)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u76b1",
    "to wrinkle; wrinkled; to crease",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u76ba",
    "\u76b1 (to wrinkle; wrinkled; to crease)",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u86c7",
    "snake; serpent",
    "sh\u00e9",
    "she2"
  ],
  [
    "\u6c88",
    "sink, (surname); place name",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u9614",
    "rich; wide; broad",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u95ca",
    "\u9614 (rich; wide; broad)",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u9510",
    "acute",
    "ru\u00ec",
    "rui4"
  ],
  [
    "\u92b3",
    "\u9510 (acute)",
    "ru\u00ec",
    "rui4"
  ],
  [
    "\u67f3",
    "willow; Liu (a surname)",
    "li\u01d4",
    "liu3"
  ],
  [
    "\u62c6",
    "to tear open; to tear down",
    "ch\u0101i",
    "chai1"
  ],
  [
    "\u659c",
    "slanting",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u5c48",
    "bent; feel wronged",
    "q\u016b",
    "qu1"
  ],
  [
    "\u8350",
    "recommend (a person)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u85a6",
    "\u8350 (recommend (a person))",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u614e",
    "cautious",
    "sh\u00e8n",
    "shen4"
  ],
  [
    "\u82cd",
    "dark blue; fly; musca; deep green",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u84bc",
    "\u82cd (dark blue; fly; musca; deep green)",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u7267",
    "shepherd",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u67ab",
    "maple",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u6953",
    "\u67ab (maple)",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u8273",
    "glamorous",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u8c54",
    "\u8273 (glamorous)",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u76c8",
    "full; filled; surplus",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u6ef4",
    "a drop; to drip",
    "d\u012b",
    "di1"
  ],
  [
    "\u5e99",
    "temple; monastery",
    "mi\u00e0o",
    "miao4"
  ],
  [
    "\u5edf",
    "\u5e99 (temple; monastery)",
    "mi\u00e0o",
    "miao4"
  ],
  [
    "\u8f96",
    "have jurisdiction over",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u8f44",
    "\u8f96 (have jurisdiction over)",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u8247",
    "small boat",
    "t\u01d0ng",
    "ting3"
  ],
  [
    "\u7272",
    "domestic animal",
    "sh\u0113ng",
    "sheng1"
  ],
  [
    "\u7545",
    "smooth; fluent; joyful; happy",
    "ch\u00e0ng",
    "chang4"
  ],
  [
    "\u66a2",
    "\u7545 (smooth; fluent; joyful; happy)",
    "ch\u00e0ng",
    "chang4"
  ],
  [
    "\u8427",
    "mournful",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u856d",
    "\u8427 (mournful)",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u6f0f",
    "funnel; to leak; to let out",
    "l\u00f2u",
    "lou4"
  ],
  [
    "\u9640",
    "(phonetic); declivity",
    "tu\u00f3",
    "tuo2"
  ],
  [
    "\u60a6",
    "pleased",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u6085",
    "\u60a6 (pleased)",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u54fc",
    "hum, (interj. of contempt)",
    "h\u0113ng",
    "heng1"
  ],
  [
    "\u9f7f",
    "tooth",
    "ch\u01d0",
    "chi3"
  ],
  [
    "\u9f52",
    "\u9f7f (tooth)",
    "ch\u01d0",
    "chi3"
  ],
  [
    "\u6643",
    "dazzle, sway; to shade",
    "hu\u01ceng",
    "huang3"
  ],
  [
    "\u8d4b",
    "poetic essay; taxation",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u8ce6",
    "\u8d4b (poetic essay; taxation)",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u5353",
    "(surname); outstanding",
    "zhu\u00f3",
    "zhuo2"
  ],
  [
    "\u7a74",
    "hole; cave",
    "xu\u00e9",
    "xue2"
  ],
  [
    "\u8180",
    "upper arm",
    "b\u01ceng",
    "bang3"
  ],
  [
    "\u75b2",
    "weary",
    "p\u00ed",
    "pi2"
  ],
  [
    "\u664b",
    "name of a dynasty",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u6649",
    "\u664b (name of a dynasty)",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u80aa",
    "animal fat",
    "f\u00e1ng",
    "fang2"
  ],
  [
    "\u4e7e",
    "dry, (surname); male; strong",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u7384",
    "black; mysterious",
    "xu\u00e1n",
    "xuan2"
  ],
  [
    "\u7f1d",
    "to sew; to stitch, crack; seam",
    "f\u00e8ng",
    "feng4"
  ],
  [
    "\u7e2b",
    "\u7f1d (to sew; to stitch, crack; seam)",
    "f\u00e8ng",
    "feng4"
  ],
  [
    "\u7a00",
    "diluted; sparse",
    "x\u012b",
    "xi1"
  ],
  [
    "\u54c0",
    "sorrow; grief; pity",
    "\u0101i",
    "ai1"
  ],
  [
    "\u6db5",
    "contain",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u6296",
    "shake out; tremble",
    "d\u01d2u",
    "dou3"
  ],
  [
    "\u4e59",
    "2nd heavenly stem",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u9e3f",
    "eastern bean goose; great; large",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u9d3b",
    "\u9e3f (eastern bean goose; great; large)",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u7626",
    "tight; thin; lean",
    "sh\u00f2u",
    "shou4"
  ],
  [
    "\u8f68",
    "course; path; track; rail",
    "gu\u01d0",
    "gui3"
  ],
  [
    "\u8ecc",
    "\u8f68 (course; path; track; rail)",
    "gu\u01d0",
    "gui3"
  ],
  [
    "\u8521",
    "(surname)",
    "c\u00e0i",
    "cai4"
  ],
  [
    "\u76d0",
    "salt",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u9e7d",
    "\u76d0 (salt)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u643a",
    "to carry; to take along",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u651c",
    "\u643a (to carry; to take along)",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u5996",
    "goblin; witch; devil; bewitching",
    "y\u0101o",
    "yao1"
  ],
  [
    "\u5507",
    "lip",
    "ch\u00fan",
    "chun2"
  ],
  [
    "\u8123",
    "\u5507 (lip)",
    "ch\u00fan",
    "chun2"
  ],
  [
    "\u8154",
    "cavity of body; tune",
    "qi\u0101ng",
    "qiang1"
  ],
  [
    "\u4e1b",
    "cluster; collection",
    "c\u00f3ng",
    "cong2"
  ],
  [
    "\u53e2",
    "\u4e1b (cluster; collection)",
    "c\u00f3ng",
    "cong2"
  ],
  [
    "\u79e9",
    "order; orderliness",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u6566",
    "kind-hearted, kind-hearted",
    "d\u016bn",
    "dun1"
  ],
  [
    "\u59a5",
    "secure; sound",
    "tu\u01d2",
    "tuo3"
  ],
  [
    "\u9006",
    "contrary; opposite; backwards",
    "n\u00ec",
    "ni4"
  ],
  [
    "\u7978",
    "disaster",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u798d",
    "\u7978 (disaster)",
    "hu\u00f2",
    "huo4"
  ],
  [
    "\u90c1",
    "(surname)",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u9b31",
    "\u90c1 ((surname))",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u62d8",
    "adhere; capture; restrain",
    "j\u016b",
    "ju1"
  ],
  [
    "\u524a",
    "to scrape, to reduce",
    "xu\u0113",
    "xue1"
  ],
  [
    "\u80c3",
    "stomach",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u8f74",
    "axis; axle, axis; axle",
    "zh\u00f3u",
    "zhou2"
  ],
  [
    "\u8ef8",
    "\u8f74 (axis; axle, axis; axle)",
    "zh\u00f3u",
    "zhou2"
  ],
  [
    "\u75ab",
    "epidemic; plague",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u9738",
    "feudal chief; rule by force",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u629a",
    "to comfort",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u64ab",
    "\u629a (to comfort)",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u60df",
    "-ism; only",
    "w\u00e9i",
    "wei2"
  ],
  [
    "\u8d74",
    "go towards; go to",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u7f69",
    "cover; fish trap (basket); shade",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u5a4a",
    "prostitute",
    "bi\u01ceo",
    "biao3"
  ],
  [
    "\u796d",
    "offer sacrifice, (surname)",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u85aa",
    "fuel; salary",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u94dc",
    "copper",
    "t\u00f3ng",
    "tong2"
  ],
  [
    "\u9285",
    "\u94dc (copper)",
    "t\u00f3ng",
    "tong2"
  ],
  [
    "\u60a0",
    "at ease; long (in time); sad",
    "y\u014du",
    "you1"
  ],
  [
    "\u5ce1",
    "gorge",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u5cfd",
    "\u5ce1 (gorge)",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u541e",
    "to swallow; to take",
    "t\u016bn",
    "tun1"
  ],
  [
    "\u814a",
    "preserved (meat), December",
    "l\u00e0",
    "la4"
  ],
  [
    "\u81d8",
    "\u814a (preserved (meat), December)",
    "l\u00e0",
    "la4"
  ],
  [
    "\u6d69",
    "grand; vast (water)",
    "h\u00e0o",
    "hao4"
  ],
  [
    "\u9888",
    "neck",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u9838",
    "\u9888 (neck)",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u53d9",
    "narrate; abbreviation for Syria",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u6558",
    "\u53d9 (narrate; abbreviation for Syria)",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u8bb6",
    "astounded",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u8a1d",
    "\u8bb6 (astounded)",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u65a5",
    "blame; reprove; reprimand",
    "ch\u00ec",
    "chi4"
  ],
  [
    "\u5428",
    "ton, ton",
    "d\u016bn",
    "dun1"
  ],
  [
    "\u5678",
    "\u5428 (ton, ton)",
    "d\u016bn",
    "dun1"
  ],
  [
    "\u5bc2",
    "lonesome",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u4ed3",
    "barn; granary; storehouse; cabin",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u5009",
    "\u4ed3 (barn; granary; storehouse; cabin)",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u78a7",
    "green jade; bluish green; blue",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5401",
    "sh; hush, implore",
    "x\u016b",
    "xu1"
  ],
  [
    "\u7c72",
    "\u5401 (sh; hush, implore)",
    "x\u016b",
    "xu1"
  ],
  [
    "\u5265",
    "peel; to skin",
    "b\u014d",
    "bo1"
  ],
  [
    "\u525d",
    "\u5265 (peel; to skin)",
    "b\u014d",
    "bo1"
  ],
  [
    "\u5fcc",
    "avoid as taboo; jealous",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u6491",
    "support; prop-up; to pole a boat",
    "ch\u0113ng",
    "cheng1"
  ],
  [
    "\u6490",
    "\u6491 (support; prop-up; to pole a boat)",
    "ch\u0113ng",
    "cheng1"
  ],
  [
    "\u94ed",
    "inscribed motto",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u9298",
    "\u94ed (inscribed motto)",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u8106",
    "crisp; brittle",
    "cu\u00ec",
    "cui4"
  ],
  [
    "\u971c",
    "frost",
    "shu\u0101ng",
    "shuang1"
  ],
  [
    "\u541f",
    "moan; to hum",
    "y\u00edn",
    "yin2"
  ],
  [
    "\u5f70",
    "manifest",
    "zh\u0101ng",
    "zhang1"
  ],
  [
    "\u6d51",
    "muddy",
    "h\u00fan",
    "hun2"
  ],
  [
    "\u6e3e",
    "\u6d51 (muddy)",
    "h\u00fan",
    "hun2"
  ],
  [
    "\u65a4",
    "catty; weight equal to 0.5 kg",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u8d81",
    "avail oneself of",
    "ch\u00e8n",
    "chen4"
  ],
  [
    "\u5362",
    "(surname)",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u76e7",
    "\u5362 ((surname))",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u5c82",
    "how can it be that",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u8c48",
    "\u5c82 (how can it be that)",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u80ba",
    "lung",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u5c97",
    "mound; policeman's beat",
    "g\u01ceng",
    "gang3"
  ],
  [
    "\u5d17",
    "\u5c97 (mound; policeman's beat)",
    "g\u01ceng",
    "gang3"
  ],
  [
    "\u6602",
    "high; raise (head), high",
    "\u00e1ng",
    "ang2"
  ],
  [
    "\u51bb",
    "to freeze",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u51cd",
    "\u51bb (to freeze)",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u6746",
    "pole, measure for guns, stick",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u6155",
    "admire",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u543e",
    "I; my",
    "w\u00fa",
    "wu2"
  ],
  [
    "\u82d7",
    "(surname); Miao tribe; sprout",
    "mi\u00e1o",
    "miao2"
  ],
  [
    "\u8bbc",
    "litigation",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u8a1f",
    "\u8bbc (litigation)",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u9601",
    "council-chamber; shelf",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u95a3",
    "\u9601 (council-chamber; shelf)",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u8881",
    "(surname)",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u8c28",
    "cautious",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u8b39",
    "\u8c28 (cautious)",
    "j\u01d0n",
    "jin3"
  ],
  [
    "\u6f20",
    "desert; unconcerned",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u5a31",
    "amuse",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u5a1b",
    "\u5a31 (amuse)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u5c65",
    "shoe; to tread on",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u820c",
    "tongue",
    "sh\u00e9",
    "she2"
  ],
  [
    "\u6e14",
    "fisherman; to fish",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u6f01",
    "\u6e14 (fisherman; to fish)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u4faf",
    "marquis",
    "h\u00f3u",
    "hou2"
  ],
  [
    "\u52a3",
    "inferior",
    "li\u00e8",
    "lie4"
  ],
  [
    "\u7720",
    "sleep",
    "mi\u00e1n",
    "mian2"
  ],
  [
    "\u4f10",
    "(v) cut down; fell",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u7a46",
    "(surname); solemn",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u77aa",
    "stare at",
    "d\u00e8ng",
    "deng4"
  ],
  [
    "\u4ef2",
    "2nd in seniority",
    "zh\u00f2ng",
    "zhong4"
  ],
  [
    "\u5733",
    "furrow in field, small drainage",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u98a4",
    "to tremble; to shiver; to shake",
    "ch\u00e0n",
    "chan4"
  ],
  [
    "\u986b",
    "\u98a4 (to tremble; to shiver; to shake)",
    "ch\u00e0n",
    "chan4"
  ],
  [
    "\u6eb6",
    "dissolve",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u5deb",
    "witch",
    "w\u016b",
    "wu1"
  ],
  [
    "\u5a07",
    "lovable; pampered; tender",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u5b0c",
    "\u5a07 (lovable; pampered; tender)",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u7cb9",
    "pure; unmixed; essence",
    "cu\u00ec",
    "cui4"
  ],
  [
    "\u6349",
    "to clutch; to grab; to capture",
    "zhu\u014d",
    "zhuo1"
  ],
  [
    "\u6cc4",
    "divulge; leak out",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u6606",
    "Kunlun mountains, descendant",
    "k\u016bn",
    "kun1"
  ],
  [
    "\u742a",
    "(white gem); angel",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u7089",
    "stove",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u7210",
    "\u7089 (stove)",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u6323",
    "struggle, to earn",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u6399",
    "\u6323 (struggle, to earn)",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u52c9",
    "exhort",
    "mi\u01cen",
    "mian3"
  ],
  [
    "\u51fd",
    "envelope; case; letter",
    "h\u00e1n",
    "han2"
  ],
  [
    "\u8292",
    "Miscanthus sinensis",
    "m\u00e1ng",
    "mang2"
  ],
  [
    "\u8fb1",
    "disgrace; insult",
    "r\u01d4",
    "ru3"
  ],
  [
    "\u62b9",
    "play; to smear, girdle",
    "m\u01d2",
    "mo3"
  ],
  [
    "\u644a",
    "spread out; vendor's stand",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u6524",
    "\u644a (spread out; vendor's stand)",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u758f",
    "negligent; sparse; thin",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u6591",
    "variegated",
    "b\u0101n",
    "ban1"
  ],
  [
    "\u62d3",
    "make rubbing, expand",
    "t\u00e0",
    "ta4"
  ],
  [
    "\u8702",
    "bee; wasp",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u6241",
    "flat; tablet",
    "bi\u01cen",
    "bian3"
  ],
  [
    "\u4f8d",
    "attend on",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u62ab",
    "scatter; separate; to open",
    "p\u012b",
    "pi1"
  ],
  [
    "\u67ef",
    "(surname); handle of axe; stem",
    "k\u0113",
    "ke1"
  ],
  [
    "\u8015",
    "to plow; to till",
    "g\u0113ng",
    "geng1"
  ],
  [
    "\u59a8",
    "hinder; harm",
    "f\u00e1ng",
    "fang2"
  ],
  [
    "\u8258",
    "mw. for boats and ships",
    "s\u014du",
    "sou1"
  ],
  [
    "\u88d5",
    "abundant",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u50e7",
    "monk",
    "s\u0113ng",
    "seng1"
  ],
  [
    "\u52fe",
    "to cancel; to delineate",
    "g\u014du",
    "gou1"
  ],
  [
    "\u6e83",
    "be dispersed; break down",
    "ku\u00ec",
    "kui4"
  ],
  [
    "\u6f70",
    "\u6e83 (be dispersed; break down)",
    "ku\u00ec",
    "kui4"
  ],
  [
    "\u9b4f",
    "(surname); name of a dynasty",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u5085",
    "(surname); tutor",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u5bb4",
    "feast; repose",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u679a",
    "M for small objects",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u98a0",
    "inverted; jolt; top",
    "di\u0101n",
    "dian1"
  ],
  [
    "\u985b",
    "\u98a0 (inverted; jolt; top)",
    "di\u0101n",
    "dian1"
  ],
  [
    "\u6dcb",
    "diseases of the bladder",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u5915",
    "evening, dusk",
    "x\u012b",
    "xi1"
  ],
  [
    "\u66f0",
    "to speak; to say",
    "yu\u0113",
    "yue1"
  ],
  [
    "\u6bc5",
    "perseverance",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u65fa",
    "prosperous; flourishing",
    "w\u00e0ng",
    "wang4"
  ],
  [
    "\u5a77",
    "graceful",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u58f3",
    "shell, shell",
    "k\u00e9",
    "ke2"
  ],
  [
    "\u6bbc",
    "\u58f3 (shell, shell)",
    "k\u00e9",
    "ke2"
  ],
  [
    "\u50ac",
    "urge; press; expedite; prompt",
    "cu\u012b",
    "cui1"
  ],
  [
    "\u5978",
    "traitor, adultery",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u727a",
    "sacrifice",
    "x\u012b",
    "xi1"
  ],
  [
    "\u72a7",
    "\u727a (sacrifice)",
    "x\u012b",
    "xi1"
  ],
  [
    "\u7b3c",
    "basket; cage",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u7c60",
    "\u7b3c (basket; cage)",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u6842",
    "Cinnamonum cassia",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u9e7f",
    "deer",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u60ac",
    "hang; suspend",
    "xu\u00e1n",
    "xuan2"
  ],
  [
    "\u61f8",
    "\u60ac (hang; suspend)",
    "xu\u00e1n",
    "xuan2"
  ],
  [
    "\u7f20",
    "to bother; wind around",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u7e8f",
    "\u7f20 (to bother; wind around)",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u4ed7",
    "battle",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u5938",
    "to boast",
    "ku\u0101",
    "kua1"
  ],
  [
    "\u8a87",
    "\u5938 (to boast)",
    "ku\u0101",
    "kua1"
  ],
  [
    "\u8d3c",
    "thief",
    "z\u00e9i",
    "zei2"
  ],
  [
    "\u8cca",
    "\u8d3c (thief)",
    "z\u00e9i",
    "zei2"
  ],
  [
    "\u6247",
    "to fan, (mw. for doors, windows)",
    "sh\u00e0n",
    "shan4"
  ],
  [
    "\u51af",
    "surname; placename",
    "f\u00e9ng",
    "feng2"
  ],
  [
    "\u99ae",
    "\u51af (surname; placename)",
    "f\u00e9ng",
    "feng2"
  ],
  [
    "\u8d26",
    "account",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u8cec",
    "\u8d26 (account)",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u83f1",
    "Trapa natans; water caltrop",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u8096",
    "similar; resembling; to resemble",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u56ca",
    "sack",
    "n\u00e1ng",
    "nang2"
  ],
  [
    "\u6d9b",
    "big wave",
    "t\u0101o",
    "tao1"
  ],
  [
    "\u6fe4",
    "\u6d9b (big wave)",
    "t\u0101o",
    "tao1"
  ],
  [
    "\u9022",
    "every time; meet by chance",
    "f\u00e9ng",
    "feng2"
  ],
  [
    "\u704c",
    "irrigate; pour",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u80a2",
    "limb",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u88f8",
    "naked",
    "lu\u01d2",
    "luo3"
  ],
  [
    "\u9505",
    "pot; pan; boiler",
    "gu\u014d",
    "guo1"
  ],
  [
    "\u934b",
    "\u9505 (pot; pan; boiler)",
    "gu\u014d",
    "guo1"
  ],
  [
    "\u86ee",
    "barbarian; bullying; very; quite",
    "m\u00e1n",
    "man2"
  ],
  [
    "\u883b",
    "\u86ee (barbarian; bullying; very; quite)",
    "m\u00e1n",
    "man2"
  ],
  [
    "\u6e34",
    "thirsty",
    "k\u011b",
    "ke3"
  ],
  [
    "\u680f",
    "fence; railing; hurdle",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u6b04",
    "\u680f (fence; railing; hurdle)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u7a3d",
    "inspect",
    "j\u012b",
    "ji1"
  ],
  [
    "\u60e9",
    "punish; discipline",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u61f2",
    "\u60e9 (punish; discipline)",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u7897",
    "bowl; cup",
    "w\u01cen",
    "wan3"
  ],
  [
    "\u7fd4",
    "soar",
    "xi\u00e1ng",
    "xiang2"
  ],
  [
    "\u8d29",
    "deal in; trade in; to peddle",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u8ca9",
    "\u8d29 (deal in; trade in; to peddle)",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u9e4f",
    "large fabulous bird",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u9d6c",
    "\u9e4f (large fabulous bird)",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u9063",
    "dispatch",
    "qi\u01cen",
    "qian3"
  ],
  [
    "\u6846",
    "frame; door frame",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u9675",
    "mound; tomb; hill; mountain",
    "l\u00edng",
    "ling2"
  ],
  [
    "\u5598",
    "to gasp; to pant; asthma",
    "chu\u01cen",
    "chuan3"
  ],
  [
    "\u9aa4",
    "sudden; suddenly",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u9a5f",
    "\u9aa4 (sudden; suddenly)",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u7741",
    "to open (eye)",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u775c",
    "\u7741 (to open (eye))",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u540a",
    "to hang a person, condole with",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u7164",
    "coal",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u6f06",
    "paint; lacquer",
    "q\u012b",
    "qi1"
  ],
  [
    "\u5cb3",
    "(surname); mountain",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u5dbd",
    "\u5cb3 ((surname); mountain)",
    "yu\u00e8",
    "yue4"
  ],
  [
    "\u5984",
    "absurd; fantastic",
    "w\u00e0ng",
    "wang4"
  ],
  [
    "\u5a74",
    "infant; baby",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u5b30",
    "\u5a74 (infant; baby)",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u76fc",
    "to hope for; to long for",
    "p\u00e0n",
    "pan4"
  ],
  [
    "\u543c",
    "roar (of a lion)",
    "h\u01d2u",
    "hou3"
  ],
  [
    "\u4e18",
    "Confucius; given name",
    "qi\u016b",
    "qiu1"
  ],
  [
    "\u754f",
    "to fear",
    "w\u00e8i",
    "wei4"
  ],
  [
    "\u901d",
    "depart; die",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u84c4",
    "to store",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u906e",
    "cover up (a shortcoming)",
    "zh\u0113",
    "zhe1"
  ],
  [
    "\u535c",
    "turnip, to divine; foretell",
    "bo",
    "bo"
  ],
  [
    "\u8c6b",
    "beforehand; prepare",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u5509",
    "an interjection",
    "\u0101i",
    "ai1"
  ],
  [
    "\u76f2",
    "blind",
    "m\u00e1ng",
    "mang2"
  ],
  [
    "\u7b4b",
    "muscle",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u8206",
    "carriage; sedan chair; world",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u8f3f",
    "\u8206 (carriage; sedan chair; world)",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u7a9d",
    "nest",
    "w\u014d",
    "wo1"
  ],
  [
    "\u7aa9",
    "\u7a9d (nest)",
    "w\u014d",
    "wo1"
  ],
  [
    "\u745f",
    "(mus. instr.)",
    "s\u00e8",
    "se4"
  ],
  [
    "\u65a9",
    "behead; chop",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u65ac",
    "\u65a9 (behead; chop)",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u54a8",
    "consult",
    "z\u012b",
    "zi1"
  ],
  [
    "\u8aee",
    "\u54a8 (consult)",
    "z\u012b",
    "zi1"
  ],
  [
    "\u6e9c",
    "slip away; to skate",
    "li\u016b",
    "liu1"
  ],
  [
    "\u9a73",
    "argue; parti-colored; tranship",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u99c1",
    "\u9a73 (argue; parti-colored; tranship)",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u6dd1",
    "kind-hearted; charming",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u916c",
    "entertain; repay; return; reward",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u5c60",
    "slaughter; slaughter man",
    "t\u00fa",
    "tu2"
  ],
  [
    "\u7eb2",
    "head rope of a fishing net",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u7db1",
    "\u7eb2 (head rope of a fishing net)",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u6670",
    "understanding",
    "x\u012b",
    "xi1"
  ],
  [
    "\u8c0a",
    "friendship",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u8abc",
    "\u8c0a (friendship)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u4ec6",
    "fall prostrate, servant",
    "p\u016b",
    "pu1"
  ],
  [
    "\u50d5",
    "\u4ec6 (fall prostrate, servant)",
    "p\u016b",
    "pu1"
  ],
  [
    "\u7ef5",
    "cotton; incessant; soft; downy",
    "mi\u00e1n",
    "mian2"
  ],
  [
    "\u7dbf",
    "\u7ef5 (cotton; incessant; soft; downy)",
    "mi\u00e1n",
    "mian2"
  ],
  [
    "\u59ca",
    "older sister",
    "z\u01d0",
    "zi3"
  ],
  [
    "\u7199",
    "prosperous; splendid",
    "x\u012b",
    "xi1"
  ],
  [
    "\u80c0",
    "dropsical; swollen; to swell",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u8139",
    "\u80c0 (dropsical; swollen; to swell)",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u7855",
    "large; big",
    "shu\u00f2",
    "shuo4"
  ],
  [
    "\u78a9",
    "\u7855 (large; big)",
    "shu\u00f2",
    "shuo4"
  ],
  [
    "\u5b5d",
    "filial",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u68c9",
    "cotton",
    "mi\u00e1n",
    "mian2"
  ],
  [
    "\u7891",
    "a monument",
    "b\u0113i",
    "bei1"
  ],
  [
    "\u72ee",
    "lion",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u7345",
    "\u72ee (lion)",
    "sh\u012b",
    "shi1"
  ],
  [
    "\u72ac",
    "dog",
    "qu\u01cen",
    "quan3"
  ],
  [
    "\u829d",
    "Zoysia pungens",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u6d66",
    "beach",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u817a",
    "gland",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u6458",
    "to borrow",
    "zh\u0101i",
    "zhai1"
  ],
  [
    "\u96b6",
    "attached to; scribe",
    "l\u00ec",
    "li4"
  ],
  [
    "\u96b8",
    "\u96b6 (attached to; scribe)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u53a6",
    "tall building, tall building",
    "sh\u00e0",
    "sha4"
  ],
  [
    "\u5ec8",
    "\u53a6 (tall building, tall building)",
    "sh\u00e0",
    "sha4"
  ],
  [
    "\u58f6",
    "pot; (a mw.)",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u58fa",
    "\u58f6 (pot; (a mw.))",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u6c6a",
    "(surname); expanse of water",
    "w\u0101ng",
    "wang1"
  ],
  [
    "\u832b",
    "vague; vast",
    "m\u00e1ng",
    "mang2"
  ],
  [
    "\u7aed",
    "exhaust",
    "ji\u00e9",
    "jie2"
  ],
  [
    "\u5239",
    "Buddhist monastery or temple",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u524e",
    "\u5239 (Buddhist monastery or temple)",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u8461",
    "grapes",
    "p\u00fa",
    "pu2"
  ],
  [
    "\u5589",
    "throat; larynx",
    "h\u00f3u",
    "hou2"
  ],
  [
    "\u68cb",
    "chess",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u780d",
    "to chop",
    "k\u01cen",
    "kan3"
  ],
  [
    "\u5986",
    "adornment; adorn",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u599d",
    "\u5986 (adornment; adorn)",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u6655",
    "confused; dizzy; giddy; faint",
    "y\u016bn",
    "yun1"
  ],
  [
    "\u6688",
    "\u6655 (confused; dizzy; giddy; faint)",
    "y\u016bn",
    "yun1"
  ],
  [
    "\u8c10",
    "harmonious",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u8ae7",
    "\u8c10 (harmonious)",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u7b52",
    "tube; cylinder",
    "t\u01d2ng",
    "tong3"
  ],
  [
    "\u74f7",
    "chinaware; porcelain",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u9038",
    "escape; leisurely; outstanding",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u67f4",
    "(surname); firewood",
    "ch\u00e1i",
    "chai2"
  ],
  [
    "\u5112",
    "scholar; Confucian",
    "r\u00fa",
    "ru2"
  ],
  [
    "\u78b3",
    "carbon",
    "t\u00e0n",
    "tan4"
  ],
  [
    "\u845b",
    "coarse grass linen, (surname)",
    "g\u00e9",
    "ge2"
  ],
  [
    "\u9e70",
    "eagle; falcon; hawk",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u9df9",
    "\u9e70 (eagle; falcon; hawk)",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u6674",
    "clear; fine (weather)",
    "q\u00edng",
    "qing2"
  ],
  [
    "\u5492",
    "put a curse on",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u5eca",
    "porch",
    "l\u00e1ng",
    "lang2"
  ],
  [
    "\u54bd",
    "narrow pass",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u5893",
    "tomb",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u5d29",
    "collapse; fall into ruins",
    "b\u0113ng",
    "beng1"
  ],
  [
    "\u6b47",
    "to rest",
    "xi\u0113",
    "xie1"
  ],
  [
    "\u5ba0",
    "to love; to pamper; to spoil",
    "ch\u01d2ng",
    "chong3"
  ],
  [
    "\u5bf5",
    "\u5ba0 (to love; to pamper; to spoil)",
    "ch\u01d2ng",
    "chong3"
  ],
  [
    "\u6d4f",
    "clear; deep (of water); swift",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u700f",
    "\u6d4f (clear; deep (of water); swift)",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u72d0",
    "fox",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u8058",
    "betrothed; engage (teacher)",
    "p\u00ecn",
    "pin4"
  ],
  [
    "\u4ead",
    "pavilion",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u971e",
    "red clouds",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u5351",
    "low; base; vulgar; inferior",
    "b\u0113i",
    "bei1"
  ],
  [
    "\u8776",
    "butterfly",
    "di\u00e9",
    "die2"
  ],
  [
    "\u62e6",
    "cut off; hinder",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u6514",
    "\u62e6 (cut off; hinder)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u6734",
    "Celtis sinensis, plain and simple",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u6a38",
    "\u6734 (Celtis sinensis, plain and simple)",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u5146",
    "omen; million",
    "zh\u00e0o",
    "zhao4"
  ],
  [
    "\u99a8",
    "fragrant",
    "x\u012bn",
    "xin1"
  ],
  [
    "\u4fa8",
    "emigrant; reside abroad",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u50d1",
    "\u4fa8 (emigrant; reside abroad)",
    "qi\u00e1o",
    "qiao2"
  ],
  [
    "\u8d50",
    "confer; bestow; grant",
    "c\u00ec",
    "ci4"
  ],
  [
    "\u8cdc",
    "\u8d50 (confer; bestow; grant)",
    "c\u00ec",
    "ci4"
  ],
  [
    "\u9896",
    "clever; gifted",
    "y\u01d0ng",
    "ying3"
  ],
  [
    "\u7a4e",
    "\u9896 (clever; gifted)",
    "y\u01d0ng",
    "ying3"
  ],
  [
    "\u5bb0",
    "slaughter; butcher; govern; rule",
    "z\u01cei",
    "zai3"
  ],
  [
    "\u76c6",
    "basin",
    "p\u00e9n",
    "pen2"
  ],
  [
    "\u803b",
    "shame; disgrace",
    "ch\u01d0",
    "chi3"
  ],
  [
    "\u6065",
    "\u803b (shame; disgrace)",
    "ch\u01d0",
    "chi3"
  ],
  [
    "\u51d1",
    "assemble; put together",
    "c\u00f2u",
    "cou4"
  ],
  [
    "\u6e4a",
    "\u51d1 (assemble; put together)",
    "c\u00f2u",
    "cou4"
  ],
  [
    "\u63a0",
    "plunder",
    "lu\u00e8",
    "lue4"
  ],
  [
    "\u5021",
    "initiate; instigate; introduce",
    "ch\u00e0ng",
    "chang4"
  ],
  [
    "\u7f55",
    "rare",
    "h\u01cen",
    "han3"
  ],
  [
    "\u6ee9",
    "beach; shoal",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u7058",
    "\u6ee9 (beach; shoal)",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u5f18",
    "great; liberal",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u8404",
    "grapes",
    "t\u00e1o",
    "tao2"
  ],
  [
    "\u8877",
    "inner feelings",
    "zh\u014dng",
    "zhong1"
  ],
  [
    "\u8679",
    "rainbow, rainbow",
    "h\u00f3ng",
    "hong2"
  ],
  [
    "\u95ef",
    "to rush; break through",
    "chu\u01ceng",
    "chuang3"
  ],
  [
    "\u95d6",
    "\u95ef (to rush; break through)",
    "chu\u01ceng",
    "chuang3"
  ],
  [
    "\u7ef3",
    "rope",
    "sh\u00e9ng",
    "sheng2"
  ],
  [
    "\u7e69",
    "\u7ef3 (rope)",
    "sh\u00e9ng",
    "sheng2"
  ],
  [
    "\u4f50",
    "assist",
    "zu\u01d2",
    "zuo3"
  ],
  [
    "\u755c",
    "livestock; domesticated animal",
    "ch\u00f9",
    "chu4"
  ],
  [
    "\u6f6d",
    "(surname); deep",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u6d12",
    "spill; sprinkle",
    "s\u01ce",
    "sa3"
  ],
  [
    "\u7051",
    "\u6d12 (spill; sprinkle)",
    "s\u01ce",
    "sa3"
  ],
  [
    "\u8c31",
    "chart; list; score (music)",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u8b5c",
    "\u8c31 (chart; list; score (music))",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u886b",
    "Chinese gown (unlined)",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u9042",
    "forthwith",
    "su\u00ec",
    "sui4"
  ],
  [
    "\u9972",
    "to raise; to rear; to feed",
    "s\u00ec",
    "si4"
  ],
  [
    "\u98fc",
    "\u9972 (to raise; to rear; to feed)",
    "s\u00ec",
    "si4"
  ],
  [
    "\u77ee",
    "short (not tall)",
    "\u01cei",
    "ai3"
  ],
  [
    "\u7f79",
    "happen to; sorrow; suffer from",
    "l\u00ed",
    "li2"
  ],
  [
    "\u699c",
    "a notice or announcement",
    "b\u01ceng",
    "bang3"
  ],
  [
    "\u6021",
    "harmony; pleased",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u9e2d",
    "duck",
    "y\u0101",
    "ya1"
  ],
  [
    "\u9d28",
    "\u9e2d (duck)",
    "y\u0101",
    "ya1"
  ],
  [
    "\u82f9",
    "marsiliaceae",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u860b",
    "\u82f9 (marsiliaceae)",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u886c",
    "give alms; underwear; to line",
    "ch\u00e8n",
    "chen4"
  ],
  [
    "\u896f",
    "\u886c (give alms; underwear; to line)",
    "ch\u00e8n",
    "chen4"
  ],
  [
    "\u5f13",
    "a bow (weapon)",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u5375",
    "ovum",
    "lu\u01cen",
    "luan3"
  ],
  [
    "\u6f58",
    "(surname)",
    "p\u0101n",
    "pan1"
  ],
  [
    "\u8dea",
    "kneel",
    "gu\u00ec",
    "gui4"
  ],
  [
    "\u6d59",
    "Zhejiang",
    "zh\u00e8",
    "zhe4"
  ],
  [
    "\u7a83",
    "I beg to presume; steal",
    "qi\u00e8",
    "qie4"
  ],
  [
    "\u7aca",
    "\u7a83 (I beg to presume; steal)",
    "qi\u00e8",
    "qie4"
  ],
  [
    "\u6ede",
    "sluggish",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u6eef",
    "\u6ede (sluggish)",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u61d2",
    "lazy",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u61f6",
    "\u61d2 (lazy)",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u90b1",
    "(surname); mound",
    "qi\u016b",
    "qiu1"
  ],
  [
    "\u87ba",
    "screw; snail",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u5a1f",
    "beautiful; graceful",
    "ju\u0101n",
    "juan1"
  ],
  [
    "\u8038",
    "excite; raise up; to shrug; high",
    "s\u01d2ng",
    "song3"
  ],
  [
    "\u8073",
    "\u8038 (excite; raise up; to shrug; high)",
    "s\u01d2ng",
    "song3"
  ],
  [
    "\u6b67",
    "divergent; side road",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u714c",
    "brilliant",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u54e9",
    "mile, (part. for continued state)",
    "li",
    "li"
  ],
  [
    "\u9a70",
    "run fast; speed; spread; gallop",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u99b3",
    "\u9a70 (run fast; speed; spread; gallop)",
    "ch\u00ed",
    "chi2"
  ],
  [
    "\u9521",
    "tin",
    "x\u012b",
    "xi1"
  ],
  [
    "\u932b",
    "\u9521 (tin)",
    "x\u012b",
    "xi1"
  ],
  [
    "\u9f0e",
    "tripod",
    "d\u01d0ng",
    "ding3"
  ],
  [
    "\u6101",
    "worry about",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u72ed",
    "narrow; narrow-minded",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u72f9",
    "\u72ed (narrow; narrow-minded)",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u810a",
    "spine; back; ridge",
    "j\u01d0",
    "ji3"
  ],
  [
    "\u715e",
    "decrease; tighten, baleful",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u53c9",
    "fork; pitchfork; prong; pick",
    "ch\u0101",
    "cha1"
  ],
  [
    "\u7fc5",
    "wing",
    "ch\u00ec",
    "chi4"
  ],
  [
    "\u50da",
    "bureaucrat",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u9b45",
    "demon; magic; to charm",
    "m\u00e8i",
    "mei4"
  ],
  [
    "\u6284",
    "to copy; to plagiarize",
    "ch\u0101o",
    "chao1"
  ],
  [
    "\u88d9",
    "skirt",
    "q\u00fan",
    "qun2"
  ],
  [
    "\u563b",
    "laugh; giggle",
    "x\u012b",
    "xi1"
  ],
  [
    "\u7802",
    "sand; gravel; granule",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u6127",
    "ashamed",
    "ku\u00ec",
    "kui4"
  ],
  [
    "\u576a",
    "a plain",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u840e",
    "spiritless; withered, wither",
    "w\u0113i",
    "wei1"
  ],
  [
    "\u5578",
    "to hiss; to whistle",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u562f",
    "\u5578 (to hiss; to whistle)",
    "xi\u00e0o",
    "xiao4"
  ],
  [
    "\u5dfe",
    "towel",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u7586",
    "border; boundary",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u5de2",
    "nest",
    "ch\u00e1o",
    "chao2"
  ],
  [
    "\u5154",
    "rabbit",
    "t\u00f9",
    "tu4"
  ],
  [
    "\u8eaf",
    "human body",
    "q\u016b",
    "qu1"
  ],
  [
    "\u8ec0",
    "\u8eaf (human body)",
    "q\u016b",
    "qu1"
  ],
  [
    "\u9f9f",
    "tortoise; turtle",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u9f9c",
    "\u9f9f (tortoise; turtle)",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u7fe0",
    "bluish-green; green jade",
    "cu\u00ec",
    "cui4"
  ],
  [
    "\u8116",
    "neck",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u7728",
    "wink",
    "zh\u01ce",
    "zha3"
  ],
  [
    "\u853d",
    "to cover; to shield; to screen",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u662d",
    "illustrious; manifest",
    "zh\u0101o",
    "zhao1"
  ],
  [
    "\u987d",
    "mischievous; obstinate; to play",
    "w\u00e1n",
    "wan2"
  ],
  [
    "\u9811",
    "\u987d (mischievous; obstinate; to play)",
    "w\u00e1n",
    "wan2"
  ],
  [
    "\u7476",
    "(jade); Yao; mother-of-pearl",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u7464",
    "\u7476 ((jade); Yao; mother-of-pearl)",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u5188",
    "ridge; mound",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u5ca1",
    "\u5188 (ridge; mound)",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u8fbd",
    "Liaoning; name of a dynasty",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u907c",
    "\u8fbd (Liaoning; name of a dynasty)",
    "li\u00e1o",
    "liao2"
  ],
  [
    "\u80be",
    "kidney",
    "sh\u00e8n",
    "shen4"
  ],
  [
    "\u814e",
    "\u80be (kidney)",
    "sh\u00e8n",
    "shen4"
  ],
  [
    "\u6123",
    "to look distracted",
    "l\u00e8ng",
    "leng4"
  ],
  [
    "\u53e0",
    "fold up; repeat",
    "di\u00e9",
    "die2"
  ],
  [
    "\u758a",
    "\u53e0 (fold up; repeat)",
    "di\u00e9",
    "die2"
  ],
  [
    "\u8302",
    "luxuriant",
    "m\u00e0o",
    "mao4"
  ],
  [
    "\u5751",
    "pit; to defraud",
    "k\u0113ng",
    "keng1"
  ],
  [
    "\u6cfc",
    "splash; to spill",
    "p\u014d",
    "po1"
  ],
  [
    "\u6f51",
    "\u6cfc (splash; to spill)",
    "p\u014d",
    "po1"
  ],
  [
    "\u819d",
    "knee",
    "x\u012b",
    "xi1"
  ],
  [
    "\u8f9f",
    "king; emperor; monarch; royal",
    "p\u00ec",
    "pi4"
  ],
  [
    "\u95e2",
    "\u8f9f (king; emperor; monarch; royal)",
    "p\u00ec",
    "pi4"
  ],
  [
    "\u84b8",
    "to steam",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u6ee5",
    "excessive; indiscriminate",
    "l\u00e0n",
    "lan4"
  ],
  [
    "\u6feb",
    "\u6ee5 (excessive; indiscriminate)",
    "l\u00e0n",
    "lan4"
  ],
  [
    "\u6e58",
    "name of a river",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u7816",
    "brick",
    "zhu\u0101n",
    "zhuan1"
  ],
  [
    "\u78da",
    "\u7816 (brick)",
    "zhu\u0101n",
    "zhuan1"
  ],
  [
    "\u6d78",
    "immerse; soak; steep",
    "j\u00ecn",
    "jin4"
  ],
  [
    "\u5583",
    "mumble in repetition",
    "n\u00e1n",
    "nan2"
  ],
  [
    "\u964c",
    "raised path; street",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u51a4",
    "injustice; to wrong",
    "yu\u0101n",
    "yuan1"
  ],
  [
    "\u94a6",
    "royal",
    "q\u012bn",
    "qin1"
  ],
  [
    "\u6b3d",
    "\u94a6 (royal)",
    "q\u012bn",
    "qin1"
  ],
  [
    "\u57ab",
    "pad; cushion; mat",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u588a",
    "\u57ab (pad; cushion; mat)",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u821f",
    "boat",
    "zh\u014du",
    "zhou1"
  ],
  [
    "\u9e45",
    "goose",
    "\u00e9",
    "e2"
  ],
  [
    "\u9d5d",
    "\u9e45 (goose)",
    "\u00e9",
    "e2"
  ],
  [
    "\u7092",
    "saute; pan-fry; to fry; fried",
    "ch\u01ceo",
    "chao3"
  ],
  [
    "\u6652",
    "to sun",
    "sh\u00e0i",
    "shai4"
  ],
  [
    "\u66ec",
    "\u6652 (to sun)",
    "sh\u00e0i",
    "shai4"
  ],
  [
    "\u6e0a",
    "abyss",
    "yu\u0101n",
    "yuan1"
  ],
  [
    "\u6df5",
    "\u6e0a (abyss)",
    "yu\u0101n",
    "yuan1"
  ],
  [
    "\u6328",
    "lean to; in order",
    "\u0101i",
    "ai1"
  ],
  [
    "\u54b3",
    "sound of sighing, cough",
    "h\u0101i",
    "hai1"
  ],
  [
    "\u5cad",
    "mountain range",
    "l\u01d0ng",
    "ling3"
  ],
  [
    "\u5dba",
    "\u5cad (mountain range)",
    "l\u01d0ng",
    "ling3"
  ],
  [
    "\u5ae9",
    "tender; soft",
    "n\u00e8n",
    "nen4"
  ],
  [
    "\u9976",
    "(surname); to spare",
    "r\u00e1o",
    "rao2"
  ],
  [
    "\u9952",
    "\u9976 ((surname); to spare)",
    "r\u00e1o",
    "rao2"
  ],
  [
    "\u5fbd",
    "insignia",
    "hu\u012b",
    "hui1"
  ],
  [
    "\u888d",
    "gown (lined)",
    "p\u00e1o",
    "pao2"
  ],
  [
    "\u840d",
    "duckweed",
    "p\u00edng",
    "ping2"
  ],
  [
    "\u55bb",
    "allegory",
    "y\u00f9",
    "yu4"
  ],
  [
    "\u6deb",
    "excessive; wanton; lewd; kinky",
    "y\u00edn",
    "yin2"
  ],
  [
    "\u7130",
    "flame",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u77e9",
    "carpenter's square; rule",
    "j\u01d4",
    "ju3"
  ],
  [
    "\u7c98",
    "sticky, to stick; paste",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u6b6a",
    "askew",
    "w\u0101i",
    "wai1"
  ],
  [
    "\u67c4",
    "authority; handle; hilt",
    "b\u01d0ng",
    "bing3"
  ],
  [
    "\u5632",
    "ridicule; mock, to ridicule",
    "ch\u00e1o",
    "chao2"
  ],
  [
    "\u85e4",
    "rattan; cane",
    "t\u00e9ng",
    "teng2"
  ],
  [
    "\u813e",
    "spleen",
    "p\u00ed",
    "pi2"
  ],
  [
    "\u9610",
    "to express; disclose; enlighten",
    "ch\u01cen",
    "chan3"
  ],
  [
    "\u95e1",
    "\u9610 (to express; disclose; enlighten)",
    "ch\u01cen",
    "chan3"
  ],
  [
    "\u7fc1",
    "(surname); elderly person",
    "w\u0113ng",
    "weng1"
  ],
  [
    "\u722a",
    "claw",
    "zh\u01ceo",
    "zhao3"
  ],
  [
    "\u6cca",
    "to anchor; touch at; to moor",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u537f",
    "(old) minister",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u901b",
    "to stroll; to visit",
    "gu\u00e0ng",
    "guang4"
  ],
  [
    "\u8fb0",
    "5th earthly branch; 7-9 a.m.",
    "ch\u00e9n",
    "chen2"
  ],
  [
    "\u64c5",
    "usurp; without authority",
    "sh\u00e0n",
    "shan4"
  ],
  [
    "\u6500",
    "climb up; pull",
    "p\u0101n",
    "pan1"
  ],
  [
    "\u683d",
    "to force; to stick in; to plant",
    "z\u0101i",
    "zai1"
  ],
  [
    "\u716e",
    "to cook; to boil",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u71e5",
    "dry; parched; impatient",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u6cbe",
    "infected by; moisten",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u96c0",
    "lentigo, bird, bird",
    "qu\u00e8",
    "que4"
  ],
  [
    "\u9a9a",
    "have sex appeal",
    "s\u0101o",
    "sao1"
  ],
  [
    "\u9a37",
    "\u9a9a (have sex appeal)",
    "s\u0101o",
    "sao1"
  ],
  [
    "\u522e",
    "to scrape",
    "gu\u0101",
    "gua1"
  ],
  [
    "\u8231",
    "cabin",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u8259",
    "\u8231 (cabin)",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u903e",
    "exceed; jump over, to exceed",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u6398",
    "dig",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u638f",
    "fish out (from pocket)",
    "t\u0101o",
    "tao1"
  ],
  [
    "\u6495",
    "to tear",
    "s\u012b",
    "si1"
  ],
  [
    "\u8d9f",
    "a time; a trip",
    "t\u00e0ng",
    "tang4"
  ],
  [
    "\u73ca",
    "coral",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u5490",
    "to order",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u859b",
    "(surname); wormwood",
    "xu\u0113",
    "xue1"
  ],
  [
    "\u6168",
    "generous; sad",
    "k\u01cei",
    "kai3"
  ],
  [
    "\u5631",
    "enjoin",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u56d1",
    "\u5631 (enjoin)",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u8c23",
    "popular ballad; rumor",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u8b20",
    "\u8c23 (popular ballad; rumor)",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u8bbd",
    "satirize",
    "f\u011bng",
    "feng3"
  ],
  [
    "\u8af7",
    "\u8bbd (satirize)",
    "f\u011bng",
    "feng3"
  ],
  [
    "\u8299",
    "lotus",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u6876",
    "pail; bucket; bin; barrel",
    "t\u01d2ng",
    "tong3"
  ],
  [
    "\u841d",
    "radish",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u863f",
    "\u841d (radish)",
    "lu\u00f3",
    "luo2"
  ],
  [
    "\u50f5",
    "rigid; deadlock",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u68f5",
    "M for plants",
    "k\u0113",
    "ke1"
  ],
  [
    "\u5018",
    "if",
    "t\u01ceng",
    "tang3"
  ],
  [
    "\u5df7",
    "lane; alley",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u64b0",
    "compose; compile, compose",
    "zhu\u00e0n",
    "zhuan4"
  ],
  [
    "\u6c41",
    "juice",
    "zh\u012b",
    "zhi1"
  ],
  [
    "\u634f",
    "to pinch (with one's fingers)",
    "ni\u0113",
    "nie1"
  ],
  [
    "\u7bee",
    "basket; goal",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u7c43",
    "\u7bee (basket; goal)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u9709",
    "bacteria; fungi; moldy",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u9ef4",
    "\u9709 (bacteria; fungi; moldy)",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u52df",
    "canvass for contributions",
    "m\u00f9",
    "mu4"
  ],
  [
    "\u632b",
    "be obstructed; fail; oppress",
    "cu\u00f2",
    "cuo4"
  ],
  [
    "\u5eb8",
    "ordinary; to use",
    "y\u014dng",
    "yong1"
  ],
  [
    "\u5288",
    "hack; chop",
    "p\u012b",
    "pi1"
  ],
  [
    "\u9493",
    "to fish with a hook and bait",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u91e3",
    "\u9493 (to fish with a hook and bait)",
    "di\u00e0o",
    "diao4"
  ],
  [
    "\u658c",
    "ornamental; refined",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u8305",
    "(surname); thatch",
    "m\u00e1o",
    "mao2"
  ],
  [
    "\u532a",
    "bandit",
    "f\u011bi",
    "fei3"
  ],
  [
    "\u7239",
    "dad",
    "di\u0113",
    "die1"
  ],
  [
    "\u5a9a",
    "flatter; charm",
    "m\u00e8i",
    "mei4"
  ],
  [
    "\u7334",
    "monkey",
    "h\u00f3u",
    "hou2"
  ],
  [
    "\u884d",
    "to spread out; to develop",
    "y\u01cen",
    "yan3"
  ],
  [
    "\u9965",
    "hungry",
    "j\u012b",
    "ji1"
  ],
  [
    "\u98e2",
    "\u9965 (hungry)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u6e20",
    "big; stream or canal; drain",
    "q\u00fa",
    "qu2"
  ],
  [
    "\u5ed6",
    "(surname)",
    "li\u00e0o",
    "liao4"
  ],
  [
    "\u5f77",
    "irresolute",
    "p\u00e1ng",
    "pang2"
  ],
  [
    "\u6ee8",
    "shore; beach; coast; bank",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u6ff1",
    "\u6ee8 (shore; beach; coast; bank)",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u5151",
    "to cash",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u514c",
    "\u5151 (to cash)",
    "du\u00ec",
    "dui4"
  ],
  [
    "\u7eb1",
    "cotton yarn; muslin",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u7d17",
    "\u7eb1 (cotton yarn; muslin)",
    "sh\u0101",
    "sha1"
  ],
  [
    "\u5b9b",
    "(surname); similar; winding",
    "w\u01cen",
    "wan3"
  ],
  [
    "\u5ac2",
    "older brother's wife; sister-in-law",
    "s\u01ceo",
    "sao3"
  ],
  [
    "\u7a23",
    "revive",
    "s\u016b",
    "su1"
  ],
  [
    "\u7a4c",
    "\u7a23 (revive)",
    "s\u016b",
    "su1"
  ],
  [
    "\u5784",
    "monopolize",
    "l\u01d2ng",
    "long3"
  ],
  [
    "\u58df",
    "\u5784 (monopolize)",
    "l\u01d2ng",
    "long3"
  ],
  [
    "\u6e17",
    "to seep; to ooze; to horrify",
    "sh\u00e8n",
    "shen4"
  ],
  [
    "\u6ef2",
    "\u6e17 (to seep; to ooze; to horrify)",
    "sh\u00e8n",
    "shen4"
  ],
  [
    "\u6ea2",
    "overflow",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u9489",
    "nail",
    "d\u012bng",
    "ding1"
  ],
  [
    "\u91d8",
    "\u9489 (nail)",
    "d\u012bng",
    "ding1"
  ],
  [
    "\u852c",
    "vegetables",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u67af",
    "dried up",
    "k\u016b",
    "ku1"
  ],
  [
    "\u8d1e",
    "chaste",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u8c9e",
    "\u8d1e (chaste)",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u5760",
    "fall",
    "zhu\u00ec",
    "zhui4"
  ],
  [
    "\u589c",
    "\u5760 (fall)",
    "zhu\u00ec",
    "zhui4"
  ],
  [
    "\u6367",
    "hold or offer with both hands",
    "p\u011bng",
    "peng3"
  ],
  [
    "\u5a05",
    "address term between sons-in-law",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u5a6d",
    "\u5a05 (address term between sons-in-law)",
    "y\u00e0",
    "ya4"
  ],
  [
    "\u68cd",
    "stick",
    "g\u00f9n",
    "gun4"
  ],
  [
    "\u574a",
    "subdivision of a city",
    "f\u0101ng",
    "fang1"
  ],
  [
    "\u79c9",
    "(surname); to grasp; hold",
    "b\u01d0ng",
    "bing3"
  ],
  [
    "\u8e29",
    "step upon; tread on; stamp",
    "c\u01cei",
    "cai3"
  ],
  [
    "\u8086",
    "four (fraud-proof); market",
    "s\u00ec",
    "si4"
  ],
  [
    "\u6467",
    "break; destroy; devastate; ravage",
    "cu\u012b",
    "cui1"
  ],
  [
    "\u8680",
    "eat up slowly; eclipse",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u8755",
    "\u8680 (eat up slowly; eclipse)",
    "sh\u00ed",
    "shi2"
  ],
  [
    "\u5835",
    "stop up",
    "d\u01d4",
    "du3"
  ],
  [
    "\u9187",
    "rich; pure; good wine; sterols",
    "ch\u00fan",
    "chun2"
  ],
  [
    "\u84ec",
    "(grass); disheveled",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u59dc",
    "(surname); ginger, ginger",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u59da",
    "Yao (a surname)",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u51f0",
    "phoenix",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u8c26",
    "modest",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u8b19",
    "\u8c26 (modest)",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u79a7",
    "joy",
    "x\u01d0",
    "xi3"
  ],
  [
    "\u5378",
    "unload; take off",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u7a84",
    "narrow",
    "zh\u01cei",
    "zhai3"
  ],
  [
    "\u8f90",
    "spoke of a wheel",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u8f3b",
    "\u8f90 (spoke of a wheel)",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u4fef",
    "look down; stoop",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u9655",
    "Shaanxi",
    "sh\u01cen",
    "shan3"
  ],
  [
    "\u965d",
    "\u9655 (Shaanxi)",
    "sh\u01cen",
    "shan3"
  ],
  [
    "\u8bc8",
    "crafty; dishonest",
    "zh\u00e0",
    "zha4"
  ],
  [
    "\u8a50",
    "\u8bc8 (crafty; dishonest)",
    "zh\u00e0",
    "zha4"
  ],
  [
    "\u680b",
    "roof beam",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u68df",
    "\u680b (roof beam)",
    "d\u00f2ng",
    "dong4"
  ],
  [
    "\u52ff",
    "do not",
    "w\u00f9",
    "wu4"
  ],
  [
    "\u6627",
    "conceal; dark",
    "m\u00e8i",
    "mei4"
  ],
  [
    "\u6daf",
    "border; horizon; shore",
    "y\u00e1",
    "ya2"
  ],
  [
    "\u59e8",
    "one's mother's sister; aunt",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u70ad",
    "carbon; charcoal",
    "t\u00e0n",
    "tan4"
  ],
  [
    "\u88f9",
    "wrap around",
    "gu\u01d2",
    "guo3"
  ],
  [
    "\u5815",
    "degenerate; fall",
    "du\u00f2",
    "duo4"
  ],
  [
    "\u58ae",
    "\u5815 (degenerate; fall)",
    "du\u00f2",
    "duo4"
  ],
  [
    "\u5f26",
    "bow string",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u8d3e",
    "merchant; to buy, (surname)",
    "ji\u01ce",
    "jia3"
  ],
  [
    "\u8cc8",
    "\u8d3e (merchant; to buy, (surname))",
    "ji\u01ce",
    "jia3"
  ],
  [
    "\u5c2c",
    "in an embarrassing situation",
    "g\u00e0",
    "ga4"
  ],
  [
    "\u632a",
    "to shift; to move",
    "nu\u00f3",
    "nuo2"
  ],
  [
    "\u633d",
    "draw; pull; send funeral ode",
    "w\u01cen",
    "wan3"
  ],
  [
    "\u9699",
    "crack; occasion for dislike",
    "x\u00ec",
    "xi4"
  ],
  [
    "\u7984",
    "good fortune; official salary",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u797f",
    "\u7984 (good fortune; official salary)",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u77e3",
    "final part.",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u8bc0",
    "farewell; secrets (of an art)",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u8a23",
    "\u8bc0 (farewell; secrets (of an art))",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u676d",
    "(surname); Hangzhou",
    "h\u00e1ng",
    "hang2"
  ],
  [
    "\u5c61",
    "time and again",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u5c62",
    "\u5c61 (time and again)",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u5be1",
    "few; widowed",
    "gu\u01ce",
    "gua3"
  ],
  [
    "\u52cb",
    "medal; merit",
    "x\u016bn",
    "xun1"
  ],
  [
    "\u52f3",
    "\u52cb (medal; merit)",
    "x\u016bn",
    "xun1"
  ],
  [
    "\u73ab",
    "rose",
    "m\u00e9i",
    "mei2"
  ],
  [
    "\u8574",
    "bring together; collect",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u860a",
    "\u8574 (bring together; collect)",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u5c34",
    "embarrassed; ill at ease",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u5c37",
    "\u5c34 (embarrassed; ill at ease)",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u6380",
    "lift (cover)",
    "xi\u0101n",
    "xian1"
  ],
  [
    "\u6756",
    "cane, walking stick",
    "zh\u00e0ng",
    "zhang4"
  ],
  [
    "\u5220",
    "to delete",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u522a",
    "\u5220 (to delete)",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u8c2d",
    "(surname)",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u8b5a",
    "\u8c2d ((surname))",
    "t\u00e1n",
    "tan2"
  ],
  [
    "\u81a8",
    "swollen",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u53a2",
    "box (in theater); side room",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u5ec2",
    "\u53a2 (box (in theater); side room)",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u65ec",
    "ten days; ten years",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u7f14",
    "closely joined; connection; knot",
    "d\u00ec",
    "di4"
  ],
  [
    "\u7de0",
    "\u7f14 (closely joined; connection; knot)",
    "d\u00ec",
    "di4"
  ],
  [
    "\u8854",
    "hold in mouth, hold in mouth",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u929c",
    "\u8854 (hold in mouth, hold in mouth)",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u6691",
    "heat; hot weather; summer heat",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u640f",
    "fight; combat; seize",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u4f1e",
    "umbrella; parasol",
    "s\u01cen",
    "san3"
  ],
  [
    "\u5098",
    "\u4f1e (umbrella; parasol)",
    "s\u01cen",
    "san3"
  ],
  [
    "\u5f0a",
    "detriment; fraud; harm; defeat",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5300",
    "even; evenly (divided); uniform",
    "y\u00fan",
    "yun2"
  ],
  [
    "\u52fb",
    "\u5300 (even; evenly (divided); uniform)",
    "y\u00fan",
    "yun2"
  ],
  [
    "\u7529",
    "fling",
    "shu\u01cei",
    "shuai3"
  ],
  [
    "\u97f5",
    "rhyme",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u97fb",
    "\u97f5 (rhyme)",
    "y\u00f9n",
    "yun4"
  ],
  [
    "\u58e4",
    "soil; earth",
    "r\u01ceng",
    "rang3"
  ],
  [
    "\u5937",
    "a barbarian",
    "y\u00ed",
    "yi2"
  ],
  [
    "\u51f9",
    "a depression; indentation",
    "\u0101o",
    "ao1"
  ],
  [
    "\u5398",
    "one thousandth",
    "l\u00ed",
    "li2"
  ],
  [
    "\u91d0",
    "\u5398 (one thousandth)",
    "l\u00ed",
    "li2"
  ],
  [
    "\u82c7",
    "reed; rush; Phragmites communis",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u8466",
    "\u82c7 (reed; rush; Phragmites communis)",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u5320",
    "craftsman",
    "ji\u00e0ng",
    "jiang4"
  ],
  [
    "\u5983",
    "imperial concubine",
    "f\u0113i",
    "fei1"
  ],
  [
    "\u6d31",
    "name of a river",
    "\u011br",
    "er3"
  ],
  [
    "\u6073",
    "earnest",
    "k\u011bn",
    "ken3"
  ],
  [
    "\u61c7",
    "\u6073 (earnest)",
    "k\u011bn",
    "ken3"
  ],
  [
    "\u7f50",
    "can; jar; pot",
    "gu\u00e0n",
    "guan4"
  ],
  [
    "\u752b",
    "just; just now",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u575f",
    "a grave",
    "f\u00e9n",
    "fen2"
  ],
  [
    "\u58b3",
    "\u575f (a grave)",
    "f\u00e9n",
    "fen2"
  ],
  [
    "\u7a9c",
    "flee; escape; run away; leap",
    "cu\u00e0n",
    "cuan4"
  ],
  [
    "\u7ac4",
    "\u7a9c (flee; escape; run away; leap)",
    "cu\u00e0n",
    "cuan4"
  ],
  [
    "\u8328",
    "Tribulus terrestris",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u8155",
    "wrist",
    "w\u00e0n",
    "wan4"
  ],
  [
    "\u6b7c",
    "annihilate",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u6bb2",
    "\u6b7c (annihilate)",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u96ef",
    "multicolored clouds",
    "w\u00e9n",
    "wen2"
  ],
  [
    "\u4ed1",
    "arrange, Koulkun mountains",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u4f96",
    "\u4ed1 (arrange, Koulkun mountains)",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u54ce",
    "an interjection; hey; lookout",
    "\u0101i",
    "ai1"
  ],
  [
    "\u62d0",
    "kidnap; to turn",
    "gu\u01cei",
    "guai3"
  ],
  [
    "\u51f8",
    "(adj) convex; stick out",
    "t\u016b",
    "tu1"
  ],
  [
    "\u5366",
    "divinatory trigram",
    "gu\u00e0",
    "gua4"
  ],
  [
    "\u6614",
    "past; former",
    "x\u012b",
    "xi1"
  ],
  [
    "\u90ca",
    "suburb",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u5e06",
    "sail",
    "f\u0101n",
    "fan1"
  ],
  [
    "\u621a",
    "ashamed; grief, (surname)",
    "q\u012b",
    "qi1"
  ],
  [
    "\u6d3d",
    "accord; make contact",
    "qi\u00e0",
    "qia4"
  ],
  [
    "\u68e0",
    "cherry-apple",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u8b6c",
    "give an example",
    "p\u00ec",
    "pi4"
  ],
  [
    "\u584c",
    "collapse",
    "t\u0101",
    "ta1"
  ],
  [
    "\u7ad6",
    "(straight down character stroke)",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u8c4e",
    "\u7ad6 ((straight down character stroke))",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u7792",
    "conceal from",
    "m\u00e1n",
    "man2"
  ],
  [
    "\u779e",
    "\u7792 (conceal from)",
    "m\u00e1n",
    "man2"
  ],
  [
    "\u8bf5",
    "read aloud",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u8aa6",
    "\u8bf5 (read aloud)",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u988a",
    "cheeks",
    "ji\u00e1",
    "jia2"
  ],
  [
    "\u9830",
    "\u988a (cheeks)",
    "ji\u00e1",
    "jia2"
  ],
  [
    "\u6ce3",
    "to sob",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u6a31",
    "cherry",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u6afb",
    "\u6a31 (cherry)",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u690e",
    "a hammer, spine",
    "chu\u00ed",
    "chui2"
  ],
  [
    "\u6014",
    "be startled",
    "zh\u0113ng",
    "zheng1"
  ],
  [
    "\u4ea9",
    "about 1; 6 acre (M)",
    "m\u01d4",
    "mu3"
  ],
  [
    "\u755d",
    "\u4ea9 (about 1; 6 acre (M))",
    "m\u01d4",
    "mu3"
  ],
  [
    "\u743c",
    "(red stone); beautiful",
    "qi\u00f3ng",
    "qiong2"
  ],
  [
    "\u74ca",
    "\u743c ((red stone); beautiful)",
    "qi\u00f3ng",
    "qiong2"
  ],
  [
    "\u5c51",
    "crumbs; filings; worth while",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u9ecf",
    "sticky",
    "ni\u00e1n",
    "nian2"
  ],
  [
    "\u90b5",
    "(surname); place name",
    "sh\u00e0o",
    "shao4"
  ],
  [
    "\u4f63",
    "hire",
    "y\u00f2ng",
    "yong4"
  ],
  [
    "\u50ad",
    "\u4f63 (hire)",
    "y\u00f2ng",
    "yong4"
  ],
  [
    "\u5d16",
    "precipice",
    "y\u00e1",
    "ya2"
  ],
  [
    "\u5be8",
    "stronghold; stockade",
    "zh\u00e0i",
    "zhai4"
  ],
  [
    "\u5026",
    "tired",
    "ju\u00e0n",
    "juan4"
  ],
  [
    "\u817b",
    "greasy; tired of",
    "n\u00ec",
    "ni4"
  ],
  [
    "\u81a9",
    "\u817b (greasy; tired of)",
    "n\u00ec",
    "ni4"
  ],
  [
    "\u56b7",
    "blurt out; to shout",
    "r\u01ceng",
    "rang3"
  ],
  [
    "\u97ad",
    "a whip or lash; to flog",
    "bi\u0101n",
    "bian1"
  ],
  [
    "\u8be1",
    "sly; crafty",
    "gu\u01d0",
    "gui3"
  ],
  [
    "\u8a6d",
    "\u8be1 (sly; crafty)",
    "gu\u01d0",
    "gui3"
  ],
  [
    "\u707f",
    "glorious; bright; brilliant",
    "c\u00e0n",
    "can4"
  ],
  [
    "\u71e6",
    "\u707f (glorious; bright; brilliant)",
    "c\u00e0n",
    "can4"
  ],
  [
    "\u8fc4",
    "as yet; until",
    "q\u00ec",
    "qi4"
  ],
  [
    "\u8e81",
    "hot-tempered; impatient",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u788c",
    "laborious; small stone",
    "l\u00f9",
    "lu4"
  ],
  [
    "\u8e72",
    "crouch; squat",
    "d\u016bn",
    "dun1"
  ],
  [
    "\u5203",
    "edge of blade",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u54d1",
    "(onomat.), dumb; mute",
    "y\u01ce",
    "ya3"
  ],
  [
    "\u555e",
    "\u54d1 ((onomat.), dumb; mute)",
    "y\u01ce",
    "ya3"
  ],
  [
    "\u9ad3",
    "marrow",
    "su\u01d0",
    "sui3"
  ],
  [
    "\u789f",
    "dish; plate",
    "di\u00e9",
    "die2"
  ],
  [
    "\u5e16",
    "fit snugly, invitation card",
    "ti\u00e8",
    "tie4"
  ],
  [
    "\u8f9c",
    "(surname); crime; sin",
    "g\u016b",
    "gu1"
  ],
  [
    "\u658b",
    "a fast; studio",
    "zh\u0101i",
    "zhai1"
  ],
  [
    "\u9f4b",
    "\u658b (a fast; studio)",
    "zh\u0101i",
    "zhai1"
  ],
  [
    "\u83ca",
    "chrysanthemum",
    "j\u00fa",
    "ju2"
  ],
  [
    "\u68ad",
    "shuttle",
    "su\u014d",
    "suo1"
  ],
  [
    "\u574e",
    "pit; threshold",
    "k\u01cen",
    "kan3"
  ],
  [
    "\u5429",
    "leave instructions; to order",
    "f\u0113n",
    "fen1"
  ],
  [
    "\u6d46",
    "broth; serum",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u6f3f",
    "\u6d46 (broth; serum)",
    "ji\u0101ng",
    "jiang1"
  ],
  [
    "\u94f8",
    "cast metals; to coin (money)",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u9444",
    "\u94f8 (cast metals; to coin (money))",
    "zh\u00f9",
    "zhu4"
  ],
  [
    "\u9988",
    "food; make a present",
    "ku\u00ec",
    "kui4"
  ],
  [
    "\u994b",
    "\u9988 (food; make a present)",
    "ku\u00ec",
    "kui4"
  ],
  [
    "\u9882",
    "to praise",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u980c",
    "\u9882 (to praise)",
    "s\u00f2ng",
    "song4"
  ],
  [
    "\u7779",
    "observe; see",
    "d\u01d4",
    "du3"
  ],
  [
    "\u51c4",
    "intense cold",
    "q\u012b",
    "qi1"
  ],
  [
    "\u60bd",
    "\u51c4 (intense cold)",
    "q\u012b",
    "qi1"
  ],
  [
    "\u5e18",
    "wine shop sign",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u7c3e",
    "\u5e18 (wine shop sign)",
    "li\u00e1n",
    "lian2"
  ],
  [
    "\u5a49",
    "graceful; tactful",
    "w\u01cen",
    "wan3"
  ],
  [
    "\u66a8",
    "and; reach to; the end",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u7470",
    "(semi-precious stone)",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u4ea8",
    "prosperous",
    "h\u0113ng",
    "heng1"
  ],
  [
    "\u5962",
    "extravagant",
    "sh\u0113",
    "she1"
  ],
  [
    "\u778e",
    "blind",
    "xi\u0101",
    "xia1"
  ],
  [
    "\u8650",
    "oppressive; tyrannical",
    "nu\u00e8",
    "nue4"
  ],
  [
    "\u7784",
    "to aim",
    "mi\u00e1o",
    "miao2"
  ],
  [
    "\u9017",
    "linger",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u60f6",
    "frightened",
    "hu\u00e1ng",
    "huang2"
  ],
  [
    "\u5a25",
    "good; beautiful",
    "\u00e9",
    "e2"
  ],
  [
    "\u6ee4",
    "to strain; to filter",
    "l\u01dc",
    "lu:4"
  ],
  [
    "\u6ffe",
    "\u6ee4 (to strain; to filter)",
    "l\u01dc",
    "lu:4"
  ],
  [
    "\u66dd",
    "to air; to sun",
    "p\u00f9",
    "pu4"
  ],
  [
    "\u94a7",
    "30 catties; great; your (hon.)",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u921e",
    "\u94a7 (30 catties; great; your (hon.))",
    "j\u016bn",
    "jun1"
  ],
  [
    "\u8fe6",
    "Buddha; Shakyamuni",
    "ji\u0101",
    "jia1"
  ],
  [
    "\u9119",
    "rustic; low; base; mean",
    "b\u01d0",
    "bi3"
  ],
  [
    "\u75d2",
    "to itch; to tickle",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u7662",
    "\u75d2 (to itch; to tickle)",
    "y\u01ceng",
    "yang3"
  ],
  [
    "\u8d31",
    "inexpensive; lowly",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u8ce4",
    "\u8d31 (inexpensive; lowly)",
    "ji\u00e0n",
    "jian4"
  ],
  [
    "\u7fa1",
    "to envy",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u7fa8",
    "\u7fa1 (to envy)",
    "xi\u00e0n",
    "xian4"
  ],
  [
    "\u5de9",
    "secure; solid",
    "g\u01d2ng",
    "gong3"
  ],
  [
    "\u978f",
    "\u5de9 (secure; solid)",
    "g\u01d2ng",
    "gong3"
  ],
  [
    "\u79c3",
    "bald; blunt",
    "t\u016b",
    "tu1"
  ],
  [
    "\u79bf",
    "\u79c3 (bald; blunt)",
    "t\u016b",
    "tu1"
  ],
  [
    "\u64bc",
    "shake; to incite",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u7eac",
    "latitude",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u7def",
    "\u7eac (latitude)",
    "w\u011bi",
    "wei3"
  ],
  [
    "\u62f1",
    "fold hands in salute; to arch",
    "g\u01d2ng",
    "gong3"
  ],
  [
    "\u6cab",
    "foam; suds",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u6d85",
    "blacken",
    "ni\u00e8",
    "nie4"
  ],
  [
    "\u67a2",
    "hinge; pivot",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u6a1e",
    "\u67a2 (hinge; pivot)",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u5764",
    "the earth-; female-principle",
    "k\u016bn",
    "kun1"
  ],
  [
    "\u6bb7",
    "dark red, (surname); dynasty",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u6ca6",
    "perish",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u6dea",
    "\u6ca6 (perish)",
    "l\u00fan",
    "lun2"
  ],
  [
    "\u59ec",
    "(surname); women",
    "j\u012b",
    "ji1"
  ],
  [
    "\u96cc",
    "female",
    "c\u00ed",
    "ci2"
  ],
  [
    "\u6bef",
    "blanket; rug",
    "t\u01cen",
    "tan3"
  ],
  [
    "\u7a3b",
    "paddy; rice",
    "d\u00e0o",
    "dao4"
  ],
  [
    "\u7538",
    "imperial domain; suburb",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u682a",
    "(a mw., use with plants)",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u64ce",
    "to raise (hand)",
    "q\u00edng",
    "qing2"
  ],
  [
    "\u4fa3",
    "companion",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u4fb6",
    "\u4fa3 (companion)",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u8205",
    "maternal uncle",
    "ji\u00f9",
    "jiu4"
  ],
  [
    "\u9e9f",
    "female unicorn",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u54c4",
    "resound with laughter, deceive",
    "h\u014dng",
    "hong1"
  ],
  [
    "\u9e64",
    "crane",
    "h\u00e8",
    "he4"
  ],
  [
    "\u9db4",
    "\u9e64 (crane)",
    "h\u00e8",
    "he4"
  ],
  [
    "\u8c79",
    "leopard; panther",
    "b\u00e0o",
    "bao4"
  ],
  [
    "\u63fd",
    "monopolize; seize",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u652c",
    "\u63fd (monopolize; seize)",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u9b44",
    "soul",
    "p\u00f2",
    "po4"
  ],
  [
    "\u54b8",
    "all; in all cases; salty, salty",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u9e79",
    "\u54b8 (all; in all cases; salty, salty)",
    "xi\u00e1n",
    "xian2"
  ],
  [
    "\u9600",
    "clique; valve",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u95a5",
    "\u9600 (clique; valve)",
    "f\u00e1",
    "fa2"
  ],
  [
    "\u566a",
    "chirp; mixture of voices; noise",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u6cb8",
    "boil",
    "f\u00e8i",
    "fei4"
  ],
  [
    "\u5d4c",
    "deep valley; inlay",
    "qi\u00e0n",
    "qian4"
  ],
  [
    "\u72c4",
    "(surname); barbarians",
    "d\u00ed",
    "di2"
  ],
  [
    "\u6055",
    "forgive",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u6817",
    "afraid; trembling, (surname)",
    "l\u00ec",
    "li4"
  ],
  [
    "\u6144",
    "\u6817 (afraid; trembling, (surname))",
    "l\u00ec",
    "li4"
  ],
  [
    "\u5cfb",
    "steep",
    "j\u00f9n",
    "jun4"
  ],
  [
    "\u4e38",
    "pill",
    "w\u00e1n",
    "wan2"
  ],
  [
    "\u9cde",
    "scales (of fish)",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u9c57",
    "\u9cde (scales (of fish))",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u7f05",
    "Burma; distant",
    "mi\u01cen",
    "mian3"
  ],
  [
    "\u7dec",
    "\u7f05 (Burma; distant)",
    "mi\u01cen",
    "mian3"
  ],
  [
    "\u6ccc",
    "secrete; pour off",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u604d",
    "disappointed; flurried",
    "hu\u01ceng",
    "huang3"
  ],
  [
    "\u6361",
    "pick up; collect; gather",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u64bf",
    "\u6361 (pick up; collect; gather)",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u7f2a",
    "(surname), to wind round",
    "m\u00f3u",
    "mou2"
  ],
  [
    "\u7e46",
    "\u7f2a ((surname), to wind round)",
    "m\u00f3u",
    "mou2"
  ],
  [
    "\u508d",
    "near (approaching), near",
    "b\u00e0ng",
    "bang4"
  ],
  [
    "\u71ac",
    "to boil; stew; to simmer, endure",
    "\u00e1o",
    "ao2"
  ],
  [
    "\u7b5b",
    "to filter; to sift; to sieve",
    "sh\u0101i",
    "shai1"
  ],
  [
    "\u7be9",
    "\u7b5b (to filter; to sift; to sieve)",
    "sh\u0101i",
    "shai1"
  ],
  [
    "\u51a5",
    "dark; deep",
    "m\u00edng",
    "ming2"
  ],
  [
    "\u94ae",
    "(surname); button",
    "ni\u01d4",
    "niu3"
  ],
  [
    "\u9215",
    "\u94ae ((surname); button)",
    "ni\u01d4",
    "niu3"
  ],
  [
    "\u5858",
    "pond",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u94a9",
    "entice; hook",
    "g\u014du",
    "gou1"
  ],
  [
    "\u9264",
    "\u94a9 (entice; hook)",
    "g\u014du",
    "gou1"
  ],
  [
    "\u655e",
    "spacious; uncovered",
    "ch\u01ceng",
    "chang3"
  ],
  [
    "\u82bd",
    "bud; sprout",
    "y\u00e1",
    "ya2"
  ],
  [
    "\u4fcf",
    "smart",
    "qi\u00e0o",
    "qiao4"
  ],
  [
    "\u602f",
    "afraid; rustic",
    "qi\u00e8",
    "qie4"
  ],
  [
    "\u9661",
    "steep",
    "d\u01d2u",
    "dou3"
  ],
  [
    "\u953b",
    "forge; wrought; to discipline",
    "du\u00e0n",
    "duan4"
  ],
  [
    "\u935b",
    "\u953b (forge; wrought; to discipline)",
    "du\u00e0n",
    "duan4"
  ],
  [
    "\u5256",
    "to cut",
    "p\u014du",
    "pou1"
  ],
  [
    "\u711a",
    "burn",
    "f\u00e9n",
    "fen2"
  ],
  [
    "\u53ee",
    "sting (of mosquito); to ask",
    "d\u012bng",
    "ding1"
  ],
  [
    "\u7a91",
    "kiln; oven",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u7aaf",
    "\u7a91 (kiln; oven)",
    "y\u00e1o",
    "yao2"
  ],
  [
    "\u55d3",
    "throat; voice",
    "s\u01ceng",
    "sang3"
  ],
  [
    "\u62e2",
    "collect; draw near to",
    "l\u01d2ng",
    "long3"
  ],
  [
    "\u650f",
    "\u62e2 (collect; draw near to)",
    "l\u01d2ng",
    "long3"
  ],
  [
    "\u9e26",
    "crow",
    "y\u0101",
    "ya1"
  ],
  [
    "\u9d09",
    "\u9e26 (crow)",
    "y\u0101",
    "ya1"
  ],
  [
    "\u7a1a",
    "infantile; young",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u4f3d",
    "(phonetic)",
    "qi\u00e9",
    "qie2"
  ],
  [
    "\u7487",
    "(jade)",
    "xu\u00e1n",
    "xuan2"
  ],
  [
    "\u54a7",
    "child's cry, draw back corners of mouth",
    "li\u0113",
    "lie1"
  ],
  [
    "\u4fd8",
    "prisoner of war",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u9877",
    "100 mu; a short while ago",
    "q\u01d0ng",
    "qing3"
  ],
  [
    "\u9803",
    "\u9877 (100 mu; a short while ago)",
    "q\u01d0ng",
    "qing3"
  ],
  [
    "\u635e",
    "fish up",
    "l\u0101o",
    "lao1"
  ],
  [
    "\u6488",
    "\u635e (fish up)",
    "l\u0101o",
    "lao1"
  ],
  [
    "\u7eba",
    "spin",
    "f\u01ceng",
    "fang3"
  ],
  [
    "\u7d21",
    "\u7eba (spin)",
    "f\u01ceng",
    "fang3"
  ],
  [
    "\u81c0",
    "butt; buttocks",
    "t\u00fan",
    "tun2"
  ],
  [
    "\u8236",
    "sea-going vessels; ship",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u7aa5",
    "peep; pry into",
    "ku\u012b",
    "kui1"
  ],
  [
    "\u7aba",
    "\u7aa5 (peep; pry into)",
    "ku\u012b",
    "kui1"
  ],
  [
    "\u5587",
    "(onomat.), (phonetic)",
    "l\u01ce",
    "la3"
  ],
  [
    "\u6da9",
    "grating (of surfaces); tart",
    "s\u00e8",
    "se4"
  ],
  [
    "\u6f80",
    "\u6da9 (grating (of surfaces); tart)",
    "s\u00e8",
    "se4"
  ],
  [
    "\u54e8",
    "a whistle; sentry",
    "sh\u00e0o",
    "shao4"
  ],
  [
    "\u5960",
    "libation",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u56da",
    "prisoner",
    "qi\u00fa",
    "qiu2"
  ],
  [
    "\u6795",
    "pillow",
    "zh\u011bn",
    "zhen3"
  ],
  [
    "\u5f66",
    "accomplished; elegant",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u5f65",
    "\u5f66 (accomplished; elegant)",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u82a6",
    "rush; reed; Phragmites communis",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u8606",
    "\u82a6 (rush; reed; Phragmites communis)",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u4e19",
    "3rd heavenly stem",
    "b\u01d0ng",
    "bing3"
  ],
  [
    "\u8d3f",
    "bribe; bribery",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u8cc4",
    "\u8d3f (bribe; bribery)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u5f6c",
    "ornamental; refined",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u57ae",
    "collapse",
    "ku\u01ce",
    "kua3"
  ],
  [
    "\u5eff",
    "twenty",
    "ni\u00e0n",
    "nian4"
  ],
  [
    "\u6816",
    "to roost; to inhabit; to stay",
    "q\u012b",
    "qi1"
  ],
  [
    "\u68f2",
    "\u6816 (to roost; to inhabit; to stay)",
    "q\u012b",
    "qi1"
  ],
  [
    "\u75a4",
    "scar",
    "b\u0101",
    "ba1"
  ],
  [
    "\u8c1c",
    "riddle",
    "m\u00ed",
    "mi2"
  ],
  [
    "\u8b0e",
    "\u8c1c (riddle)",
    "m\u00ed",
    "mi2"
  ],
  [
    "\u8d2c",
    "to diminish; to demote",
    "bi\u01cen",
    "bian3"
  ],
  [
    "\u8cb6",
    "\u8d2c (to diminish; to demote)",
    "bi\u01cen",
    "bian3"
  ],
  [
    "\u818f",
    "ointment; paste",
    "g\u0101o",
    "gao1"
  ],
  [
    "\u4f51",
    "bless; protect, protect",
    "y\u00f2u",
    "you4"
  ],
  [
    "\u7f1a",
    "to bind; to tie",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u7e1b",
    "\u7f1a (to bind; to tie)",
    "f\u00f9",
    "fu4"
  ],
  [
    "\u7bf7",
    "sail",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u83b9",
    "luster of gems",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u7469",
    "\u83b9 (luster of gems)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u4f3a",
    "wait on, to watch; to wait",
    "c\u00ec",
    "ci4"
  ],
  [
    "\u61c8",
    "lax; negligent",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u54d7",
    "crashing sound, cat-calling sound",
    "hu\u0101",
    "hua1"
  ],
  [
    "\u8b41",
    "\u54d7 (crashing sound, cat-calling sound)",
    "hu\u0101",
    "hua1"
  ],
  [
    "\u6c5d",
    "thou",
    "r\u01d4",
    "ru3"
  ],
  [
    "\u65ed",
    "dawn; rising sun",
    "x\u00f9",
    "xu4"
  ],
  [
    "\u79bd",
    "birds; fowl",
    "q\u00edn",
    "qin2"
  ],
  [
    "\u5f6a",
    "a tiger-cat; stripes; streaks",
    "bi\u0101o",
    "biao1"
  ],
  [
    "\u8f7f",
    "sedan chair",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u8f4e",
    "\u8f7f (sedan chair)",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u6fa1",
    "bath",
    "z\u01ceo",
    "zao3"
  ],
  [
    "\u57a6",
    "reclaim (land)",
    "k\u011bn",
    "ken3"
  ],
  [
    "\u58be",
    "\u57a6 (reclaim (land))",
    "k\u011bn",
    "ken3"
  ],
  [
    "\u8c2c",
    "absurd; erroneous",
    "mi\u00f9",
    "miu4"
  ],
  [
    "\u8b2c",
    "\u8c2c (absurd; erroneous)",
    "mi\u00f9",
    "miu4"
  ],
  [
    "\u867e",
    "shrimp; prawn",
    "xi\u0101",
    "xia1"
  ],
  [
    "\u8766",
    "\u867e (shrimp; prawn)",
    "xi\u0101",
    "xia1"
  ],
  [
    "\u9a87",
    "astonish; startle",
    "h\u00e0i",
    "hai4"
  ],
  [
    "\u99ed",
    "\u9a87 (astonish; startle)",
    "h\u00e0i",
    "hai4"
  ],
  [
    "\u6402",
    "graft (money); solicit",
    "l\u01d2u",
    "lou3"
  ],
  [
    "\u645f",
    "\u6402 (graft (money); solicit)",
    "l\u01d2u",
    "lou3"
  ],
  [
    "\u745c",
    "excellence; luster of gems",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u7845",
    "silicon",
    "gu\u012b",
    "gui1"
  ],
  [
    "\u8db4",
    "to lie on one's stomach",
    "p\u0101",
    "pa1"
  ],
  [
    "\u4e5e",
    "beg",
    "q\u01d0",
    "qi3"
  ],
  [
    "\u70db",
    "candle",
    "zh\u00fa",
    "zhu2"
  ],
  [
    "\u71ed",
    "\u70db (candle)",
    "zh\u00fa",
    "zhu2"
  ],
  [
    "\u5c9a",
    "mist; name of a mountain",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u5d50",
    "\u5c9a (mist; name of a mountain)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u82b8",
    "Ruta graveolens",
    "y\u00fan",
    "yun2"
  ],
  [
    "\u6577",
    "announce; apply",
    "f\u016b",
    "fu1"
  ],
  [
    "\u5c7f",
    "islet",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u5dbc",
    "\u5c7f (islet)",
    "y\u01d4",
    "yu3"
  ],
  [
    "\u5792",
    "rampart",
    "l\u011bi",
    "lei3"
  ],
  [
    "\u58d8",
    "\u5792 (rampart)",
    "l\u011bi",
    "lei3"
  ],
  [
    "\u5495",
    "mutter",
    "g\u016b",
    "gu1"
  ],
  [
    "\u90e1",
    "canton; county; region",
    "j\u00f9n",
    "jun4"
  ],
  [
    "\u5bde",
    "lonesome",
    "m\u00f2",
    "mo4"
  ],
  [
    "\u82d1",
    "(surname); park",
    "yu\u00e0n",
    "yuan4"
  ],
  [
    "\u6a61",
    "oak; Quercus serrata",
    "xi\u00e0ng",
    "xiang4"
  ],
  [
    "\u786b",
    "sulfur",
    "li\u00fa",
    "liu2"
  ],
  [
    "\u63c9",
    "knead; massage; rub",
    "r\u00f3u",
    "rou2"
  ],
  [
    "\u56a3",
    "clamor",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u56c2",
    "\u56a3 (clamor)",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u68da",
    "shed",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u9171",
    "jam; thick sauce",
    "ji\u00e0ng",
    "jiang4"
  ],
  [
    "\u91ac",
    "\u9171 (jam; thick sauce)",
    "ji\u00e0ng",
    "jiang4"
  ],
  [
    "\u540f",
    "minor official",
    "l\u00ec",
    "li4"
  ],
  [
    "\u8a79",
    "(surname); excellent; verbose",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u8774",
    "butterfly",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u56bc",
    "to chew, to chew",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u6dee",
    "name of a river",
    "hu\u00e1i",
    "huai2"
  ],
  [
    "\u7329",
    "ape",
    "x\u012bng",
    "xing1"
  ],
  [
    "\u6401",
    "to place",
    "g\u0113",
    "ge1"
  ],
  [
    "\u64f1",
    "\u6401 (to place)",
    "g\u0113",
    "ge1"
  ],
  [
    "\u5395",
    "rest-room; toilet; lavatory",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u5ec1",
    "\u5395 (rest-room; toilet; lavatory)",
    "c\u00e8",
    "ce4"
  ],
  [
    "\u76cf",
    "M for lamp, wine cup",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u76de",
    "\u76cf (M for lamp, wine cup)",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u6405",
    "to disturb; to annoy; to mix",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u652a",
    "\u6405 (to disturb; to annoy; to mix)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u68b3",
    "comb",
    "sh\u016b",
    "shu1"
  ],
  [
    "\u80f3",
    "armpit",
    "g\u0113",
    "ge1"
  ],
  [
    "\u545c",
    "(onomat. for humming)",
    "w\u016b",
    "wu1"
  ],
  [
    "\u55da",
    "\u545c ((onomat. for humming))",
    "w\u016b",
    "wu1"
  ],
  [
    "\u762b",
    "paralyzed",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u7671",
    "\u762b (paralyzed)",
    "t\u0101n",
    "tan1"
  ],
  [
    "\u84c9",
    "lotus",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u70c1",
    "bright; luminous",
    "shu\u00f2",
    "shuo4"
  ],
  [
    "\u720d",
    "\u70c1 (bright; luminous)",
    "shu\u00f2",
    "shuo4"
  ],
  [
    "\u8165",
    "fishy (smell)",
    "x\u012bng",
    "xing1"
  ],
  [
    "\u857e",
    "bud",
    "l\u011bi",
    "lei3"
  ],
  [
    "\u70eb",
    "to scald; to burn; to iron; hot",
    "t\u00e0ng",
    "tang4"
  ],
  [
    "\u71d9",
    "\u70eb (to scald; to burn; to iron; hot)",
    "t\u00e0ng",
    "tang4"
  ],
  [
    "\u8721",
    "candle; wax",
    "l\u00e0",
    "la4"
  ],
  [
    "\u881f",
    "\u8721 (candle; wax)",
    "l\u00e0",
    "la4"
  ],
  [
    "\u836b",
    "shade",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u852d",
    "\u836b (shade)",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u77eb",
    "dissemble; rectify",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u77ef",
    "\u77eb (dissemble; rectify)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u7574",
    "arable fields; cultivated field",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u7587",
    "\u7574 (arable fields; cultivated field)",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u818a",
    "shoulder; upper arm",
    "b\u00f3",
    "bo2"
  ],
  [
    "\u7ec5",
    "member of gentry",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u7d33",
    "\u7ec5 (member of gentry)",
    "sh\u0113n",
    "shen1"
  ],
  [
    "\u7ee3",
    "to embroider",
    "xi\u00f9",
    "xiu4"
  ],
  [
    "\u7e61",
    "\u7ee3 (to embroider)",
    "xi\u00f9",
    "xiu4"
  ],
  [
    "\u5a36",
    "take a wife",
    "q\u01d4",
    "qu3"
  ],
  [
    "\u65a7",
    "hatchet",
    "f\u01d4",
    "fu3"
  ],
  [
    "\u917f",
    "ferment; brew",
    "ni\u00e0ng",
    "niang4"
  ],
  [
    "\u91c0",
    "\u917f (ferment; brew)",
    "ni\u00e0ng",
    "niang4"
  ],
  [
    "\u515c",
    "pocket",
    "d\u014du",
    "dou1"
  ],
  [
    "\u94dd",
    "aluminum",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u92c1",
    "\u94dd (aluminum)",
    "l\u01da",
    "lu:3"
  ],
  [
    "\u8eac",
    "body; oneself; personally; to bow",
    "g\u014dng",
    "gong1"
  ],
  [
    "\u7011",
    "shower (rain), waterfall",
    "p\u00f9",
    "pu4"
  ],
  [
    "\u733f",
    "ape",
    "yu\u00e1n",
    "yuan2"
  ],
  [
    "\u86db",
    "spider",
    "zh\u016b",
    "zhu1"
  ],
  [
    "\u673d",
    "rotten",
    "xi\u01d4",
    "xiu3"
  ],
  [
    "\u84b2",
    "calamus",
    "p\u00fa",
    "pu2"
  ],
  [
    "\u57d4",
    "port",
    "p\u01d4",
    "pu3"
  ],
  [
    "\u52d8",
    "to investigate; to survey",
    "k\u0101n",
    "kan1"
  ],
  [
    "\u8c0d",
    "to spy",
    "di\u00e9",
    "die2"
  ],
  [
    "\u8adc",
    "\u8c0d (to spy)",
    "di\u00e9",
    "die2"
  ],
  [
    "\u6f84",
    "clear and still water, clear",
    "ch\u00e9ng",
    "cheng2"
  ],
  [
    "\u7c3f",
    "a book; a register; account-book",
    "b\u00f9",
    "bu4"
  ],
  [
    "\u8c34",
    "punishment; scold",
    "qi\u01cen",
    "qian3"
  ],
  [
    "\u8b74",
    "\u8c34 (punishment; scold)",
    "qi\u01cen",
    "qian3"
  ],
  [
    "\u60d5",
    "fearful; respectful",
    "t\u00ec",
    "ti4"
  ],
  [
    "\u7ed2",
    "velvet; woolen",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u7d68",
    "\u7ed2 (velvet; woolen)",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u6c79",
    "scared, nervous, torrential rush",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u6d36",
    "\u6c79 (scared, nervous, torrential rush)",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u54af",
    "part. for obviousness, cough (blood)",
    "lu\u00f2",
    "luo4"
  ],
  [
    "\u8513",
    "Brassica campestris, creeper",
    "m\u00e0n",
    "man4"
  ],
  [
    "\u5636",
    "hiss; neigh",
    "s\u012b",
    "si1"
  ],
  [
    "\u5ab3",
    "daughter in law",
    "x\u00ed",
    "xi2"
  ],
  [
    "\u5e1c",
    "flag",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u5e5f",
    "\u5e1c (flag)",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u8e44",
    "hoof; pork shoulder",
    "t\u00ed",
    "ti2"
  ],
  [
    "\u7109",
    "where; how",
    "y\u0101n",
    "yan1"
  ],
  [
    "\u70bd",
    "flame; blaze",
    "ch\u00ec",
    "chi4"
  ],
  [
    "\u71be",
    "\u70bd (flame; blaze)",
    "ch\u00ec",
    "chi4"
  ],
  [
    "\u4e2b",
    "slave girl",
    "y\u0101",
    "ya1"
  ],
  [
    "\u6cfb",
    "to flow (out) swiftly; diarrhea",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u7009",
    "\u6cfb (to flow (out) swiftly; diarrhea)",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u5885",
    "villa",
    "sh\u00f9",
    "shu4"
  ],
  [
    "\u62da",
    "disregard; reject, stake all",
    "p\u00e0n",
    "pan4"
  ],
  [
    "\u5993",
    "prostitute",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u6869",
    "item; stump; stake; pile",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u6a01",
    "\u6869 (item; stump; stake; pile)",
    "zhu\u0101ng",
    "zhuang1"
  ],
  [
    "\u7fd8",
    "outstanding; to raise",
    "qi\u00e0o",
    "qiao4"
  ],
  [
    "\u7ff9",
    "\u7fd8 (outstanding; to raise)",
    "qi\u00e0o",
    "qiao4"
  ],
  [
    "\u5bdd",
    "lie down",
    "q\u01d0n",
    "qin3"
  ],
  [
    "\u5be2",
    "\u5bdd (lie down)",
    "q\u01d0n",
    "qin3"
  ],
  [
    "\u8511",
    "belittle; nothing",
    "mi\u00e8",
    "mie4"
  ],
  [
    "\u5384",
    "distressed",
    "\u00e8",
    "e4"
  ],
  [
    "\u6df9",
    "drown; submerge",
    "y\u0101n",
    "yan1"
  ],
  [
    "\u8be0",
    "explain; comment",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u8a6e",
    "\u8be0 (explain; comment)",
    "qu\u00e1n",
    "quan2"
  ],
  [
    "\u5d14",
    "(surname); high mountain",
    "cu\u012b",
    "cui1"
  ],
  [
    "\u70d8",
    "bake; heat by fire",
    "h\u014dng",
    "hong1"
  ],
  [
    "\u6687",
    "leisure",
    "xi\u00e1",
    "xia2"
  ],
  [
    "\u7f38",
    "jar; vat",
    "g\u0101ng",
    "gang1"
  ],
  [
    "\u94c5",
    "lead (metal)",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u925b",
    "\u94c5 (lead (metal))",
    "qi\u0101n",
    "qian1"
  ],
  [
    "\u7b1b",
    "flute",
    "d\u00ed",
    "di2"
  ],
  [
    "\u6320",
    "to scratch",
    "n\u00e1o",
    "nao2"
  ],
  [
    "\u6493",
    "\u6320 (to scratch)",
    "n\u00e1o",
    "nao2"
  ],
  [
    "\u5824",
    "dike",
    "d\u012b",
    "di1"
  ],
  [
    "\u501a",
    "to lean on; rely upon",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u6115",
    "startled",
    "\u00e8",
    "e4"
  ],
  [
    "\u6eaf",
    "go upstream; trace the source",
    "s\u00f9",
    "su4"
  ],
  [
    "\u965b",
    "the steps to the throne",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u5450",
    "battle cry, (final part.)",
    "ne",
    "ne"
  ],
  [
    "\u5436",
    "\u5450 (battle cry, (final part.))",
    "ne",
    "ne"
  ],
  [
    "\u9b41",
    "chief; head",
    "ku\u00ed",
    "kui2"
  ],
  [
    "\u714e",
    "pan-fry",
    "ji\u0101n",
    "jian1"
  ],
  [
    "\u69fd",
    "manger; trough; groove",
    "c\u00e1o",
    "cao2"
  ],
  [
    "\u5f27",
    "arc",
    "h\u00fa",
    "hu2"
  ],
  [
    "\u9499",
    "calcium",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u9223",
    "\u9499 (calcium)",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u516e",
    "(part.)",
    "x\u012b",
    "xi1"
  ],
  [
    "\u63b7",
    "toss",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u64f2",
    "\u63b7 (toss)",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u8681",
    "ant",
    "y\u01d0",
    "yi3"
  ],
  [
    "\u87fb",
    "\u8681 (ant)",
    "y\u01d0",
    "yi3"
  ],
  // [
  //   "\u5fb5",
  //   "levy (taxes), note in Chinese scale",
  //   "zh\u0113ng",
  //   "zheng1"
  // ],
  [
    "\u7785",
    "to see; look; take a look at",
    "ch\u01d2u",
    "chou3"
  ],
  [
    "\u5992",
    "jealous",
    "d\u00f9",
    "du4"
  ],
  [
    "\u55a7",
    "clamor; noise, clamor; noise",
    "xu\u0101n",
    "xuan1"
  ],
  [
    "\u55c5",
    "sense of smell",
    "xi\u00f9",
    "xiu4"
  ],
  [
    "\u8700",
    "Sichuan",
    "sh\u01d4",
    "shu3"
  ],
  [
    "\u9556",
    "a throwing weapon; dart",
    "bi\u0101o",
    "biao1"
  ],
  [
    "\u93e2",
    "\u9556 (a throwing weapon; dart)",
    "bi\u0101o",
    "biao1"
  ],
  [
    "\u68a8",
    "pear",
    "l\u00ed",
    "li2"
  ],
  [
    "\u533f",
    "to hide",
    "n\u00ec",
    "ni4"
  ],
  [
    "\u8471",
    "scallion; green onion",
    "c\u014dng",
    "cong1"
  ],
  [
    "\u8525",
    "\u8471 (scallion; green onion)",
    "c\u014dng",
    "cong1"
  ],
  [
    "\u68fa",
    "coffin",
    "gu\u0101n",
    "guan1"
  ],
  [
    "\u5180",
    "Hebei; to hope",
    "j\u00ec",
    "ji4"
  ],
  [
    "\u717d",
    "fan into a flame; incite",
    "sh\u0101n",
    "shan1"
  ],
  [
    "\u6bd9",
    "die violently",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u6583",
    "\u6bd9 (die violently)",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u7aa6",
    "(surname); sinus (anatom.)",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u7ac7",
    "\u7aa6 ((surname); sinus (anatom.))",
    "d\u00f2u",
    "dou4"
  ],
  [
    "\u7901",
    "reef; shoal rock",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u6c22",
    "hydrogen",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u6c2b",
    "\u6c22 (hydrogen)",
    "q\u012bng",
    "qing1"
  ],
  [
    "\u65f7",
    "waste; wilderness",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u66e0",
    "\u65f7 (waste; wilderness)",
    "ku\u00e0ng",
    "kuang4"
  ],
  [
    "\u964b",
    "low; humble; plain; ugly; mean",
    "l\u00f2u",
    "lou4"
  ],
  [
    "\u65f1",
    "drought",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u9a74",
    "donkey",
    "l\u01d8",
    "lu:2"
  ],
  [
    "\u9a62",
    "\u9a74 (donkey)",
    "l\u01d8",
    "lu:2"
  ],
  [
    "\u803f",
    "(surname); bright",
    "g\u011bng",
    "geng3"
  ],
  [
    "\u5580",
    "(onomat.)",
    "k\u0101",
    "ka1"
  ],
  [
    "\u7076",
    "kitchen stove",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u7ac8",
    "\u7076 (kitchen stove)",
    "z\u00e0o",
    "zao4"
  ],
  [
    "\u4fae",
    "insult",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u55fd",
    "cough",
    "s\u00f2u",
    "sou4"
  ],
  [
    "\u62c2",
    "brush away",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u80e7",
    "rising moon, hazy; unclear",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u6727",
    "\u80e7 (rising moon, hazy; unclear)",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u8346",
    "(surname); thorns; brambles",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u834a",
    "\u8346 ((surname); thorns; brambles)",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u8be7",
    "surprised; to wander",
    "ch\u00e0",
    "cha4"
  ],
  [
    "\u8a6b",
    "\u8be7 (surprised; to wander)",
    "ch\u00e0",
    "cha4"
  ],
  [
    "\u5352",
    "abruptly; hurriedly, die",
    "z\u00fa",
    "zu2"
  ],
  [
    "\u5455",
    "vomit",
    "\u01d2u",
    "ou3"
  ],
  [
    "\u5614",
    "\u5455 (vomit)",
    "\u01d2u",
    "ou3"
  ],
  [
    "\u5d1b",
    "towering as a peak",
    "ju\u00e9",
    "jue2"
  ],
  [
    "\u7ede",
    "hang (a criminal); to turn",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u7d5e",
    "\u7ede (hang (a criminal); to turn)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u7554",
    "bank; field-path",
    "p\u00e0n",
    "pan4"
  ],
  [
    "\u8d41",
    "to rent",
    "l\u00ecn",
    "lin4"
  ],
  [
    "\u8cc3",
    "\u8d41 (to rent)",
    "l\u00ecn",
    "lin4"
  ],
  [
    "\u5d0e",
    "mountainous",
    "q\u00ed",
    "qi2"
  ],
  [
    "\u97e7",
    "annealed; pliable but strong",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u97cc",
    "\u97e7 (annealed; pliable but strong)",
    "r\u00e8n",
    "ren4"
  ],
  [
    "\u8424",
    "firefly",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u87a2",
    "\u8424 (firefly)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u9165",
    "flaky",
    "s\u016b",
    "su1"
  ],
  [
    "\u62d9",
    "awkward; clumsy; dull; inelegant",
    "zhu\u014d",
    "zhuo1"
  ],
  [
    "\u8654",
    "devout",
    "qi\u00e1n",
    "qian2"
  ],
  [
    "\u5ed3",
    "big; empty; open",
    "ku\u00f2",
    "kuo4"
  ],
  [
    "\u6789",
    "in the wrong; in vain",
    "w\u01ceng",
    "wang3"
  ],
  [
    "\u5955",
    "abundant; graceful",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u556a",
    "syllable",
    "p\u0101",
    "pa1"
  ],
  [
    "\u6ed4",
    "overflow; torrent-dash",
    "t\u0101o",
    "tao1"
  ],
  [
    "\u9756",
    "pacify; quiet",
    "j\u00ecng",
    "jing4"
  ],
  [
    "\u86d9",
    "frog",
    "w\u0101",
    "wa1"
  ],
  [
    "\u5308",
    "Hungary; thorax; chest",
    "xi\u014dng",
    "xiong1"
  ],
  [
    "\u9980",
    "remainder",
    "y\u00fa",
    "yu2"
  ],
  // [
  //   "\u9918",
  //   "\u9980 (remainder)",
  //   "y\u00fa",
  //   "yu2"
  // ],
  [
    "\u9716",
    "continued rain",
    "l\u00edn",
    "lin2"
  ],
  [
    "\u4ffa",
    "I (northern dialects)",
    "\u01cen",
    "an3"
  ],
  [
    "\u6dc0",
    "shallow water, sediment",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u6fb1",
    "\u6dc0 (shallow water, sediment)",
    "di\u00e0n",
    "dian4"
  ],
  [
    "\u5c6f",
    "to station (soldiers)",
    "zh\u016bn",
    "zhun1"
  ],
  [
    "\u78b1",
    "alkali; soda",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u9e7c",
    "\u78b1 (alkali; soda)",
    "ji\u01cen",
    "jian3"
  ],
  [
    "\u7ef7",
    "to stretch; taut; to tie",
    "b\u011bng",
    "beng3"
  ],
  [
    "\u7e43",
    "\u7ef7 (to stretch; taut; to tie)",
    "b\u011bng",
    "beng3"
  ],
  [
    "\u8367",
    "Polyeonatum officinale; glimmer",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u7192",
    "\u8367 (Polyeonatum officinale; glimmer)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u904f",
    "to stop; check",
    "\u00e8",
    "e4"
  ],
  [
    "\u6c9b",
    "copious; abundant",
    "p\u00e8i",
    "pei4"
  ],
  [
    "\u772f",
    "to squint, blind (as with dust)",
    "m\u012b",
    "mi1"
  ],
  [
    "\u89c5",
    "seek",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u8993",
    "\u89c5 (seek)",
    "m\u00ec",
    "mi4"
  ],
  [
    "\u8304",
    "eggplant",
    "qi\u00e9",
    "qie2"
  ],
  [
    "\u51db",
    "shiver with cold or fear",
    "l\u01d0n",
    "lin3"
  ],
  [
    "\u51dc",
    "\u51db (shiver with cold or fear)",
    "l\u01d0n",
    "lin3"
  ],
  [
    "\u674f",
    "apricot",
    "x\u00ecng",
    "xing4"
  ],
  [
    "\u5fcf",
    "feel remorse; regret; repent",
    "ch\u00e0n",
    "chan4"
  ],
  [
    "\u61fa",
    "\u5fcf (feel remorse; regret; repent)",
    "ch\u00e0n",
    "chan4"
  ],
  [
    "\u5029",
    "niece; plagiarize; winsome",
    "qi\u00e0n",
    "qian4"
  ],
  [
    "\u7f06",
    "cable; hawser",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u7e9c",
    "\u7f06 (cable; hawser)",
    "l\u01cen",
    "lan3"
  ],
  [
    "\u76d4",
    "helmet",
    "ku\u012b",
    "kui1"
  ],
  [
    "\u9524",
    "to hammer; weight of steel yard",
    "chu\u00ed",
    "chui2"
  ],
  [
    "\u9318",
    "\u9524 (to hammer; weight of steel yard)",
    "chu\u00ed",
    "chui2"
  ],
  [
    "\u7194",
    "to smelt; fuse",
    "r\u00f3ng",
    "rong2"
  ],
  [
    "\u655b",
    "arrange; control oneself; gather",
    "li\u01cen",
    "lian3"
  ],
  [
    "\u6582",
    "\u655b (arrange; control oneself; gather)",
    "li\u01cen",
    "lian3"
  ],
  [
    "\u9cb8",
    "whale",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u9be8",
    "\u9cb8 (whale)",
    "j\u012bng",
    "jing1"
  ],
  [
    "\u70fd",
    "beacon fire",
    "f\u0113ng",
    "feng1"
  ],
  [
    "\u9a6f",
    "attain gradually; tame",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u99b4",
    "\u9a6f (attain gradually; tame)",
    "x\u00fan",
    "xun2"
  ],
  [
    "\u68b5",
    "Brahma; Sanskrit",
    "f\u00e0n",
    "fan4"
  ],
  [
    "\u68d8",
    "thorns",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u7115",
    "brilliant; lustrous",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u7165",
    "\u7115 (brilliant; lustrous)",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u5e87",
    "to protect; cover; shelter",
    "b\u00ec",
    "bi4"
  ],
  [
    "\u608d",
    "violent",
    "h\u00e0n",
    "han4"
  ],
  [
    "\u949e",
    "money; paper money",
    "ch\u0101o",
    "chao1"
  ],
  [
    "\u9214",
    "\u949e (money; paper money)",
    "ch\u0101o",
    "chao1"
  ],
  [
    "\u725f",
    "barley; to moo; usurp",
    "m\u00f3u",
    "mou2"
  ],
  [
    "\u7caa",
    "manure; dung",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u7cde",
    "\u7caa (manure; dung)",
    "f\u00e8n",
    "fen4"
  ],
  [
    "\u889c",
    "socks; stockings",
    "w\u00e0",
    "wa4"
  ],
  [
    "\u896a",
    "\u889c (socks; stockings)",
    "w\u00e0",
    "wa4"
  ],
  [
    "\u7a8d",
    "intelligence; opening",
    "qi\u00e0o",
    "qiao4"
  ],
  [
    "\u7ac5",
    "\u7a8d (intelligence; opening)",
    "qi\u00e0o",
    "qiao4"
  ],
  [
    "\u9ebd",
    "dimi.",
    "m\u00f3",
    "mo2"
  ],
  // [
  //   "\u9ebc",
  //   "\u9ebd (dimi.)",
  //   "m\u00f3",
  //   "mo2"
  // ],
  [
    "\u72e1",
    "crafty; cunning; sly",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u55e3",
    "to connect, inherit",
    "s\u00ec",
    "si4"
  ],
  [
    "\u77a5",
    "blink; glance",
    "pi\u0113",
    "pie1"
  ],
  [
    "\u631a",
    "(surname); sincere",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u646f",
    "\u631a ((surname); sincere)",
    "zh\u00ec",
    "zhi4"
  ],
  [
    "\u6487",
    "cast away",
    "pi\u0113",
    "pie1"
  ],
  [
    "\u96c1",
    "wild goose",
    "y\u00e0n",
    "yan4"
  ],
  [
    "\u618e",
    "detest",
    "z\u0113ng",
    "zeng1"
  ],
  [
    "\u5e90",
    "hut",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u5eec",
    "\u5e90 (hut)",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u7aff",
    "pole",
    "g\u0101n",
    "gan1"
  ],
  [
    "\u7efd",
    "ripped seam",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u7dbb",
    "\u7efd (ripped seam)",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u9edb",
    "black dye for eyebrows",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u840c",
    "people; sprout",
    "m\u00e9ng",
    "meng2"
  ],
  [
    "\u7a9f",
    "cave; hole",
    "k\u016b",
    "ku1"
  ],
  [
    "\u763e",
    "addiction; craving",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u766e",
    "\u763e (addiction; craving)",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u8944",
    "assist",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u7737",
    "concern; wife and children",
    "ju\u00e0n",
    "juan4"
  ],
  [
    "\u707c",
    "brilliant; cauterize",
    "zhu\u00f3",
    "zhuo2"
  ],
  [
    "\u5431",
    "(phonetic)",
    "z\u012b",
    "zi1"
  ],
  [
    "\u6f8e",
    "sound of waves",
    "p\u00e9ng",
    "peng2"
  ],
  [
    "\u6da1",
    "name of a river, eddy",
    "w\u014d",
    "wo1"
  ],
  [
    "\u6e26",
    "\u6da1 (name of a river, eddy)",
    "w\u014d",
    "wo1"
  ],
  [
    "\u527f",
    "destroy (bandits), destroy (bandits)",
    "ji\u01ceo",
    "jiao3"
  ],
  [
    "\u9175",
    "yeast",
    "ji\u00e0o",
    "jiao4"
  ],
  [
    "\u9551",
    "pound (sterling)",
    "b\u00e0ng",
    "bang4"
  ],
  [
    "\u938a",
    "\u9551 (pound (sterling))",
    "b\u00e0ng",
    "bang4"
  ],
  [
    "\u9eef",
    "deep black; dark",
    "\u00e0n",
    "an4"
  ],
  [
    "\u7391",
    "irregular pearl",
    "j\u012b",
    "ji1"
  ],
  [
    "\u74a3",
    "\u7391 (irregular pearl)",
    "j\u012b",
    "ji1"
  ],
  [
    "\u7ece",
    "continuous; explain; unravel",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7e79",
    "\u7ece (continuous; explain; unravel)",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u5a75",
    "beautiful; graceful",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u5b0b",
    "\u5a75 (beautiful; graceful)",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u960e",
    "(surname); gate of village",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u95bb",
    "\u960e ((surname); gate of village)",
    "y\u00e1n",
    "yan2"
  ],
  [
    "\u6cbc",
    "pond; pool",
    "zh\u01ceo",
    "zhao3"
  ],
  [
    "\u70ab",
    "dazzle; to show off, to boast",
    "xu\u00e0n",
    "xuan4"
  ],
  [
    "\u9885",
    "forehead; skull",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u9871",
    "\u9885 (forehead; skull)",
    "l\u00fa",
    "lu2"
  ],
  [
    "\u6b79",
    "bad; wicked; evil",
    "d\u01cei",
    "dai3"
  ],
  [
    "\u6e5b",
    "deep; clear (water)",
    "zh\u00e0n",
    "zhan4"
  ],
  [
    "\u8747",
    "fly; musca",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u8805",
    "\u8747 (fly; musca)",
    "y\u00edng",
    "ying2"
  ],
  [
    "\u55e1",
    "-oin (chem.) as in anisoin",
    "w\u0113ng",
    "weng1"
  ],
  [
    "\u8fed",
    "alternately; repeatedly",
    "di\u00e9",
    "die2"
  ],
  [
    "\u561f",
    "toot; honk",
    "d\u016b",
    "du1"
  ],
  [
    "\u663c",
    "daytime",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u665d",
    "\u663c (daytime)",
    "zh\u00f2u",
    "zhou4"
  ],
  [
    "\u7410",
    "fragmentary; trifling",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u7463",
    "\u7410 (fragmentary; trifling)",
    "su\u01d2",
    "suo3"
  ],
  [
    "\u54df",
    "(interj) oh",
    "y\u014d",
    "yo1"
  ],
  [
    "\u55b2",
    "\u54df ((interj) oh)",
    "y\u014d",
    "yo1"
  ],
  [
    "\u6363",
    "pound; beat; hull; attack; disturb",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u6417",
    "\u6363 (pound; beat; hull; attack; disturb)",
    "d\u01ceo",
    "dao3"
  ],
  [
    "\u6e23",
    "dregs",
    "zh\u0101",
    "zha1"
  ],
  [
    "\u6850",
    "Aleurites cordata",
    "t\u00f3ng",
    "tong2"
  ],
  [
    "\u8587",
    "species of fern",
    "w\u0113i",
    "wei1"
  ],
  [
    "\u54c9",
    "(exclamatory or interrog. part.)",
    "z\u0101i",
    "zai1"
  ],
  [
    "\u54fa",
    "evening meal, feed",
    "b\u01d4",
    "bu3"
  ],
  [
    "\u6bb4",
    "brawl",
    "\u014du",
    "ou1"
  ],
  [
    "\u6bc6",
    "\u6bb4 (brawl)",
    "\u014du",
    "ou1"
  ],
  [
    "\u77bb",
    "gaze; view",
    "zh\u0101n",
    "zhan1"
  ],
  [
    "\u9706",
    "clap of thunder",
    "t\u00edng",
    "ting2"
  ],
  [
    "\u631f",
    "hold between, clasp under the arm",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u633e",
    "\u631f (hold between, clasp under the arm)",
    "xi\u00e9",
    "xie2"
  ],
  [
    "\u5ac9",
    "jealousy; be jealous of",
    "j\u00ed",
    "ji2"
  ],
  [
    "\u8bb3",
    "avoid mentioning; to taboo",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u8af1",
    "\u8bb3 (avoid mentioning; to taboo)",
    "hu\u00ec",
    "hui4"
  ],
  [
    "\u6715",
    "I; we (imperial use); subtle",
    "zh\u00e8n",
    "zhen4"
  ],
  [
    "\u8335",
    "Skimmia japonica; cushion",
    "y\u012bn",
    "yin1"
  ],
  [
    "\u60eb",
    "exhausted",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u618a",
    "\u60eb (exhausted)",
    "b\u00e8i",
    "bei4"
  ],
  [
    "\u854a",
    "stamen; pistil",
    "ru\u01d0",
    "rui3"
  ],
  [
    "\u88d4",
    "descendents; frontier",
    "y\u00ec",
    "yi4"
  ],
  [
    "\u7ca5",
    "congee; gruel; porridge",
    "zh\u014du",
    "zhou1"
  ],
  [
    "\u9576",
    "to inlay; to embed; ridge; border",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u9472",
    "\u9576 (to inlay; to embed; ridge; border)",
    "xi\u0101ng",
    "xiang1"
  ],
  [
    "\u7184",
    "(v) extinguish; quench",
    "x\u012b",
    "xi1"
  ],
  [
    "\u625b",
    "lift overhead, carry on shoulder",
    "k\u00e1ng",
    "kang2"
  ],
  [
    "\u8749",
    "cicada",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u87ec",
    "\u8749 (cicada)",
    "ch\u00e1n",
    "chan2"
  ],
  [
    "\u6f9c",
    "swelling water",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u703e",
    "\u6f9c (swelling water)",
    "l\u00e1n",
    "lan2"
  ],
  [
    "\u5e62",
    "(mw. for houses); tents",
    "chu\u00e1ng",
    "chuang2"
  ],
  [
    "\u6d4a",
    "impure; muddy",
    "zhu\u00f3",
    "zhuo2"
  ],
  [
    "\u6fc1",
    "\u6d4a (impure; muddy)",
    "zhu\u00f3",
    "zhuo2"
  ],
  [
    "\u83f8",
    "cigarette; tobacco",
    "y\u0101n",
    "yan1"
  ],
  [
    "\u718f",
    "to smoke; scent; fumigate",
    "x\u016bn",
    "xun1"
  ],
  [
    "\u71fb",
    "\u718f (to smoke; scent; fumigate)",
    "x\u016bn",
    "xun1"
  ],
  [
    "\u50fb",
    "low; rustic; secluded",
    "p\u00ec",
    "pi4"
  ],
  [
    "\u7729",
    "dizzy; dazzled",
    "xu\u00e0n",
    "xuan4"
  ],
  [
    "\u4e10",
    "beg for alms; beggar",
    "g\u00e0i",
    "gai4"
  ],
  [
    "\u8910",
    "coarse cloth; dull; brown",
    "h\u00e8",
    "he4"
  ],
  [
    "\u6020",
    "idle; lazy; negligent; careless",
    "d\u00e0i",
    "dai4"
  ],
  [
    "\u51ff",
    "chisel, chisel",
    "z\u00e1o",
    "zao2"
  ],
  [
    "\u947f",
    "\u51ff (chisel, chisel)",
    "z\u00e1o",
    "zao2"
  ],
  [
    "\u6fd2",
    "near",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u7015",
    "\u6fd2 (near)",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u5e9a",
    "7th heavenly stem; age",
    "g\u0113ng",
    "geng1"
  ],
  [
    "\u53ed",
    "a sharp sound or noise (e.g. gunfire)",
    "b\u0101",
    "ba1"
  ],
  [
    "\u8098",
    "elbow; pork shoulder",
    "zh\u01d2u",
    "zhou3"
  ],
  [
    "\u819b",
    "chest (of body); hollow space",
    "t\u00e1ng",
    "tang2"
  ],
  [
    "\u68d5",
    "palm",
    "z\u014dng",
    "zong1"
  ],
  [
    "\u4ed5",
    "an official",
    "sh\u00ec",
    "shi4"
  ],
  [
    "\u85fb",
    "(aquatic grasses); elegant",
    "z\u01ceo",
    "zao3"
  ],
  [
    "\u6342",
    "resist, (v) seal up; muffle",
    "w\u01d4",
    "wu3"
  ],
  [
    "\u9e3d",
    "pigeon; dove",
    "g\u0113",
    "ge1"
  ],
  [
    "\u9d3f",
    "\u9e3d (pigeon; dove)",
    "g\u0113",
    "ge1"
  ],
  [
    "\u918b",
    "vinegar",
    "c\u00f9",
    "cu4"
  ],
  [
    "\u75ea",
    "illness; numbness of the limbs",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u7613",
    "\u75ea (illness; numbness of the limbs)",
    "hu\u00e0n",
    "huan4"
  ],
  [
    "\u6c13",
    "vagrant; ruffian, people",
    "m\u00e1ng",
    "mang2"
  ],
  [
    "\u4ea5",
    "12th earthly branch; 9-11 p.m.",
    "h\u00e0i",
    "hai4"
  ],
  [
    "\u627c",
    "hold (strategic position)",
    "\u00e8",
    "e4"
  ],
  [
    "\u5d2d",
    "precipitous peak",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u5d84",
    "\u5d2d (precipitous peak)",
    "zh\u01cen",
    "zhan3"
  ],
  [
    "\u9631",
    "hole; pitfall",
    "j\u01d0ng",
    "jing3"
  ],
  [
    "\u87f9",
    "crab",
    "xi\u00e8",
    "xie4"
  ],
  [
    "\u803d",
    "indulge; delay",
    "d\u0101n",
    "dan1"
  ],
  [
    "\u865e",
    "forewarned; peace; worry",
    "y\u00fa",
    "yu2"
  ],
  [
    "\u8beb",
    "commandment; prohibit",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u8aa1",
    "\u8beb (commandment; prohibit)",
    "ji\u00e8",
    "jie4"
  ],
  [
    "\u6912",
    "pepper",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u73c0",
    "amber",
    "p\u00f2",
    "po4"
  ],
  [
    "\u60ed",
    "ashamed",
    "c\u00e1n",
    "can2"
  ],
  [
    "\u615a",
    "\u60ed (ashamed)",
    "c\u00e1n",
    "can2"
  ],
  [
    "\u7940",
    "offer sacrifice to",
    "s\u00ec",
    "si4"
  ],
  [
    "\u6d47",
    "to water",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u6f86",
    "\u6d47 (to water)",
    "ji\u0101o",
    "jiao1"
  ],
  [
    "\u7504",
    "(surname); to mold",
    "zh\u0113n",
    "zhen1"
  ],
  [
    "\u5499",
    "throat",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u56a8",
    "\u5499 (throat)",
    "l\u00f3ng",
    "long2"
  ],
  [
    "\u7ef0",
    "generous; ample; wide; spacious",
    "chu\u00f2",
    "chuo4"
  ],
  [
    "\u7dbd",
    "\u7ef0 (generous; ample; wide; spacious)",
    "chu\u00f2",
    "chuo4"
  ],
  [
    "\u8760",
    "bat",
    "f\u00fa",
    "fu2"
  ],
  [
    "\u96a7",
    "subterranean",
    "su\u00ec",
    "sui4"
  ],
  [
    "\u8d4e",
    "redeem; to ransom",
    "sh\u00fa",
    "shu2"
  ],
  [
    "\u8d16",
    "\u8d4e (redeem; to ransom)",
    "sh\u00fa",
    "shu2"
  ],
  [
    "\u5bc7",
    "bandit",
    "k\u00f2u",
    "kou4"
  ],
  [
    "\u77a9",
    "to gaze at; to stare at",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u77da",
    "\u77a9 (to gaze at; to stare at)",
    "zh\u01d4",
    "zhu3"
  ],
  [
    "\u8845",
    "offer blood in sacrifice",
    "x\u00ecn",
    "xin4"
  ],
  [
    "\u91c1",
    "\u8845 (offer blood in sacrifice)",
    "x\u00ecn",
    "xin4"
  ],
  [
    "\u51f3",
    "bench; stool",
    "d\u00e8ng",
    "deng4"
  ],
  [
    "\u895f",
    "overlapping part of Chinese gown",
    "j\u012bn",
    "jin1"
  ],
  [
    "\u7f00",
    "to stitch up; connect",
    "zhu\u00ec",
    "zhui4"
  ],
  [
    "\u7db4",
    "\u7f00 (to stitch up; connect)",
    "zhu\u00ec",
    "zhui4"
  ],
  [
    "\u69df",
    "areca",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u6ab3",
    "\u69df (areca)",
    "b\u012bn",
    "bin1"
  ],
  [
    "\u6ca7",
    "blue; green(color of water)",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u6ec4",
    "\u6ca7 (blue; green(color of water))",
    "c\u0101ng",
    "cang1"
  ],
  [
    "\u68f1",
    "corner; square beam",
    "l\u00e9ng",
    "leng2"
  ],
  [
    "\u8c41",
    "play Chinese finger game",
    "hu\u014d",
    "huo1"
  ],
  [
    "\u5c39",
    "(surname); to rule",
    "y\u01d0n",
    "yin3"
  ],
  [
    "\u575d",
    "dam; dyke; embankment",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u58e9",
    "\u575d (dam; dyke; embankment)",
    "b\u00e0",
    "ba4"
  ],
  [
    "\u5ae6",
    "the lady in the moon",
    "ch\u00e1ng",
    "chang2"
  ],
  [
    "\u6caa",
    "Shanghai",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u6eec",
    "\u6caa (Shanghai)",
    "h\u00f9",
    "hu4"
  ],
  [
    "\u864f",
    "take captive",
    "l\u01d4",
    "lu3"
  ],
  [
    "\u865c",
    "\u864f (take captive)",
    "l\u01d4",
    "lu3"
  ],
  [
    "\u82db",
    "severe; exacting",
    "k\u0113",
    "ke1"
  ],
  [
    "\u745b",
    "(crystal); lustrous",
    "y\u012bng",
    "ying1"
  ],
  [
    "\u6cae",
    "destroy; to stop",
    "j\u01d4",
    "ju3"
  ],
  [
    "\u5bb5",
    "night",
    "xi\u0101o",
    "xiao1"
  ],
  [
    "\u753a",
    "raised path between fields",
    "t\u01d0ng",
    "ting3"
  ],
  [
    "\u7ef8",
    "(light) silk",
    "ch\u00f3u",
    "chou2"
  ],
  [
    "\u7da2",
    "\u7ef8 ((light) silk)",
    "ch\u00f3u",
    "chou2"
  ]
];

export interface CharacterEntry {
  character: string;
  definition: string;
  pinyin: string;
  pinyinAscii: string;
}

const characterTable = new Map<string, CharacterEntry>();
data.map(([character, definition, pinyin, pinyinAscii]) => characterTable.set(
  character, { character, definition, pinyin, pinyinAscii }
));

export function lookupCharacter(character: string): CharacterEntry {
  return characterTable.get(character) || {
    character, definition: '?', pinyin: '?', pinyinAscii: '?'
  };
}

const charIndex = new Map<string, number>();
data.map(([char, _1, _2, _3], index) => charIndex.set(char, index));

export function getCharacterIndex(character: string): number {
  const value = charIndex.get(character);
  if (value === undefined)
    return -1;
  return value;
}
